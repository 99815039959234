.mappingModalBody,
.mappingModalFooter,
.mappingModalHeader,
.createAddressbookModalBody,
.createAddressbookModalFooter,
.createAddressbookModalHeader,
.createAddressbookModalBodyDisabled {
  padding: 50px;
}

.createAddressbookModalBodyDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.queryRow {
  margin-bottom: 20px;
}

.mappingInput {
  cursor: pointer;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  pointer-events: disabled;
}

.mappingModalBodyRow {
  padding: 20px;
}

.createAddressbookModalBodyRow {
  padding: 10px;
}

.createAddressbookModalFooter {
  display: block;
  flex-wrap: nowrap;
}

.mappingModalFooterRow,
.createAddressbookFooterRow {
  padding: 20px;
  text-align: left;
}

.mappingModal,
.createAddressbookModal {
  max-width: 80%;
}

.validationError {
  min-width: 170px;
}

.createAddressbookButton {
  margin-left: 15px;
}

.canopusValidCol svg {
  margin-top: 40px;
}

.canopusValidCol img {
  margin-top: 30px;
}

.loadingRow {
  padding-top: 20px;
}

.querySearchBox,
.queryOptionDropdown {
  max-width: 12.5%;
}

.saveButton {
  float: right;
}

.mappingDiv {
}

.mappingSpan {
}

.createAddressbookModalFooterRow {
}

.columnsButton {
  margin: 15px 0;
  margin-right: 10px;
}

.bulkUpdateButton {
  margin-top: 30px;
}

.center {
  text-align: center;
}

.rowMarginTop {
  margin-top: 20px;
}

.modalPadding {
  padding: 50px;
}

.categoryDuplicatesHeaderRow {
  margin-bottom: 0.75rem;
}

.categoryDuplicatesAlert {
  padding: 0.25rem 1.25rem;
  margin-bottom: 0.5rem;
}

.bulkInputRow {
  vertical-align: bottom;
}

.brandInputCol label {
  text-wrap: nowrap;
}
