import * as React from "react";
import { Table, Row, Col, Button } from 'reactstrap';
import { GlobalFilter } from "../../components/table/GlobalFilter";
import { DownArrow, UpArrow } from '../../components/Icons';
import { useState } from "react";
import { logger } from "../../logging";

const usersStyles = require('./users.css');
const iconStyles = require('../../components/icons.css');


export const UsersTable = ({ preGlobalFilteredRows, state, setGlobalFilter,
  getTableProps, headerGroups, getTableBodyProps, rows, prepareRow }) => {

  const onCellClicked = (cell, row) => {
    // logger.info(`cell clicked - cell: ${JSON.stringify({ ...cell.getCellProps() })}, row: ${JSON.stringify({ ...row.getRowProps() })}`);
  }

  const [selectedUser, setSelectedUser] = useState('');

  const onUserRowClicked = (row) => {
    logger.info(`row clicked: ${row.original.uid}`)
    setSelectedUser(row.original.uid);
  }

  return (
    <div>
      <React.Fragment>
        <Row className={usersStyles.searchRow}>
          <Col>
            <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
          </Col>
        </Row>
        <div className={usersStyles.usersTableContainer}>
          <Table {...getTableProps()} bordered striped className={usersStyles.usersTable}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                      <Button className={usersStyles.sortButton} size="sm" color="link" {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.isSorted ? "sorted " : "sort"}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? <DownArrow fill="#007bff" class={iconStyles.sortButton} /> : <UpArrow fill="#007bff" class={iconStyles.sortButton} />) : ''}
                        </span>
                      </Button>

                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} onClick={(e) => onUserRowClicked(row)}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()} onClick={(e) => onCellClicked(cell, row)}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      Total Rows: {rows.length}
      </React.Fragment>
    </div>
  )
}