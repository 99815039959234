import { useState, useEffect } from 'react';
import { logger, serverLogger } from '../logging';
import * as firebaseService from '../services/firebase';

// basic hook to ease usage of firebase onCall
export const useOnCall = (
  fn: string,
  data?: any,
  reload?: boolean | number
) => {
  const damApp = firebaseService.getDAMApp();
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const callable = damApp.functions().httpsCallable(fn);

  useEffect(() => {
    let isSubscribed = true;

    const promise = data !== null ? callable(data) : callable();

    if (isSubscribed) {
      setLoading(true);
    }

    promise
      .then((res) => {
        if (isSubscribed) {
          setResponse(res);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isSubscribed) {
          setError(error);
        }
      });

    return () => (isSubscribed = false) as any; //react will call this on cleanup and set issubscribed to false so no setstates should happen
  }, [reload]);

  return [response, loading, error];
};

export const useUserWithRole = (auth) => {
  const damApp = firebaseService.getDAMApp();
  const [userWithRole, setUserWithRole] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  // logger.debug(
  //   `[useUserWithRole] called. userWithRole: ${JSON.stringify(userWithRole)}`
  // );

  useEffect(() => {
    // logger.debug(
    //   `[useUserWithRole] useEffect auth changed - auth: ${JSON.stringify(auth)}`
    // );
    return auth.onAuthStateChanged(async (user) => {
      // we need to keep any apps auth state beyond the default synced
      const canopusApp = firebaseService.getCanopusApp();
      const ifbApp = firebaseService.getIFBApp();
      const partyServiceApp = firebaseService.getPartyServiceApp();

      if (user) {
        setLoading(true);
        try {
          const idTokenResult = await user.getIdTokenResult();
          if (
            !idTokenResult.claims.isDAMUser &&
            !idTokenResult.claims.damPowerUser
          ) {
            damApp.auth().signOut();
            canopusApp.auth().signOut();
            ifbApp.auth().signOut();
            partyServiceApp.auth().signOut();
            throw new Error(
              'User account does not exist. Please contact your administrator.'
            );
          }

          try {
            // only log for auth change on DAM app - was previously logging for change on all four DBs.
            if (auth?.app?.name === 'damApp') {
              await serverLogger(
                '[useUserWithRole]: auth state changed, logged in',
                'info'
              );
            }
          } catch (e) {
            damApp.auth().signOut();
            canopusApp.auth().signOut();
            ifbApp.auth().signOut();
            partyServiceApp.auth().signOut();
            throw new Error(`Please make sure you are connected to the UMG VPN and using one of the following Global Protect gateways:

            zLondon_AllTrafficviaVPN or
            zNortheast_US_AllTrafficviaVPN`);
          }

          setUserWithRole(user);
          setLoading(false);
          setError(undefined);
        } catch (e) {
          setError(e);
          setLoading(false);
        }
      } else {
        setUserWithRole(undefined);
        setLoading(false);
      }
    });
  }, [auth]);

  return [userWithRole, loading, error];
};
