import * as React from 'react';
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';

const accountStyles = require('./account.css');

export const AccountAudit = ({ account, system }) => {
  let created = '';
  let modified = '';
  let created_by = '';
  let updated_by = '';
  const isDotdigital = system === 'dotdigital';
  const isSlicktext = system === 'slicktext';
  const isAttentive = system === 'attentive';
  const isTreasureData = system === 'treasure_data';
  const isCommunity = system === 'community';
  if (isDotdigital && account && account.createdAt) created = account.createdAt;
  if (isSlicktext && account && account.created)
    created = moment.unix(account.created).format();
  if (isDotdigital && account && account.lastModifiedAt)
    modified = account.lastModifiedAt;
  if (isSlicktext && account && account.modified)
    modified = moment.unix(account.modified).format();
  if (isAttentive && account && account.created_at)
    created = moment.unix(account.created_at).format();
  if (isAttentive && account && account.updated_at)
    modified = moment.unix(account.updated_at).format();
  if (isTreasureData && account && account.created_at)
    created = moment.unix(account.created_at).format();
  if (isTreasureData && account && account.updated_at)
    modified = moment.unix(account.updated_at).format();
  if (isCommunity && account && account.created_at)
    created = moment.unix(account.created_at).format();
  if (isCommunity && account && account.updated_at)
    modified = moment.unix(account.updated_at).format();
  if (account && account.createdBy && account.createdBy.email)
    created_by = account.createdBy.email;
  if (account && account.lastModifiedBy && account.lastModifiedBy.email)
    updated_by = account.lastModifiedBy.email;
  if (account && account.created_by && account.created_by.email)
    created_by = account.created_by.email;
  if (account && account.updated_by && account.updated_by.email)
    updated_by = account.updated_by.email;

  return (
    <React.Fragment>
      <Row>
        <Col sm="12">
          {account && (
            <ListGroup className={accountStyles.auditGroup}>
              {created && (
                <ListGroupItem>Created Date: {created}</ListGroupItem>
              )}
              {created_by && (
                <ListGroupItem>Created By: {created_by}</ListGroupItem>
              )}
              {modified && (
                <ListGroupItem>Last Modified Date: {modified}</ListGroupItem>
              )}
              {updated_by && (
                <ListGroupItem>Last Modified By: {updated_by}</ListGroupItem>
              )}
            </ListGroup>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};
