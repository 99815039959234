import * as React from 'react';
import { useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { LoginForm } from './login/LoginForm';
import { LocationProps } from '../Routes';
import { Loader } from '../components/Loader';
import { useUserWithRole } from '../hooks/firebase';
import * as firebaseService from '../services/firebase';
import { logger, serverLogger } from '../logging';

export const Login = () => {
  const canopusApp = firebaseService.getCanopusApp();
  const ifbApp = firebaseService.getIFBApp();
  const damApp = firebaseService.getDAMApp();
  const partyServiceApp = firebaseService.getPartyServiceApp();
  const location: LocationProps = useLocation();
  const [user, loading, error] = useUserWithRole(damApp.auth());
  const [userIFB, loadingIFB, errorIFB] = useUserWithRole(ifbApp.auth());
  const [userCanopus, loadingCanopus, errorCanopus] = useUserWithRole(
    canopusApp.auth()
  );
  const [userPartyService, loadingPartyService, errorPartyService] =
    useUserWithRole(partyServiceApp.auth());
  const [loginError, setLoginError] = useState();
  const [cred, setCred]: [any, any] = useState();

  const { from } = location.state || { from: { pathname: '/accounts' } };

  const loginWithAd = async () => {
    setLoginError(undefined);

    try {
      // sign in with AD
      const provider = new firebase.auth.OAuthProvider('microsoft.com');
      logger.debug(
        `provider: ${typeof provider} - ${JSON.stringify(provider)}`
      );
      provider.setCustomParameters({
        tenant: process.env.TENANT_ID,
      });

      const result = await damApp.auth().signInWithPopup(provider);
      setCred(result.credential);

      // trying to prevent flashing/multiple logins if dam login fails due to serverlogger failing due to not being on vpn
      // logger.debug(`signing in with ${JSON.stringify(result.credential)} to canopusApp`);
      // await canopusApp.auth().signInWithCredential(result.credential);
      // logger.debug(`now signing in with ${JSON.stringify(result.credential)} to ifbApp`);
      // await ifbApp.auth().signInWithCredential(result.credential);
      // logger.debug(`signed in to both canopusApp and ifbApp`);
    } catch (e) {
      logger.error('error logging in ', e);
      serverLogger(`Error logging in: ${e}`, 'error');
      setLoginError(e);
    }
  };

  if (loading || loadingCanopus || loadingIFB || loadingPartyService) {
    return <Loader loading={true} />;
  }

  if (
    typeof error !== 'undefined' ||
    typeof errorCanopus !== 'undefined' ||
    typeof errorIFB !== 'undefined' ||
    typeof errorPartyService !== 'undefined'
  ) {
    return (
      <LoginForm errorMessage={(error as Error).message} login={loginWithAd} />
    );
  }

  if (typeof loginError !== 'undefined') {
    return (
      <LoginForm
        errorMessage={(loginError as Error).message}
        login={loginWithAd}
      />
    );
  }

  if (cred && !userCanopus) {
    canopusApp.auth().signInWithCredential(cred);
  }

  if (cred && !userIFB) {
    ifbApp.auth().signInWithCredential(cred);
  }

  if (cred && !userPartyService) {
    partyServiceApp.auth().signInWithCredential(cred);
  }

  // user has access to this app
  if (user && userCanopus && userIFB && userPartyService) {
    const userEmail = user['email'];
    // logger.info('reidrecting to', from.pathname);
    serverLogger(`User logged in: ${userEmail}`, 'info');
    return (
      <Redirect to={{ pathname: from.pathname, state: { from: location } }} />
    );
  }

  return <LoginForm login={loginWithAd} />;
};
