import * as React from "react";
import { Table, Button } from 'reactstrap';
import { DownArrow, UpArrow } from '../../components/Icons';
import { useEffect } from "react";

const tagsStyles = require('./tags.css');
const iconStyles = require('../../components/icons.css');


export const TagsTable = ({ preGlobalFilteredRows, state, setGlobalFilter,
  getTableProps, headerGroups, getTableBodyProps, rows, prepareRow, system, pageOptions,
  page, gotoPage, previousPage, nextPage, setPageSize, canPreviousPage, canNextPage }) => {

  const onCellClicked = (cell, row) => {
    // logger.info(`cell clicked - cell: ${JSON.stringify({ ...cell.getCellProps() })}, row: ${JSON.stringify({ ...row.getRowProps() })}`);
  }

  const filters = state.filters;
  const pageIndex = state.pageIndex;
  const pageSize = state.pageSize;

  useEffect(
    () => {
      // filters changed
      gotoPage(0);
    },
    [filters]
  );

  return (
    <div>
      <React.Fragment>
        <div className={tagsStyles.tagsTableContainer}>
          <Table {...getTableProps()} bordered striped className={tagsStyles.tagsTable}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                      <Button className={tagsStyles.sortButton} size="sm" color="link" {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.isSorted ? "sorted " : "sort"}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? <DownArrow fill="#007bff" class={iconStyles.sortButton} /> : <UpArrow fill="#007bff" class={iconStyles.sortButton} />) : ''}
                        </span>
                      </Button>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} >
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()} onClick={(e) => onCellClicked(cell, row)}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
        <div className={tagsStyles.pagination}>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous Page
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next Page
          </Button>
          <div>
            Page{' '}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>
        </div>
        Total Rows: {rows.length}
      </React.Fragment>
    </div>
  )
}