.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}
.filterSearchContainer {
  width: 1045px;
  margin-bottom: 15px;
}

.filterToggleContainer {
  width: 1045px;
}
.inputContainer {
  display: flex;
  min-width: 100%;
}

.syncColumn {
  height: 50px;
  position: relative;
}

.syncToggle {
  position: absolute;
  min-width: 150px;
}

.syncSearch {
  margin-top: 30px;
  position: absolute;
  min-width: 150px;
}

.fitContents {
  max-width: fit-content;
}
