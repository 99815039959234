.button {
  margin-right: 10px;
}

.center {
  text-align: center;
}

.loader {
  height: 40px;
  width: 40px;
  padding: 0px;
  margin-left: 0px;
}
