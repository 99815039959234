.container {
  margin: 20px;
}

.headerRow {
  margin: 20px 0 30px 0;
}

.noMargin {
  margin: 0;
}

.noPadding {
  padding: 0;
}

.divider {
  height: 1px;
  width: 100%;
  display: block; /* for use on default inline elements like span */
  margin: 9px 0 20px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.fullHeight {
  height: 100%;
}
