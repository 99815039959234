export const generateIndexes = (fields: string[] = []) => {
  const combine = (sub: string[], ind: number) => {
    let result: string[] = [];
    let i, p;
    const l = fields.length;
    for (i = ind; i < l; i++) {
      // go through each element in the original array
      p = sub.slice(0); // get a copy of the sub array
      p.push(fields[i]); // push the current element onto it
      result = result.concat(combine(p, i + 1)); // recursively call with sub array (now head of original) + increment index so next call is [country, channel]

      if (p.length > 1) {
        // so we don't return the single field (eg. country)
        result.push(p.join('_'));
      }
    }

    return result;
  };

  return combine([], 0);
};

export const index = (object: any) => {
  const fieldNames = [
    'active',
    'country',
    'channel',
    'category',
    'brandId',
    'name',
    'tag',
  ];
  const fieldValues = [];

  const entries = Object.entries(object);
  for (const [key, value] of entries) {
    // exclude accountName and target_id from index generation
    if (key === 'accountName' || key === 'target_id') {
      continue;
    }

    const idx = fieldNames.indexOf(key);
    if (idx === -1) {
      continue;
    }

    fieldValues[idx] = `${value}`.toLowerCase().trim();
  }

  const indexNames = generateIndexes(fieldNames);
  const indexValues = generateIndexes(fieldValues);

  for (let i = 1; i < indexNames.length; i++) {
    const name = indexNames[i];
    const value = indexValues[i];

    object[name] = value;
  }

  return object;
};
