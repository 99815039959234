import * as React from 'react';
import { useState } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import * as firebaseService from '../../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  TabPane,
} from 'reactstrap';
import { Loader } from '../../../components/Loader';
import { logger } from '../../../logging';
import { ref } from '../../../utils/firebase';
import { PRIVY_TAB } from '../AccountForm';
import * as R from 'ramda';
import moment from 'moment';

const accountStyles = require('../account.css');

export const PrivyTab = ({ accountId }) => {
  const damApp = firebaseService.getDAMApp();
  const [user, userLoading] = useAuthState(damApp.auth() as any);
  const [accountValues, accountLoading, accountError] = useObjectVal(
    ref(`accounts/dotdigital/${accountId}`)
  );
  //logger.debug(`[Privy] accountValues: ${JSON.stringify(accountValues)}`);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [storeShopifyUrl, setStoreShopifyUrl] = useState('');
  const [ddWebTrackingId, setDDWebTrackingId] = useState('');
  const [webTrackingUrl, setWebTrackingUrl] = useState('');

  React.useEffect(() => {
    //logger.debug('in useEffect');
    if (accountValues && accountValues['store_myshopify_url']) {
      setStoreShopifyUrl(accountValues['store_myshopify_url']);
    }

    if (accountValues && accountValues['dotdigital_web_tracking_id']) {
      setDDWebTrackingId(accountValues['dotdigital_web_tracking_id']);
    }

    if (accountValues && accountValues['web_tracking_url']) {
      setWebTrackingUrl(accountValues['web_tracking_url']);
    }
  }, [accountValues]);

  if (accountLoading === true) {
    return <Loader loading={true} />;
  }

  if (accountError) {
    return (
      <TabPane tabId={PRIVY_TAB}>
        <React.Fragment>Error</React.Fragment>
      </TabPane>
    );
  }

  if (!accountValues) {
    return (
      <TabPane tabId={PRIVY_TAB}>
        <div>No data available for account</div>
      </TabPane>
    );
  }

  const validateFields = () => {
    let currentErrors = [];

    let storeShopifyUrlValid;
    try {
      new URL(storeShopifyUrl);
      storeShopifyUrlValid = true;
    } catch (e) {
      storeShopifyUrlValid = false;
    }

    let webTrackingUrlValid;
    try {
      new URL(webTrackingUrl);
      webTrackingUrlValid = true;
    } catch (e) {
      webTrackingUrlValid = false;
    }

    /*const storeShopifyUrlIsURL =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(
        `${storeShopifyUrl}`
      );*/

    /*const webTrackingUrlIsURL =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(
        `${webTrackingUrl}`
      );*/

    if (!storeShopifyUrlValid && storeShopifyUrl !== '') {
      logger.debug('[Privy] storeShopifyUrl not valid url');
      currentErrors = R.append(
        'Store MyShopify URL is not a valid URL',
        currentErrors
      );
    }

    if (!webTrackingUrlValid && webTrackingUrl !== '') {
      logger.debug('[Privy] webTrackingUrl not valid url');
      currentErrors = R.append(
        'Web Tracking URL is not a valid URL',
        currentErrors
      );
    }

    logger.debug(`setting errors with: ${currentErrors}`);
    setErrors(currentErrors);
    return currentErrors;
  };

  const handleStoreURLChanged = (e) => {
    const val = e.target.value;
    setStoreShopifyUrl(val);
  };

  const handleDDWebTrackingIdChanged = (e) => {
    const val = e.target.value;
    setDDWebTrackingId(val);
  };

  const handleWebTrackingUrlChanged = (e) => {
    const val = e.target.value;
    setWebTrackingUrl(val);
  };

  const savePrivyFields = async () => {
    logger.debug(`[Privy] save clicked (errors.length: ${errors.length})`);
    setSaving(true);
    const currentErrors = validateFields();
    logger.debug(`[savePrivyFields] errors.length: ${currentErrors.length}`);
    if (accountValues && currentErrors.length === 0 && !userLoading && user) {
      try {
        const accountRef = damApp
          .database()
          .ref(`accounts/dotdigital/${accountId}`);

        const account = {
          ...accountValues,
          lastModifiedBy: { uid: user.uid, email: user.email },
          lastModifiedAt: `${moment().format()}`,
          store_myshopify_url: storeShopifyUrl.trim().toLowerCase(),
          dotdigital_web_tracking_id: ddWebTrackingId.trim(),
          web_tracking_url: webTrackingUrl.trim().toLowerCase(),
        };

        await accountRef.update(account);
      } catch (e) {
        logger.error(`[Privy] error saving account update: ${e}`);
      }
    }
    setSaving(false);
  };

  // logger.debug(`errorRows: ${errors.length}`);
  const errorRows = errors.map((error, index) => {
    return (
      <Row className={accountStyles.formRow} key={index}>
        <Col>
          <Alert color="danger">{error}</Alert>
        </Col>
      </Row>
    );
  });

  return (
    <TabPane tabId={PRIVY_TAB}>
      {errorRows}
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <FormGroup>
            <Label>Store MyShopify URL</Label>
            <Input
              name="store_myshopify_url"
              placeholder="Store MyShopify URL"
              value={storeShopifyUrl}
              onChange={handleStoreURLChanged}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <FormGroup>
            <Label>DotDigital Web Tracking ID</Label>
            <Input
              name="dotdigital_web_tracking_id"
              placeholder="DotDigital Web Tracking ID"
              value={ddWebTrackingId}
              onChange={handleDDWebTrackingIdChanged}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <FormGroup>
            <Label>Web Tracking URL</Label>
            <Input
              name="web_tracking_url"
              placeholder="Web Tracking URL"
              value={webTrackingUrl}
              onChange={handleWebTrackingUrlChanged}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <Button
            color="primary"
            onClick={(e) => savePrivyFields()}
            disabled={saving}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </Col>
      </Row>
    </TabPane>
  );
};
