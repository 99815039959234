import * as React from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router';
import { AccountForm } from '../account/AccountForm';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import { logger, serverLogger } from '../../logging';
import { getDAMApp } from '../../services/firebase';

export const CreateAccountForm = ({ system }) => {
  const damApp = getDAMApp();
  const [accountId, setAccountId] = useState('');
  const [user, userLoading] = useAuthState(damApp.auth() as any);

  // consider adding validation for available account types

  const save = async (values, errors) => {
    logger.info(`createAccountForm save called`);
    const isDotdigital = system === 'dotdigital';
    const isSlicktext = system === 'slicktext';
    const isAttentive = system === 'attentive';
    const isTreasureData = system === 'treasure_data';
    const isCommunity = system === 'community';
    const errorItems = Object.keys(errors);
    if (errorItems.length === 0 && !userLoading && user) {
      logger.info('no errors, saving');

      // trim important values
      values.ddAccountId && (values.ddAccountId = values.ddAccountId.trim());
      values.ddAccountName &&
        (values.ddAccountName = values.ddAccountName.trim());
      values.id && (values.id = values.id.trim());
      values.name && (values.name = values.name.trim());
      values.account_id && (values.account_id = values.account_id.trim());
      values.account_name && (values.account_name = values.account_name.trim());

      values.party_id && (values.party_id = parseInt(values.party_id, 10));

      const accountsRef = damApp.database().ref(`accounts/${system}`);
      const account = {
        ...values,
        createdBy: { uid: user.uid, email: user.email },
      };

      if (isDotdigital) {
        account.createdAt = `${moment().format()}`;
        account.data_acquisition_processing_method = 'asynchronous';
        account.data_acquisition_selection = 'unselected';
        if (values.isB2COrB2B === 'b2c') {
          account.send_subscription_insights = true;
          account.send_genre_insights = true;
        }
      }

      if (isSlicktext) {
        account.created = `${moment().unix()}`;
        account.data_acquisition_processing_method = 'asynchronous';
        account.data_acquisition_selection = 'unselected';
      }

      if (isAttentive || isTreasureData || isCommunity) {
        account.created_at = `${moment().unix()}`;
        account.created_by = { uid: user.uid, email: user.email };
        delete account.createdBy;
        if (account.account_id === '') delete account.account_id;
      }

      try {
        const accountRef = await accountsRef.push(account);
        setAccountId(accountRef.key);
        const newAccountRef = damApp
          .database()
          .ref(`accounts/${system}/${accountRef.key}`);
        serverLogger(
          `[CreateAccountForm] User ${user.email} created account: ${accountRef.key} (${system})`,
          'info'
        );
        await newAccountRef.update({ key: accountRef.key, id: accountRef.key });
      } catch (e) {
        serverLogger(
          `[CreateAccountForm] User ${user.email} had an error attempting to create account (${system})`,
          'error'
        );
        logger.error(e);
      }
    } else {
      logger.error('errors', errorItems);
    }
  };

  const formModel = {
    // not currently used - this is how we do default values with availity-form
    active: true,
  };

  return (
    <div>
      {accountId !== '' && <Redirect to={`/accounts/${system}/${accountId}`} />}
      {accountId === '' && (
        <AccountForm
          save={save}
          model={formModel}
          account={null}
          system={system}
        />
      )}
    </div>
  );
};
