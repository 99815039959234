import * as React from 'react';
import { useState } from 'react';
import { useObjectVal, useListVals } from 'react-firebase-hooks/database';
import { ref } from '../../utils/firebase';
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { MinusIcon, PlusIcon } from '../../components/Icons';
import { logger } from '../../logging';
import { Loader } from '../../components/Loader';
import * as R from 'ramda';
import moment from 'moment';

const pagesStyles = require('../pages.css');
const iconStyles = require('../../components/icons.css');
const customFieldStyles = require('./customFields.css');

const CustomFieldGroupRow = ({
  accountType,
  customFields,
  setValidationError,
  val,
}) => {
  const customFieldsValues = Object.values(customFields) || [];
  const [modal, setModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [addDisabled, setAddDisabled] = useState(true);
  const [field, setField] = useState('');
  const [fields, setFields] = useState((val && val.fields) || {});
  const customFieldGroupRef = ref(
    `customFieldsByCategory/${accountType.value}`
  );

  // logger.debug(`customFields: ${JSON.stringify(customFieldsValues)}`)
  // logger.debug(`fields: ${JSON.stringify(fields)}`)

  const toggle = (save?) => {
    if (save === true) {
      logger.info(`[toggle] save`);
      createCustomFieldGroup();
    }

    setModal(!modal);
  };

  const createCustomFieldGroup = async () => {
    logger.info(`[createCustomFieldGroup] save confirmed`);
    const now = moment().unix();
    const toUpdate = {
      fields,
      modified: now,
      key: accountType.value,
    };

    if (val === undefined || (val && !val.created)) {
      toUpdate['created'] = now;
    }

    try {
      await customFieldGroupRef.update(toUpdate);
    } catch (e) {
      logger.error(`[createCustomFieldGroup] ${e}`);
    }
  };

  const handleCustomFieldChanged = (e) => {
    const val = e.target.value;
    setField(val);
    setAddDisabled(true);
    if (val !== '' && fields[val] !== true) {
      setAddDisabled(false);
    }
  };

  const handleAddField = () => {
    const newFields = { ...fields };
    newFields[field] = true;
    const ordered = R.keys(newFields)
      .sort()
      .reduce((obj, key) => {
        obj[key] = newFields[key];
        return obj;
      }, {});

    setFields(ordered);
    setField('');
    setAddDisabled(true);
    setDisabled(false);
  };

  // removing a field doesn't actually have any effect currently because we do not delete fields from DotDigital accounts (in case of mistaken mass deletion of data for example)
  // custom field groups drive mass field creation in DotDigital accounts
  // lytics entity generation is solely based on the fields PRESENT in an account, and the category given to those fields on the main Fields tab (and the should sync to lytics flag state)
  const handleRemoveField = (id: string) => {
    const newFields = { ...fields };
    delete newFields[id];
    setFields(newFields);
    setDisabled(false);
    if (R.keys(newFields).length === 0) {
      setDisabled(true);
    }
  };

  return (
    <React.Fragment>
      <tr>
        <td>{accountType.label}</td>
        <td>
          <Input
            type="select"
            name="customFields"
            value={field}
            onChange={handleCustomFieldChanged}
          >
            <option value="">Please Select</option>
            {customFieldsValues &&
              customFieldsValues.map((c: any) => {
                if (fields[c.name] !== true) {
                  return (
                    <option value={c.name} key={c.name}>
                      {c.name}
                    </option>
                  );
                }
              })}
          </Input>
          <Table striped size="sm">
            <tbody>
              {fields &&
                R.keys(fields).length > 0 &&
                R.keys(fields).map((field: string) => (
                  <tr key={field}>
                    <td className={customFieldStyles.fieldItem}>{field}</td>
                    <td>
                      <Button
                        onClick={() => handleRemoveField(field)}
                        disabled={false}
                        className={customFieldStyles.removeButton}
                      >
                        <MinusIcon
                          fill="white"
                          class={iconStyles.minusButton}
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </td>
        <td>
          <Button onClick={handleAddField} disabled={addDisabled}>
            <PlusIcon fill="white" class={iconStyles.addButton} />
          </Button>
        </td>
        <td>
          <Button onClick={toggle} disabled={disabled}>
            Save Field Group
          </Button>
        </td>
      </tr>
      <Modal isOpen={modal} toggle={(e) => toggle(false)}>
        <ModalHeader toggle={(e) => toggle(false)}>Please Confirm</ModalHeader>
        <ModalBody>
          Please confirm that you would like to create this new custom field
          group. Saving will cause these fields to be created in all accounts of
          this type. This can NOT be undone.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => toggle(true)}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => toggle(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export const CustomFieldGroups = () => {
  const [enumerations] = useObjectVal(ref('enumerations')) as any;
  const [customFields, customFieldsLoading, customFieldsError] = useObjectVal(
    ref('customFields')
  );
  const [validationError, setValidationError] = useState('');
  const [
    customFieldGroupValues,
    customFieldGroupLoading,
    customFieldGroupError,
  ] = useListVals(ref('customFieldsByCategory'));

  if (customFieldGroupLoading || !enumerations || customFieldsLoading) {
    return <Loader loading={true} />;
  }

  if (customFieldGroupError || customFieldsError) {
    return <React.Fragment>Error</React.Fragment>;
  }

  const ddAccountTypes = Object.values(enumerations.ddAccountTypes) || [];
  // logger.debug(`customFieldGroupValues: ${JSON.stringify(customFieldGroupValues)}`)

  return (
    <div className={pagesStyles.container}>
      {validationError && validationError !== '' && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="danger">{validationError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12}>
          <Alert color="warning">
            Any fields assigned to the Account Types below will be synced to any
            Dotdigital accounts which have that Account Type specified. The
            process which generates any custom fields in Dotdigital will run
            hourly, on the hour.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 12 }}>
          <Table striped responsive>
            <tbody>
              {ddAccountTypes
                ? ddAccountTypes.map((accountType: any) => (
                    <CustomFieldGroupRow
                      accountType={accountType}
                      customFields={customFields}
                      setValidationError={setValidationError}
                      key={accountType.value}
                      val={R.find(R.propEq('key', accountType.value))(
                        customFieldGroupValues as any
                      )}
                    />
                  ))
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};
