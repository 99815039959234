import { Form } from '@availity/form';
import * as React from 'react';
import { Col, Row, Card, CardHeader, CardBody, Alert, Button } from 'reactstrap';
import { logger } from '../../logging';

const logoImage = require('../../../public/universal_music_group_transparent_40pc.png');
const styles = require('./login-form.css');

interface LoginFormProps {
  errorMessage?: string;
  login: () => void;
}

export const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { errorMessage, login } = props;

  const handleLogin = async (e) => {
    logger.debug(`handleLogin: called with ${e}`);
    await login();
    logger.debug(`handleLogin: finshed`);
  };

  return (
    <Col sm={{ size: 12 }} className="login-page-col">
      <Row>
        <Col sm={{ size: 12 }} className={styles.hero}>
          <img src={logoImage} />
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader>Login</CardHeader>
            <CardBody>
              {errorMessage && <Alert color="warning">{errorMessage}</Alert>}
              <Form
                initialValues={{}}
                onSubmit={(values) => logger.debug(`[LoginForm] values: ${JSON.stringify(values)}`)}
              >
                <Button type="submit" onClick={handleLogin}>
                  Login
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
