import * as React from 'react';
import { useList, useObjectVal } from 'react-firebase-hooks/database';
import { useState } from 'react';
import { ref } from '../../utils/firebase';
import { arrayToOptions } from '../../utils/react';
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { PlusIcon } from '../../components/Icons';
import { Loader } from '../../components/Loader';
import { logger } from '../../logging';
import moment from 'moment';

const pagesStyles = require('../pages.css');
const iconStyles = require('../../components/icons.css');
const customFieldsStyles = require('./customFields.css');
const GLOBAL_NAMESPACE = 'CM';

const areSyncFieldsValid = (shouldSync, cat, lyticsField) => {
  if (shouldSync) {
    if (cat === '' || lyticsField === '') {
      return false;
    }
  }

  return true;
};

const CustomFieldRow = ({ refKey, val, enumerations }) => {
  const customFieldTypes = Object.values(enumerations.customFieldType) || [];
  const customFieldVisibilities =
    Object.values(enumerations.customFieldVisibility) || [];
  const customFieldCategories =
    Object.values(enumerations.customFieldCategory) || [];

  const [category, setCategory] = useState(val.category || '');
  const [lyticsFieldName, setLyticsFieldName] = useState(
    val.lyticsFieldName || ''
  );
  const [shouldSyncFromLyticsToDD, setShouldSyncFromLyticsToDD] = useState(
    val.shouldSyncFromLyticsToDD || false
  );
  const [disabled, setDisabled] = useState(true);

  const updateCustomField = async () => {
    try {
      const now = moment().unix();
      const customFieldRef = ref(`customFields/${refKey}`);
      const customFieldSnap = await customFieldRef.once('value');
      if (customFieldSnap.exists()) {
        const customField = {
          ...val,
          category,
          lyticsFieldName,
          shouldSyncFromLyticsToDD,
          modified: now,
        };
        await customFieldRef.update(customField);
        setDisabled(true); // we have successfully updated to there are no changes to commit
      } else {
        logger.error(
          `[updateCustomField] customField (${val.name}) does not exist`
        );
        return;
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const handleCategoryChanged = (e) => {
    const val = e.target.value;
    setCategory(val);
    setDisabled(true);
    if (areSyncFieldsValid(shouldSyncFromLyticsToDD, val, lyticsFieldName)) {
      setDisabled(false);
    }
  };

  const handleLyticsFieldNameChanged = (e) => {
    const val = e.target.value;
    // clean fieldname here
    const nameClean = val
      .replace(/[^a-zA-Z0-9 \_]/g, '')
      .replace(/[ ]/g, '_')
      .toLowerCase();
    setLyticsFieldName(nameClean);
    setDisabled(true);
    if (areSyncFieldsValid(shouldSyncFromLyticsToDD, category, nameClean)) {
      setDisabled(false);
    }
  };

  const handleShouldSyncChanged = (e) => {
    const val = e.target.checked;
    setShouldSyncFromLyticsToDD(val);
    setDisabled(true);
    if (areSyncFieldsValid(val, category, lyticsFieldName)) {
      setDisabled(false);
    }
  };

  return (
    <tr>
      <td>{val.name}</td>
      <td>
        <Input
          type="select"
          name="customFieldTypes"
          value={val.type}
          disabled={true}
        >
          {arrayToOptions(customFieldTypes)}
        </Input>
      </td>
      <td>
        <Input
          type="select"
          name="customFieldVisibilities"
          value={val.visibility}
          disabled={true}
        >
          {arrayToOptions(customFieldVisibilities)}
        </Input>
      </td>
      <td>
        <Input
          name="defaultValue"
          placeholder=""
          value={val.defaultValue}
          disabled={true}
        />
      </td>
      <td className={customFieldsStyles.center}>
        <Input
          name="shouldSyncFromLyticsToDD"
          checked={shouldSyncFromLyticsToDD}
          type="checkbox"
          onChange={handleShouldSyncChanged}
        />
      </td>
      <td>
        <Input
          type="select"
          name="customFieldCategories"
          value={category}
          onChange={handleCategoryChanged}
        >
          {arrayToOptions(customFieldCategories)}
        </Input>
      </td>
      <td>
        <Input
          name="lyticsFieldName"
          placeholder=""
          value={lyticsFieldName}
          onChange={handleLyticsFieldNameChanged}
        />
      </td>
      <td>
        <Button onClick={updateCustomField} disabled={disabled}>
          Update
        </Button>
      </td>
    </tr>
  );
};

const CreateCustomFieldRow = ({ enumerations, setValidationError }) => {
  const customFieldTypes = Object.values(enumerations.customFieldType) || [];
  const customFieldVisibilities =
    Object.values(enumerations.customFieldVisibility) || [];
  const customFieldCategories =
    Object.values(enumerations.customFieldCategory) || [];

  const [name, setName] = useState(`${GLOBAL_NAMESPACE}_`);
  const [fieldType, setFieldType] = useState('String');
  const [visibility, setVisibility] = useState('Private');
  const [defaultValue, setDefaultValue] = useState('');
  const [category, setCategory] = useState('');
  const [lyticsFieldName, setLyticsFieldName] = useState('');
  const [shouldSyncFromLyticsToDD, setShouldSyncFromLyticsToDD] =
    useState(false);
  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState(false);

  const toggle = (save?) => {
    if (save === true) {
      logger.info(`[toggle] save`);
      createCustomField();
    }

    setModal(!modal);
  };

  // used if a duplicate value/name is found
  const reset = () => {
    setName(`${GLOBAL_NAMESPACE}_`);
    setFieldType('String');
    setVisibility('Private');
    setDefaultValue('');
    setCategory('');
    setLyticsFieldName('');
    setShouldSyncFromLyticsToDD(false);
    setDisabled(true);
  };

  const createCustomField = async () => {
    try {
      const now = moment().unix();
      const customFieldsRef = ref(`customFields/${name}`);
      const customFieldsSnap = await customFieldsRef.once('value');
      if (customFieldsSnap.exists()) {
        // already have a key with this value, can't create
        reset();
        setValidationError(
          'Duplicate keys are not allowed, please use a different name'
        );
        return;
      }

      const customField = {};
      customField['name'] = name;
      customField['type'] = fieldType;
      customField['visibility'] = visibility;
      customField['defaultValue'] = (defaultValue && defaultValue) || null;
      customField['category'] = category;
      customField['lyticsFieldName'] = lyticsFieldName;
      customField['shouldSyncFromLyticsToDD'] = shouldSyncFromLyticsToDD;
      customField['created'] = now;
      customField['modified'] = now;

      await customFieldsRef.update(customField);

      setValidationError('');
      reset();
    } catch (e) {
      logger.error(e);
      reset();
    }
  };

  const isIsoDate = (str) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(str)) return false;
    return true;
  };

  // this may not be required as API allows any value to be set as default
  const isDefaultValueValid = (type, value) => {
    if (type === 'Numeric' && isNaN(value) && value !== '') {
      logger.info(
        `[isDefaultValueValid] defaultValue (${value}) is not numeric`
      );
      return false;
    }

    if (
      type === 'Boolean' &&
      value !== 'true' &&
      value !== 'false' &&
      value !== ''
    ) {
      logger.info(
        `[isDefaultValueValid] defaultValue (${value}) is not boolean typeof: ${typeof value}`
      );
      return false;
    }

    if (type === 'Date' && isIsoDate(value) !== true && value !== '') {
      logger.info(`[isDefaultValueValid] defaultValue (${value}) is not date`);
      return false;
    }

    logger.info('[isDefaultValueValid] called and returning true');
    return true;
  };

  const handleNameChanged = (e) => {
    const val = e.target.value;
    const nameClean = val
      .replace(/[^a-zA-Z0-9 \_]/g, '')
      .replace(/[ ]/g, '_')
      .toUpperCase();
    const regex = new RegExp(`^${GLOBAL_NAMESPACE}_`, 'g');
    const suffix =
      regex.test(nameClean) === false ? `${GLOBAL_NAMESPACE}_` : '';
    const namespacedNameClean = `${suffix}${nameClean}`.substring(0, 25);
    setName(namespacedNameClean);
    setDisabled(true);
    if (
      namespacedNameClean !== '' &&
      namespacedNameClean !== `${GLOBAL_NAMESPACE}_` &&
      isDefaultValueValid(fieldType, defaultValue) &&
      areSyncFieldsValid(shouldSyncFromLyticsToDD, category, lyticsFieldName)
    ) {
      setDisabled(false);
    }
  };

  const handleFieldTypeChanged = (e) => {
    const val = e.target.value;
    setFieldType(val);
    setDefaultValue('');
    setDisabled(true);
    if (
      name !== '' &&
      name !== `${GLOBAL_NAMESPACE}_` &&
      isDefaultValueValid(val, defaultValue) &&
      visibility !== '' &&
      val !== '' &&
      areSyncFieldsValid(shouldSyncFromLyticsToDD, category, lyticsFieldName)
    ) {
      setDisabled(false);
    }
  };

  const handleVisibilityChanged = (e) => {
    const val = e.target.value;
    setVisibility(val);
    setDisabled(true);
    logger.info(`[handleVisibilityChanged] typeof val: ${typeof val}`);
    if (
      name !== '' &&
      name !== `${GLOBAL_NAMESPACE}_` &&
      isDefaultValueValid(fieldType, defaultValue) &&
      val !== '' &&
      fieldType !== '' &&
      areSyncFieldsValid(shouldSyncFromLyticsToDD, category, lyticsFieldName)
    ) {
      setDisabled(false);
    }
  };

  const handleDefaultValueChanged = (e) => {
    const val = e.target.value;
    setDefaultValue(val);
    setDisabled(true);
    if (
      name !== '' &&
      name !== `${GLOBAL_NAMESPACE}_` &&
      isDefaultValueValid(fieldType, val) &&
      visibility !== '' &&
      fieldType !== '' &&
      areSyncFieldsValid(shouldSyncFromLyticsToDD, category, lyticsFieldName)
    ) {
      setDisabled(false);
    }
  };

  const handleCategoryChanged = (e) => {
    const val = e.target.value;
    setCategory(val);
    setDisabled(true);
    if (
      name !== '' &&
      name !== `${GLOBAL_NAMESPACE}_` &&
      visibility !== '' &&
      isDefaultValueValid(fieldType, defaultValue) &&
      areSyncFieldsValid(shouldSyncFromLyticsToDD, val, lyticsFieldName)
    ) {
      setDisabled(false);
    }
  };

  const handleLyticsFieldNameChanged = (e) => {
    const val = e.target.value;
    // clean fieldname here
    const nameClean = val
      .replace(/[^a-zA-Z0-9 \_]/g, '')
      .replace(/[ ]/g, '_')
      .toLowerCase();
    setLyticsFieldName(nameClean);
    setDisabled(true);
    if (
      name !== '' &&
      name !== `${GLOBAL_NAMESPACE}_` &&
      visibility !== '' &&
      isDefaultValueValid(fieldType, defaultValue) &&
      areSyncFieldsValid(shouldSyncFromLyticsToDD, category, nameClean)
    ) {
      setDisabled(false);
    }
  };

  const handleShouldSyncChanged = (e) => {
    const val = e.target.checked;
    setShouldSyncFromLyticsToDD(val);
    setDisabled(true);
    if (
      name !== '' &&
      name !== `${GLOBAL_NAMESPACE}_` &&
      visibility !== '' &&
      isDefaultValueValid(fieldType, defaultValue) &&
      areSyncFieldsValid(val, category, lyticsFieldName)
    ) {
      setDisabled(false);
    }
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          <Input
            name="name"
            placeholder="Name"
            value={name}
            onChange={handleNameChanged}
          />
        </td>
        <td>
          <Input
            type="select"
            name="customFieldTypes"
            value={fieldType}
            onChange={handleFieldTypeChanged}
          >
            {arrayToOptions(customFieldTypes)}
          </Input>
        </td>
        <td>
          <Input
            type="select"
            name="customFieldVisibilities"
            value={visibility}
            onChange={handleVisibilityChanged}
            disabled={true}
          >
            {arrayToOptions(customFieldVisibilities)}
          </Input>
        </td>
        {fieldType === 'Boolean' ? (
          <td>
            <Input
              type="select"
              name="defaultValueBoolean"
              placeholder="Default Value"
              value={defaultValue}
              onChange={handleDefaultValueChanged}
            >
              <option value="">Please Select</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </Input>
          </td>
        ) : (
          <td>
            <Input
              name="defaultValue"
              placeholder={
                fieldType === 'Date'
                  ? 'Default Value e.g. 2021-05-02T23:00:00Z'
                  : 'Default Value'
              }
              value={defaultValue}
              onChange={handleDefaultValueChanged}
            />
          </td>
        )}
        <td className={customFieldsStyles.center}>
          <Input
            name="shouldSyncFromLyticsToDD"
            checked={shouldSyncFromLyticsToDD}
            type="checkbox"
            onChange={handleShouldSyncChanged}
          />
        </td>
        <td>
          <Input
            type="select"
            name="customFieldCategories"
            value={category}
            onChange={handleCategoryChanged}
          >
            {arrayToOptions(customFieldCategories)}
          </Input>
        </td>
        <td>
          <Input
            name="lyticsFieldName"
            placeholder=""
            value={lyticsFieldName}
            onChange={handleLyticsFieldNameChanged}
          />
        </td>
        <td>
          <Button onClick={toggle} disabled={disabled}>
            <PlusIcon fill="white" class={iconStyles.addButton} />
          </Button>
        </td>
      </tr>
      <Modal isOpen={modal} toggle={(e) => toggle(false)}>
        <ModalHeader toggle={(e) => toggle(false)}>Please Confirm</ModalHeader>
        <ModalBody>
          Please confirm that you would like to create this new custom field.
          This can NOT be undone.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => toggle(true)}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => toggle(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export const CustomFields = () => {
  const [enumerations] = useObjectVal(ref('enumerations')) as any;
  const [customFieldValues, customFieldLoading, customFieldError] = useList(
    ref('customFields')
  );
  const [validationError, setValidationError] = useState('');

  if (customFieldLoading || !enumerations) {
    return <Loader loading={true} />;
  }

  if (customFieldError) {
    return <React.Fragment>Error</React.Fragment>;
  }

  return (
    <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
      {validationError && validationError !== '' && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="danger">{validationError}</Alert>
          </Col>
        </Row>
      )}
      <Row className={pagesStyles.fullHeight}>
        <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
          <div className={customFieldsStyles.fullHeightTableContainer}>
            <div className={customFieldsStyles.fitContentTableContainer}>
              <Table striped className={customFieldsStyles.customFieldsTable}>
                <thead>
                  <tr>
                    <th>DD Field Name</th>
                    <th>Data Type</th>
                    <th>Private/Public</th>
                    <th>Default Value</th>
                    <th>Sync to DD</th>
                    <th>Category</th>
                    <th>Mapped Field Name</th>
                    <th>Actions</th>
                  </tr>
                  <CreateCustomFieldRow
                    enumerations={enumerations}
                    setValidationError={setValidationError}
                  />
                </thead>
                <tbody>
                  {customFieldValues.map((c) => (
                    <CustomFieldRow
                      refKey={c.key}
                      val={c.val()}
                      key={c.key}
                      enumerations={enumerations}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
