import moment = require('moment');
import { Addressbook } from '../../addressbooks/domain/addressbook';
import { Textword } from '../../brandAttribution/domain/textword';

export interface Optin {
  active: boolean;
  country: string;
  name: string;
  brandId: string;
  category: string;
  channel: string;
  key?: string;
  target_id: string; // eg. push key for textwords, account_addressbook id for addressbooks, ...future channels/systems tbd
  accountName: string;
  timestamp?: string; //testing
  tag?: string;
  created?: number;
  modified?: number;
  vendor?: string;
}

export enum Channel {
  EMAIL = 'email',
  SMS = 'sms',
  WHATSAPP = 'whatsapp',
}

const checkRequiredFields = (
  object: any,
  requiredFields: string[]
): boolean => {
  for (const field of requiredFields) {
    if (!object[field] || object[field] === '') {
      return false;
    }
  }

  return true;
};

const checkAddressbookActive = (
  addressbook: Addressbook,
  requiredFields: string[]
): boolean => {
  if (!checkRequiredFields(addressbook, requiredFields)) {
    return false;
  }

  return (
    addressbook.nonConsented !== true &&
    addressbook.dataCapture === true &&
    addressbook.doNotShare !== true &&
    addressbook.deletedFromDD !== true &&
    (addressbook.canopusValid === true || addressbook.partyIdValid === true)
  );
};

const checkTextwordActive = (
  textword: Textword,
  requiredFields: string[]
): boolean => {
  if (!checkRequiredFields(textword, requiredFields)) {
    return false;
  }

  return (
    textword.isRemovedFromSlicktext !== true &&
    (textword.verifiedcanopus === true || textword.verifiedpartyid === true)
  );
};

export const fromAddressbook = (
  addressbook: Addressbook,
  existingOptin?: Optin
) => {
  const requiredFields = ['country', 'category', 'accountName', 'key', 'tag'];
  if (addressbook.partyIdValid === true) {
    requiredFields.push('party_id');
    requiredFields.push('party_name');
  } else {
    requiredFields.push('canopusId');
    requiredFields.push('canopusName');
  }

  const active = checkAddressbookActive(addressbook, requiredFields);
  const now = moment().unix();

  const optin: Optin = {
    ...(existingOptin && existingOptin),
    ...(existingOptin && { modified: now }),
    ...(!existingOptin && { created: now }),
    active,
    tag: addressbook.tag || '',
    accountName: addressbook.accountName || '',
    target_id: addressbook.key || '',
    country: addressbook.country ? addressbook.country.toLowerCase() : '',
    brandId: `${addressbook.party_id || addressbook.canopusId || ''}`,
    channel: Channel.EMAIL || '',
    category: addressbook.category || '',
    name: addressbook.party_name || addressbook.canopusName || '',
    vendor: 'dotdigital',
  };

  return optin;
};

export const fromTextword = (textword: Textword, existingOptin?: Optin) => {
  const requiredFields = [
    'country',
    'label',
    'category',
    'accountName',
    'id',
    'tag',
  ];
  if (textword.party_id && typeof textword.party_id !== undefined) {
    requiredFields.push('party_id');
    requiredFields.push('party_name');
  } else {
    requiredFields.push('canopusId');
    requiredFields.push('canopusName');
  }

  const active = checkTextwordActive(textword, requiredFields);
  const now = moment().unix();

  const optin: Optin = {
    ...(existingOptin && existingOptin),
    ...(existingOptin && { modified: now }),
    ...(!existingOptin && { created: now }),
    active,
    tag: textword.tag || '',
    accountName: textword.accountName || '',
    target_id: textword.key || '',
    country: textword.country ? textword.country.toLowerCase() : '',
    brandId: `${textword.party_id || textword.canopusId || ''}`,
    channel: Channel.SMS || '',
    category: textword.category || '',
    name: textword.party_name || textword.canopusName || '',
    vendor: 'slicktext',
  };

  return optin;
};

export const columns: any = [
  'key',
  'country',
  'channel',
  'category',
  'brand_id',
  'name',
  'tag',
  'target_id',
  'account_name',
  'created',
  'modified',
  'vendor',
];

const fieldsToSnake: any = {
  key: 'key',
  country: 'country',
  channel: 'channel',
  category: 'category',
  brandId: 'brand_id',
  name: 'name',
  tag: 'tag',
  target_id: 'target_id',
  accountName: 'account_name',
  created: 'created',
  modified: 'modified',
  vendor: 'vendor',
};

export const dataTypes = {
  created: 'TIMESTAMP',
  modified: 'TIMESTAMP',
};

export interface OptinProps {
  active: boolean;
  country: string;
  name: string;
  brandId: string;
  category: string;
  channel: string;
  key: string;
  target_id: string; // eg. push key for textwords, account_addressbook id for addressbooks, ...future channels/systems tbd
  accountName: string;
  timestamp: string;
  tag: string;
  created: number;
  modified: number | null;
  [index: string]: string | number | boolean | null;
}

export const create = (props: OptinProps) => {
  const finalOptin: any = {};
  const keys = Object.keys(props);
  for (const key of keys) {
    const snakeKey = fieldsToSnake[key];
    finalOptin[snakeKey] = props[key];
  }

  return finalOptin;
};
