const pino = require('pino');
import { getDAMApp } from './services/firebase';

const level = process.env.NODE_ENV === 'prod' ? 'info' : 'trace';

export const logger = pino({ level, name: 'digital-accounts-management' });

export const serverLogger = async (message: string, level: string) => {
  try {
    const damApp = getDAMApp();
    const callable = damApp.functions().httpsCallable('accountManager-callableLogger');
    await callable({ message, level });
  } catch (e) {
    logger.error('Error calling callableLogger');
    throw new Error('Error calling callableLogger');
  }
};
