import * as React from 'react';
import { useContext } from 'react';
import { Col, Row, TabPane } from 'reactstrap';
import { TEXTWORDS_TAB } from '../AccountForm';
import { Loader } from '../../../components/Loader';
import { Textwords } from './Textwords';
import { EnumerationsContext } from '../../enumerations/EnumerationsContext';
import { useObjectVal } from 'react-firebase-hooks/database';
import { getDAMApp } from '../../../services/firebase';
import { CountriesContext } from '../../country-manager/CountriesContext';
import { ref } from '../../../utils/firebase';

const pagesStyles = require('../../pages.css');

export interface Textword {
  id: number;
  accountId: string;
  accountName: string;
  accountid_id: string;
  added: string;
  ageRequirement: number;
  autoReply: string;
  canopusId?: string;
  canopus_verified: boolean;
  contactsCount: number;
  contacts_count: number;
  country: string;
  doubleOptIn: number;
  lastSynced: number;
  modified: number;
  optOuts: number;
  sentToBQ?: number;
  shortcode: number;
  termsLink: string;
  textNumber: number;
  verifiedcanopus: boolean;
  verifiedcanopus_accountid_id: string;
  verifiedcanopus_senttobq: string;
  word: string;
  label?: string;
}

interface Textwords {
  textwords: Textword[];
}

export const TextwordsTab = ({ account }) => {
  const damApp = getDAMApp();
  const { enumerations, loadingEnumerations } = useContext(EnumerationsContext);
  const { countries, loadingCountries } = useContext(CountriesContext);
  const [countriesValues, loadingCountriesValues] = useObjectVal(
    ref('countries') as any
  ) as any;

  const labelsRef = damApp.database().ref('labels');
  const [labels, labelsLoading, labelsError] = useObjectVal(labelsRef as any);

  if (
    !enumerations.enumerations ||
    loadingEnumerations ||
    !labels ||
    labelsLoading ||
    !countries ||
    loadingCountries ||
    !countriesValues ||
    loadingCountriesValues
  ) {
    return (
      <TabPane tabId={TEXTWORDS_TAB}>
        <Loader loading={true} />
      </TabPane>
    );
  }

  return (
    <TabPane tabId={TEXTWORDS_TAB}>
      <div>
        <Row>
          <Col sm={{ size: 12 }}>
            <Textwords
              account={account}
              enumerations={enumerations}
              labels={labels}
              countriesModel={countries}
              countries={countriesValues}
            />
          </Col>
        </Row>
      </div>
    </TabPane>
  );
};
