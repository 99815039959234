.formRow {
  margin-top: 20px;
}

.button {
  margin-right: 10px;
}

.fullHeight {
  height: 100%;
}

.fullHeightTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fitContentTableContainer {
  width: fit-content;
  flex: 1;
  overflow-y: auto;
}

.artistMappingTable select,
.artistMappingTable input {
  width: -webkit-fill-available;
  margin-top: 5px;
}

.artistMappingTable {
  border-collapse: separate;
  border-spacing: 0;
}

.artistMappingTable th {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  z-index: 1;
}

.artistMappingTable td {
  border-right: 1px solid #dee2e6;
}

.artistMappingTable th:first-child,
.artistMappingTable td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid #dee2e6;
}

.artistMappingTable tr:last-child td {
  /* Apply a left border on the first <td> or <th> in a row */
  border-bottom: 1px solid #dee2e6;
}

.artistMappingTable {
  overflow-x: inherit;
}

.artistMappingTableContainer {
  overflow-x: inherit;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.artistMappingVerify {
  margin-left: 10px;
}

.canopusLoader {
  height: 40px;
  width: 40px;
  padding: 0px;
  margin-left: -23px;
}

.queryRow {
  margin-bottom: 20px;
}

.pagination button {
  margin: 5px;
}

.canopusOptionsContainer {
  display: flex;
}

.canopusOptionsContainer select {
  margin-left: 10px;
}

.canopusOptionsContainer span {
  margin-top: 9px;
}
