import * as React from 'react';
import { useObjectVal, useObject } from 'react-firebase-hooks/database';
import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Alert,
} from 'reactstrap';
import { Field } from '@availity/form';
import { SelectField } from '@availity/select';
import '@availity/yup';
import { ref } from '../../../utils/firebase';
import { Can } from '../../../auth/Can';
import { Loader } from '../../../components/Loader';
import { logger } from '../../../logging';
import * as R from 'ramda';
import { getDAMApp, getIFBApp } from '../../../services/firebase';
import { Feature, useFeature } from 'flagged';
import { useFormikContext } from 'formik';
import { BrandInputField } from '../../../components/brand/BrandInputField';
import { BrandService } from '../../../components/brand/BrandLookup';

const accountStyles = require('../account.css');

// account name should be unique
export const validateAccountName = async (
  rawValue,
  key,
  country,
  setFieldError
) => {
  logger.debug(
    `[DDFields] validateAccountName called with, ${rawValue}, ${key}, ${country}`
  );
  const damApp = getDAMApp();
  const errorMessage =
    'An account name must be provided and unique for each territory (operating country)';

  const value = rawValue ? rawValue.trim() : '';

  if (!value || value === '') {
    logger.info(
      '[DDFields] [validateAccountName] there is no value, so we dont lookup. Returning as this is the cause of a setstate error'
    );
    setFieldError('ddAccountName', errorMessage);
    return false;
    // there's no value so it is invalid
  }

  const accountsRef = damApp
    .database()
    .ref('accounts/dotdigital')
    .orderByChild('ddAccountName')
    .equalTo(value);
  const accountsSnap = await accountsRef.once('value');

  const accounts = [];
  accountsSnap.forEach((v) => {
    accounts.push({ ...v.val(), key: v.key });
  });

  logger.debug(
    `[DDFields] validateAccountName accounts.length, ${accounts.length}`
  );
  // so obviously there's an account with this name (if we're editing)
  if (accounts.length > 0) {
    for (const account of accounts) {
      // if there is an account with this name but a different uid, then we have a validation error
      // allow same-named accounts ONLY IF COUNTRY DIFFERENT
      if (
        account.key !== key &&
        (account.territoryOperatingCountry === country || country === '')
      ) {
        logger.debug(
          `[DDFields] [validateAccountName] account.territoryOperatingCountry: ${account.territoryOperatingCountry}, value: ${value}, country: ${country}`
        );
        logger.debug(
          `[DDFields] validateAccountName account.key (${account.key}), key (${key}) keys not same`
        );
        setFieldError('ddAccountName', errorMessage);
        return false;
      }
    }
  }

  return true;
};

// account id should be unique
export const validateAccountId = async (rawValue, key, setFieldError) => {
  const damApp = getDAMApp();
  const errorMessage = 'An account ID must be provided and unique';

  const value = rawValue ? rawValue.trim() : '';

  if (!value || value === '') {
    logger.debug(
      '[DDFields] [validateAccountId] there is no value, so we dont lookup. Returning as this is the cause of a setstate error'
    );
    setFieldError('ddAccountId', errorMessage);
    return false; // there's no value so it is invalid
  }

  const accountsRef = damApp
    .database()
    .ref('accounts/dotdigital')
    .orderByChild('ddAccountId')
    .equalTo(value);
  const accountsSnap = await accountsRef.once('value');

  const accounts = [];
  accountsSnap.forEach((v) => {
    accounts.push({ ...v.val(), key: v.key });
  });

  // so obviously there's an account with this name (if we're editing)
  if (accounts.length > 0) {
    const account = accounts[0];
    // if there is an account with this name but a different uid, then we have a validation error
    if (account.key !== key) {
      logger.debug(
        `[DDFields] [validateAccountId] account.key (${account.key}) and key (${key}) are not the same`
      );
      setFieldError('ddAccountId', errorMessage);
      return false;
    }
  }

  return true;
};

export const DDFields = ({ model, setCanSave, accountId, system, errors }) => {
  const formik = useFormikContext();
  const damApp = getDAMApp();
  const ifbApp = getIFBApp();
  const [errorMessage, setErrorMessage] = useState('');
  const areDDInsightsEnabled = useFeature('dd-insights-admin');

  // get the custom fields for this account that exist with no errors
  const accountFieldNames = R.reduce(
    (acc: string[], name: string) => {
      const field = model.customFields[name];
      if (!field.error || field.error === '') {
        return R.append(name, acc);
      }

      return acc;
    },
    [],
    Object.keys(model.customFields || {})
  );

  // get the fields classified as 'genre'
  const genreFieldsRef = damApp
    .database()
    .ref('customFields')
    .orderByChild('category')
    .equalTo('genre');
  const [genreFieldsSnap, genreFieldsLoading, genreFieldsError] = useObject(
    genreFieldsRef as any
  );
  // get the fields classified as 'demographic'
  const demographicFieldsRef = damApp
    .database()
    .ref('customFields')
    .orderByChild('category')
    .equalTo('demographic');
  const [
    demographicFieldsSnap,
    demographicFieldsLoading,
    demographicFieldsError,
  ] = useObject(demographicFieldsRef as any);

  // does this account have any genre fields?
  let hasGenreFields = false;
  if (genreFieldsSnap) {
    // check accountFieldNames against genreFields
    const genreFieldNames = [];
    genreFieldsSnap.forEach((s) => {
      const field = s.val();
      genreFieldNames.push(field.name);
    });

    const isect = R.intersection(accountFieldNames, genreFieldNames);
    hasGenreFields = isect.length > 0;
  }

  let hasDemographicFields = false;
  if (demographicFieldsSnap) {
    const demographicFieldNames = [];
    demographicFieldsSnap.forEach((s) => {
      const field = s.val();
      demographicFieldNames.push(field.name);
    });

    const isect = R.intersection(accountFieldNames, demographicFieldNames);
    hasDemographicFields = isect.length > 0;
  }

  const checkIfCanSave = () => {
    if (errorMessage === '') {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  };

  useEffect(() => {
    if (errors) {
      const invalidFields = Object.keys(errors);
      // logger.debug(`invalidFields: ${JSON.stringify(invalidFields)}`);
      if (invalidFields.length > 0) {
        setErrorMessage(
          `The following fields are set incorrectly: ${JSON.stringify(
            invalidFields
          )}`
        );
        checkIfCanSave();
      } else {
        setErrorMessage('');
        checkIfCanSave();
      }
    }
  });

  const [count, setCount] = useState(0);
  const getAddressbookCount = async (ddAccountId) => {
    logger.debug(`[getAddressbookCount] ddAccountId: ${ddAccountId}`);
    const addressbookSnap = await ifbApp
      .database()
      .ref('addressbooks')
      .orderByChild('account_addressbookname')
      .startAt(`${ddAccountId}`)
      .endAt(`${ddAccountId}\uf8ff`)
      .once('value');

    const addressbookCount = addressbookSnap.numChildren();
    setCount(addressbookCount);
  };

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      logger.debug(
        `[useEffect] model.ddAccountId: ${model.ddAccountId}, calling getAddressbookCount`
      );
      getAddressbookCount(model.ddAccountId);
    }
    return () => {
      isMounted = false;
    };
  }, [model]);

  const [enumerations] = useObjectVal(ref('enumerations') as any) as any;
  const [countries, countriesLoading] = useObjectVal(
    ref('countries') as any
  ) as any;
  const [labelValues, labelLoading, labelError] = useObjectVal(
    ref('labels') as any
  );

  // active/inactive toggle confirmation modal
  const [modal, setModal] = useState(false);
  const toggle = (ok?) => {
    if (ok === false) {
      formik.setFieldValue('active', !formik.values['active']); // Maybe look at default here instead
    }

    setModal(!modal);
  };

  // active/inactive toggle confirmation merchModal
  const [merchModal, setMerchModal] = useState(false);
  const toggleMerchModal = (ok?) => {
    if (ok === false) {
      formik.setFieldValue(
        'allowMerchRecommendationsForAddressbooks',
        !formik.values['allowMerchRecommendationsForAddressbooks']
      );
    }

    setMerchModal(!merchModal);
  };

  //active/inactive toggle confirmation countryModal
  const [countryModal, setCountryModal] = useState(false);
  const toggleCountryModal = (ok?) => {
    if (ok === false) {
      logger.debug(
        `[toggleCountryModal] ok = ${ok} - reverting to previous selection: ${model['territoryOperatingCountry']}`
      );
      formik.setFieldValue(
        'territoryOperatingCountry',
        model['territoryOperatingCountry']
      );
    }

    setCountryModal(!countryModal);
  };

  const [accountLevelParent, setAccountLevelParent] = useState(null);

  // so if we don't have an accountlevelparent but the model does, we need to find the matching 'option' record in the enum options array
  // this is a react-select thing because windowedselect wraps react-select
  // this is all to improve performance for the large number of options in this select
  // we have also had to use a hidden field to store the 'plain' value because react-select only returns an object (with .value property)
  // and avform requires a simple value (the .value property)
  if (
    !accountLevelParent &&
    enumerations &&
    model &&
    model.accountLevelParent
  ) {
    const parentValues =
      Object.values(enumerations.ddParentAccountLevels) || [];
    const val = parentValues.find((v: any) => {
      return v.value === model.accountLevelParent;
    });

    if (val) {
      logger.debug(`[DDFields] setting acconutLevelParent - val: ${val}`);
      setAccountLevelParent(val);
      // formik.setFieldValue('accountLevelParent', val);
    }
  }

  useEffect(() => {
    formik.setFieldValue('accountLevelParent', accountLevelParent);
  }, [accountLevelParent]);

  const disableStorePlatform =
    formik.values['ddAccountType'] !== 'store' &&
    formik.values['ddAccountType'] !== 'store_siloed';

  useEffect(() => {
    // if the account type is not store or store_siloed, and storeplatform is set, unset it
    if (
      formik.values['ddAccountType'] !== 'store' &&
      formik.values['ddAccountType'] !== 'store_siloed' &&
      formik.values['storePlatform'] !== ''
    ) {
      formik.setFieldValue('storePlatform', '');
    }
  });

  const handleAccountLevelParentChange = (val) => {
    logger.debug(`[DDFields] handleAccountLevelParentChange - val: ${val}`);
    setAccountLevelParent(val);
    formik.setFieldValue('accountLevelParent', val);
  };

  const handlePartyIdChange = (partyId, partyName) => {
    logger.debug(
      `[DDFields] handlePartyIdChange: partyId: ${partyId}, partyName: ${partyName}`
    );
    formik.setFieldValue('party_id', partyId);
    formik.setFieldValue('party_name', partyName);
  };

  if (labelLoading || !enumerations || countriesLoading || !countries) {
    return <Loader loading={true} />;
  }

  if (labelError) {
    return <React.Fragment>Error</React.Fragment>;
  }

  const ddAccountLevels =
    (Object.values(enumerations.ddAccountLevels) as any) || [];
  const ddAccountTypes =
    (Object.values(enumerations.ddAccountTypes) as any) || [];
  const umgOrPartner = (Object.values(enumerations.umgOrPartner) as any) || [];
  const regionalGroupings =
    (Object.values(enumerations.regionalGroupings) as any) || [];
  const operatingCountries = (Object.values(countries) as any) || [];
  const ddParentAccountLevels =
    (Object.values(enumerations.ddParentAccountLevels) as any) || [];
  const locales = (Object.values(enumerations.locales) as any) || [];
  const languages = (Object.values(enumerations.languages) as any) || [];
  const timezones = (Object.values(enumerations.timezones) as any) || [];
  const storePlatforms =
    (Object.values(enumerations.storePlatforms) as any) || [];
  const b2bOrB2c = (Object.values(enumerations.b2bOrB2c) as any) || [];
  const ipMappings = (Object.values(enumerations.ipMappings) as any) || [];
  const labels = (Object.values(labelValues) as any) || [];

  // this is called when trying to toggle active. It opens the confirmation modal and stores the desired active value
  const confirmSetActive = (value: boolean) => {
    logger.debug(`[confirmSetActive] value: ${value}`);
    toggle();
  };

  // this is called when the confirm modal button is clicked. It sets the active value and closes the modal.
  const setActiveConfirmed = () => {
    toggle();
  };

  const setActiveCancelled = () => {
    toggle(false);
  };

  const confirmSetMerch = (value: boolean) => {
    toggleMerchModal();
  };

  const setMerchConfirmed = () => {
    toggleMerchModal();
  };

  const setMerchCancelled = () => {
    toggleMerchModal(false);
  };

  const confirmSetCountry = (value: any) => {
    toggleCountryModal();
  };

  const setCountryConfirmed = () => {
    toggleCountryModal();
  };

  const setCountryCancelled = () => {
    toggleCountryModal(false);
  };

  const disableDDAccountId = accountId ? true : false;
  const isNewForm = !accountId;
  const disableIfInactive = formik.values['active'] === false ? true : false;
  const isDE = formik.values['territoryOperatingCountry'] === 'de';

  const isDataAcquisitionTreasureData =
    model['data_acquisition_selection'] === 'treasure_data';

  const disableB2BB2C =
    disableIfInactive === true ||
    (isNewForm === false &&
      model['isB2COrB2B'] !== undefined &&
      model['isB2COrB2B'] !== '');
  const disableLevel =
    disableIfInactive === true ||
    (isNewForm === false &&
      model['ddAccountLevel'] !== undefined &&
      model['ddAccountLevel'] !== '');

  const hasAddressbooks = isNewForm ? false : count === 0 ? false : true;

  logger.debug(
    `isNewForm: ${isNewForm}, count: ${count}, model['ddAccountLevel']: ${model['ddAccountLevel']}, isB2COrB2B: ${model['isB2COrB2B']}: disableB2BB2C: ${disableB2BB2C}., disableLevel: ${disableLevel}`
  );

  return (
    <React.Fragment>
      {errorMessage !== '' ? (
        <Row>
          <Col sm={12}>
            <Alert color="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      ) : null}
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <Field
            name="ddAccountName"
            label="Account Name"
            required
            disabled={disableIfInactive}
          />
          <Field
            name="ddFormerAccountName"
            label="Former Account Name (if repurposed)"
            disabled={disableIfInactive}
          />
          <BrandInputField
            name="party_id"
            label="Party ID"
            brandFieldName="party_name"
            brandFieldNameLabel="Party Name"
            required={false}
            disabled={disableIfInactive}
            handleOnChange={handlePartyIdChange}
            brandService={BrandService.PARTY_SERVICE}
          />
          <Field name="canopusId" label="Canopus ID" disabled={true} />
          <Field
            name="ddAccountId"
            label="Account ID"
            required
            disabled={disableDDAccountId || disableIfInactive}
          />
          <SelectField
            name="ddAccountLevel"
            label="Account Level"
            required
            options={ddAccountLevels}
            isMulti={false}
            isDisabled={disableIfInactive || disableLevel}
          />
          <Alert color="warning">
            Account Type will determine which custom fields will be created for
            this Dotdigital account. The process which generates any custom
            fields in Dotdigital will run hourly, on the hour.
          </Alert>
          <SelectField
            name="ddAccountType"
            label="Account Type"
            required
            options={ddAccountTypes}
            isMulti={false}
            isDisabled={disableIfInactive}
          />
          <SelectField
            isDisabled={disableStorePlatform || disableIfInactive}
            name="storePlatform"
            label="Store Platform"
            options={storePlatforms}
            isMulti={false}
          />
          <SelectField
            name="isB2COrB2B"
            label="B2C/B2B"
            required
            options={b2bOrB2c}
            isMulti={false}
            isDisabled={disableIfInactive || disableB2BB2C}
          />
          <SelectField
            name="isUmgOrPartner"
            label="UMG/Partner"
            required
            options={umgOrPartner}
            isMulti={false}
            isDisabled={disableIfInactive}
          />
          <SelectField
            name="label"
            label="Label"
            required
            options={labels}
            isMulti={false}
            isDisabled={disableIfInactive}
          />
          <Field
            name="seedListName"
            label="Seed List Name"
            disabled={disableIfInactive}
          />
          <Field
            name="shopifyAccountId"
            label="Shopify Account ID"
            disabled={disableIfInactive}
          />
          <Field name="key" type="hidden" />
        </Col>
        <Col sm="6">
          <SelectField
            name="regionalGroupings"
            label="Regional Groupings"
            required
            options={regionalGroupings}
            isMulti={false}
            isDisabled={disableIfInactive}
          />
          <SelectField
            name="accountLevelParent"
            label="Account Level (Parent)"
            options={ddParentAccountLevels}
            isMulti={false}
            onChange={(option) => {
              handleAccountLevelParentChange(option);
            }}
            isDisabled={disableIfInactive}
          />
          <SelectField
            name="territoryOperatingCountry"
            label="Territory (Operating Country)"
            required
            options={operatingCountries}
            isMulti={false}
            isDisabled={disableIfInactive || hasAddressbooks}
            onChange={(option) => confirmSetCountry(option)}
          />
          <SelectField
            name="localeCode"
            label="Locale"
            required
            options={locales}
            isMulti={false}
            isDisabled={disableIfInactive}
          />
          <SelectField
            name="language"
            label="Language"
            required
            options={languages}
            isMulti={false}
            isDisabled={disableIfInactive}
          />
          <SelectField
            name="timezone"
            label="Timezone"
            required
            options={timezones}
            isMulti={false}
            isDisabled={disableIfInactive}
          />
          <Field
            name="ddLeadAccountSetup"
            label="DotDigital Lead Account Setup"
            required
            disabled={disableIfInactive}
          />
          <SelectField
            name="ipMapping"
            label="IP Mapping"
            required
            options={ipMappings}
            isMulti={false}
            isDisabled={disableIfInactive}
          />
          <Field
            name="sendingAccountCFAEmail"
            label="Sending Account (CFA)"
            required
            disabled={disableIfInactive}
          />
          <Field
            name="isUsingCustomDomains"
            label="Using Custom Domains"
            type="checkbox"
            disabled={disableIfInactive}
          />
          <Field
            name="seedListUpdates"
            label="Seed List Update Date"
            type="date"
            disabled={disableIfInactive}
          />
          {!isDataAcquisitionTreasureData && (
            <Feature name="lytics-sync">
              {/* Only enable this if the account has at least one demographic field assigned to its account type. Also show the message that says 'please make sure all fields you wish to sync are mapped and assigned to <account type> */}
              {!hasDemographicFields && (
                <Alert color="warning">
                  This account has no custom fields in the Demographic group.
                  Please ensure at least one Demographic category Custom Field
                  is available on the account.
                </Alert>
              )}
              <Alert color="warning">
                Please note that only Custom fields in the Account Type will be
                synced from Lytics to DotDigital.
              </Alert>
              <Field
                name="createStandardLyticsEntities"
                label="Create Acquisition Sync/Contacts and Activity import (Lytics)"
                type="checkbox"
                disabled={!hasDemographicFields || disableIfInactive}
              />
              {/* Only enable this if the account has at least one genre field assigned to its account type. Also show the message that says 'please make sure all fields you wish to sync are mapped and assigned to <account type> */}

              {areDDInsightsEnabled !== true && !hasGenreFields && (
                <Alert color="warning">
                  This account has no custom fields in the Genre group. Please
                  ensure at least one Genre category Custom Field is available
                  on the account.
                </Alert>
              )}
              <Alert color="warning">
                Please note that only Custom fields in the Account Type will be
                synced from Lytics to DotDigital.
              </Alert>
              {areDDInsightsEnabled !== true ? (
                <Field
                  name="createGenreLyticsEntities"
                  label="Create Genre Sync (Lytics)"
                  type="checkbox"
                  disabled={!hasGenreFields || disableIfInactive || isDE}
                />
              ) : null}
            </Feature>
          )}
          <Field
            name="allowMerchRecommendationsForAddressbooks"
            label="Enable setting 'Allow Merch Recommendations' for Addressbooks"
            type="checkbox"
            onChange={(e) => confirmSetMerch(e.target.checked)}
            disabled={disableIfInactive}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6"></Col>
        <Col sm="6">
          <Can I="delete" an="account">
            <FormGroup check>
              <Label check className={accountStyles.activeCheckboxLabel}>
                Active
              </Label>
              <Field
                className={accountStyles.activeCheckbox}
                name="active"
                type="checkbox"
                onChange={(e) => {
                  confirmSetActive(e.target.checked);
                }}
              />
            </FormGroup>
          </Can>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={(e) => toggle(false)}>
        <ModalHeader toggle={(e) => toggle(false)}>Please Confirm</ModalHeader>
        <ModalBody>
          Please confirm that you would like to change the 'active' status of
          this account. Once saved this change will also update all of the Vault
          credentials' active flag for this account. Additionally, if this
          account has created with the 'Create Acquisition Sync/Contacts and
          Activity import (Lytics)' or 'Create Genre Sync (Lytics)' checkboxes
          selected, then the relevant generated audiences, exports and imports
          in Lytics will be deleted. For older accounts that did not use this
          process, manual cleanup in Lytics will be required to delete these.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => setActiveConfirmed()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => setActiveCancelled()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={merchModal} toggle={(e) => toggleMerchModal(false)}>
        <ModalHeader toggle={(e) => toggleMerchModal(false)}>
          Please Confirm
        </ModalHeader>
        <ModalBody>
          Please confirm that you would like to change the "Enable setting
          'Allow Merch Recommendations' for Addressbooks" setting for this
          account. Please note switching this off would cause addressbooks in
          this account to have their "Allow Merch Recommendations" flag set to
          false.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => setMerchConfirmed()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => setMerchCancelled()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={countryModal} toggle={(e) => toggleCountryModal(false)}>
        <ModalHeader toggle={(e) => toggleCountryModal(false)}>
          Please Confirm Country Change
        </ModalHeader>
        <ModalBody>
          Please confirm that you would like to change the country from [
          {countries[model['territoryOperatingCountry']] &&
            countries[model['territoryOperatingCountry']].label}
          ] to [
          {countries[formik.values['territoryOperatingCountry']] &&
            countries[formik.values['territoryOperatingCountry']].label}
          ] for this account. Please note changing this will have repercussions
          as to the data acquisition method and this account's addressbooks.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => setCountryConfirmed()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => setCountryCancelled()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
