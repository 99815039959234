import * as React from 'react';
import { useContext } from 'react';
import { EnumerationsContext } from '../../enumerations/EnumerationsContext';
import { Optins } from '../../optins/Optins';
import { Loader } from '../../../components/Loader';

export const CommunityOptins = ({ account_id }) => {
  const { enumerations, loadingEnumerations } = useContext(EnumerationsContext);
  if (!enumerations.enumerations || loadingEnumerations) {
    <Loader loading={true} />;
  }
  return (
    <Optins enumerations={enumerations} community_account_id={account_id} />
  );
};
