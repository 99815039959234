import * as React from 'react';
import { useState } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import { D2cArtistMapping } from './D2cArtistMapping';
import * as matchSorter from 'match-sorter';
import classnames from 'classnames';
import { Can } from '../../auth/Can';

const pagesStyles = require('../pages.css');

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
export const fuzzyTextFilterFnExp = fuzzyTextFilterFn;

const D2C_ARTISTS_TAB = '1';

export const ArtistMappingContainer = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Partner Artist Mapping</h3>
        </Col>
      </Row>
      <Nav tabs>
        <Can I="manage" a="d2c_artist_mapping">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === D2C_ARTISTS_TAB })}
              onClick={() => {
                toggle(D2C_ARTISTS_TAB);
              }}
            >
              D2C Germany Artist Mapping
            </NavLink>
          </NavItem>
        </Can>
      </Nav>
      <TabContent activeTab={activeTab} className={pagesStyles.fullHeight}>
        <TabPane tabId={D2C_ARTISTS_TAB} className={pagesStyles.fullHeight}>
          <D2cArtistMapping />
        </TabPane>
      </TabContent>
    </div>
  );
};
