import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Table,
} from 'reactstrap';
import * as firebaseService from '../../services/firebase';
import { logger } from '../../logging';
import moment from 'moment';
const R = require('ramda');

const addressbookStyles = require('./addressbook.css');

export const AddressbookMappingModal = ({
  addressbook,
  mappingModalIsOpen,
  setMappingModalIsOpen,
  setMappingAddressbook,
  tableDataUpdated,
  mappingAddressbookIndex,
}) => {
  const damApp = firebaseService.getDAMApp();
  const ifbApp = firebaseService.getIFBApp();

  const [saveIsDisabled, setSaveIsDisabled] = useState(true);
  const [communityLeaderId, setCommunityLeaderId] = useState(
    addressbook.communityLeaderId || ''
  );
  const [communityLeaderName, setCommunityLeaderName] = useState(
    addressbook.communityLeaderName || ''
  );
  const [availableLeadersSorted, setAvailableLeadersSorted] = useState([]);
  const [availableLeaders, setAvailableLeaders] = useState({});

  const leaderOptions = R.map((leader) => {
    return (
      <option value={leader.id} key={leader.id}>
        {leader.name}
      </option>
    );
  }, availableLeadersSorted);

  useEffect(() => {
    setCommunityLeaderId(addressbook.communityLeaderId || '');
    setCommunityLeaderName(addressbook.communityLeaderName || '');
    getAvailableLeaders();
  }, [addressbook]);

  const getAvailableLeaders = async () => {
    // logger.debug('getAvailableLeaders called');
    if (addressbook.partyIdValid !== true) {
      return;
    }

    try {
      const leadersRef = damApp
        .database()
        .ref('community/leaders')
        .orderByChild('party_id')
        .equalTo(addressbook.party_id);
      const leadersSnap = await leadersRef.once('value');
      const leaders = {};
      leadersSnap.forEach((v) => {
        const leader = v.val();
        if (addressbook.party_id === leader.party_id) {
          leaders[leader.id] = {
            id: leader.id,
            name: leader.name,
            party_id: leader.party_id,
            party_name: leader.party_name,
          };
        }
      });

      const sortedLeaders = R.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }, R.values(leaders));

      setAvailableLeadersSorted(sortedLeaders);
      setAvailableLeaders(leaders);
      logger.debug(`setAvailableLeaders: ${JSON.stringify(leaders)}`);
    } catch (e) {
      logger.error(`getAvailableLeaders: ${e}`);
      setAvailableLeadersSorted([]);
      setAvailableLeaders({});
    }
  };

  const saveAddressbookMapping = async () => {
    logger.debug(
      `saveAddressbookMapping called for addressbook: ${addressbook.key}`
    );
    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const addressbooksRef = ifbApp
      .database()
      .ref(`addressbooks/${addressbook.key}`);
    const addressbookToUpdate = {
      ...addressbook,
      communityLeaderId: communityLeaderId,
      communityLeaderName: communityLeaderName,
      communityLeaderMappingValid: true,
      haspartyid_senttobq_accountid_addressbookid: `${addressbook.partyIdValid}_false_${addressbook.key}`,
      modified: now,
    };

    try {
      await addressbooksRef.update(addressbookToUpdate);
      // logger.debug(`updated addressbook with: ${JSON.stringify(addressbookToUpdate)}`);
      tableDataUpdated(
        mappingAddressbookIndex,
        'communityLeaderId',
        communityLeaderId
      );
      tableDataUpdated(
        mappingAddressbookIndex,
        'communityLeaderName',
        communityLeaderName
      );
      tableDataUpdated(
        mappingAddressbookIndex,
        'communityLeaderMappingValid',
        addressbookToUpdate.communityLeaderMappingValid
      );
      setMappingAddressbook({});
      setMappingModalIsOpen(false);
    } catch (e) {
      logger.error('[updateMyData] - error updating addressbook: ', e);
      setMappingAddressbook({});
      setMappingModalIsOpen(false);
    }
  };

  const leaderIdChanged = (e) => {
    setSaveIsDisabled(true);
    setCommunityLeaderId(e.target.value);
    const leaderName =
      (availableLeaders[e.target.value] &&
        availableLeaders[e.target.value].name) ||
      '';
    setCommunityLeaderName(leaderName);
    if (e.target.value !== '') {
      setSaveIsDisabled(false);
    }
  };

  const handleClose = () => {
    setMappingAddressbook({});
    setMappingModalIsOpen(false);
    setSaveIsDisabled(true);
    setAvailableLeadersSorted([]);
    setAvailableLeaders({});
    setCommunityLeaderId('');
    setCommunityLeaderName('');
  };

  return (
    <Modal
      isOpen={mappingModalIsOpen}
      toggle={handleClose}
      className={addressbookStyles.mappingModal}
    >
      <ModalHeader
        toggle={handleClose}
        className={addressbookStyles.mappingModalHeader}
      >
        Community Leader Mapping for Addressbook: '{addressbook.name}' (
        {addressbook.accountName})
      </ModalHeader>
      <ModalBody className={addressbookStyles.mappingModalBody}>
        {availableLeadersSorted.length === 0 ? (
          <Row className={addressbookStyles.mappingModalBodyRow}>
            <Col>
              <h5>
                There are no Community Leaders which match the criteria to map
                to this addressbook
              </h5>
            </Col>
          </Row>
        ) : null}
        <Row className={addressbookStyles.mappingModalBodyRow}>
          <Col>
            <h6>Community Leaders must match the following criteria:</h6>
            <ul>
              <li>
                Community Leader must match Addressbook party id (
                {addressbook.party_id})
              </li>
            </ul>
          </Col>
        </Row>
        {availableLeadersSorted.length > 0 ? (
          <React.Fragment>
            <Row className={addressbookStyles.mappingModalBodyRow}>
              <Col>1) Select the correct Community Leader</Col>
            </Row>
            <Row className={addressbookStyles.mappingModalBodyRow}>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Leader Name</th>
                      <th>Leader ID</th>
                      <th>Leader Canopus ID</th>
                      <th>Leader Canopus Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          type="select"
                          value={communityLeaderId || ''}
                          onChange={leaderIdChanged}
                        >
                          <option value="" key="">
                            Please select
                          </option>
                          {leaderOptions}
                        </Input>
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={communityLeaderId || ''}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableLeaders[communityLeaderId] &&
                              availableLeaders[communityLeaderId].party_id) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableLeaders[communityLeaderId] &&
                              availableLeaders[communityLeaderId].party_name) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className={addressbookStyles.mappingModalFooterRow}>
              <Col>
                2) Save to commit the mapping and schedule for push to Big Query
              </Col>
            </Row>
            <Row className={addressbookStyles.mappingModalFooterRow}>
              <Col>
                <Button
                  color="primary"
                  onClick={(e) => saveAddressbookMapping()}
                  disabled={saveIsDisabled}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        ) : null}
      </ModalBody>
    </Modal>
  );
};
