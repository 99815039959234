export enum WorkflowTypes {
  MIGRATE_CONTACTS = 'migrate_contacts',
}

export enum WorkflowSubtype {
  EXILE = 'exile',
  DATA_TRANSFER = 'data_transfer',
  DATA_MIGRATION = 'data_migration',
}

export const workflowTypesMap = {
  [WorkflowTypes.MIGRATE_CONTACTS]: 'Migrate Contacts',
};

export const WorkflowSubtypeMap = {
  [WorkflowSubtype.EXILE]: 'Exile',
  [WorkflowSubtype.DATA_TRANSFER]: 'Data Transfer',
  [WorkflowSubtype.DATA_MIGRATION]: 'Data Migration',
};

export enum State {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  PAUSED = 'paused',
  STATE_UNSPECIFIED = 'state_unspecified',
  UPDATE_FAILED = 'update_failed',
}

export enum ExecutionResult {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum WorkflowState {
  RESUME = 'resume',
  PAUSE = 'pause',
  RUN_NOW = 'run_now',
}

export type WorkflowLifecycle = {
  type: WorkflowLifecycleType;
  stage: WorkflowLifecycleStage;
  counter: number;
};

export enum WorkflowLifecycleType {
  ONE_STAGE_7D = 'one_stage_7d',
  THREE_STAGE_6M = 'three_stage_6m',
}

export enum WorkflowLifecycleStage {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
}

export interface Workflow {
  name: string;
  type: WorkflowTypes;
  subtype: WorkflowSubtype;
  lifecycle: WorkflowLifecycle;
  description?: string;
  schedule: string;
  continuous: boolean;
  args?: any;
  created: number;
  modified?: number;
  last_execution?: number;
  next_execution?: number;
  last_execution_result?: ExecutionResult;
  status: State;
  createdBy: any;
}
