import { getDAMApp } from '../../services/firebase';

export class Labels {
  labels = {};

  constructor() {
    this.fetch = this.fetch.bind(this);
  }

  async fetch() {
    const damApp = getDAMApp();
    const ref = damApp.database().ref('labels');
    const res = await ref.once('value');
    this.labels = res.val();
  }

  getName(value: string): string {
    const labelsObj = this.labels;
    if (!labelsObj) {
      return value;
    }

    const valueObj = labelsObj[value];
    if (!valueObj) {
      return value;
    }

    return valueObj.label;
  }
}

export const labels = new Labels();
