import * as React from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { logger } from '../../logging';
import * as firebaseService from '../../services/firebase';
import { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader';
import { DDAccount } from '../accounts/dotdigital/Accounts';
import { BrandInput } from '../../components/brand/BrandInput';
import { BrandService } from '../../components/brand/BrandLookup';
import { booleanOptions } from './Addressbooks';

const R = require('ramda');
const addressbookStyles = require('./addressbook.css');

export enum VALIDATION_RULES {
  ONE_PER_ACCOUNT = 'one_per_account',
  ONE_PER_COUNTRY_BRAND_CHANNEL = 'one_per_country_brand_channel',
  SPECIFIC_TO_ACCOUNT = 'specific_to_account',
}

interface AddressbooksBulkUpdateProps {
  addressbookKeys: string[];
  ddAccount: DDAccount;
  enumerations: any;
  emailOptinCategories: any;
  setBulkUpdateLoading: (val) => void;
}

interface AddressbookPropertiesToUpdate {
  category: string;
  party_id: number;
  party_name: string;
  canopusId: string;
  processed: boolean;
  dataCapture: boolean;
  excludeFromReporting: boolean;
  nonConsented: boolean;
}

const defaultAddressbookProperties: AddressbookPropertiesToUpdate = {
  category: null,
  party_id: null,
  party_name: null,
  canopusId: null,
  processed: true,
  dataCapture: null,
  excludeFromReporting: null,
  nonConsented: null,
};

const bulkUpdateAddressbooks = async (
  addressbookKeys: string[],
  propertiesToUpdate: AddressbookPropertiesToUpdate,
  shouldSendToLytics: boolean
) => {
  if (addressbookKeys.length === 0) {
    return;
  }

  if (!propertiesToUpdate) {
    return;
  }

  const damApp = firebaseService.getDAMApp();
  const payload = {
    addressbookKeys,
    propertiesToUpdate,
    shouldSendToLytics,
  };

  logger.debug(
    `[bulkUpdateAddressbooks] calling with callable with payload: ${JSON.stringify(
      payload
    )}`
  );

  try {
    const callable = damApp
      .functions()
      .httpsCallable('accountManager-callableBulkUpdateAddressbooks');

    await callable(payload);
  } catch (e) {
    logger.error(`[bulkUpdateAddressbooks] e: ${e}`);
  }
};

const getDefaultsFromFirstAddressbook = async (addressbookKey: string) => {
  const defaults = defaultAddressbookProperties;
  try {
    const ifbApp = firebaseService.getIFBApp();
    const addressbookRef = ifbApp
      .database()
      .ref(`addressbooks/${addressbookKey}`);
    const snap = await addressbookRef.once('value');
    if (snap.exists()) {
      const addressbook = snap.val();
      defaults['category'] = addressbook.category;
      defaults['party_id'] = addressbook.party_id;
      defaults['party_name'] = addressbook.party_name;
      defaults['canopusId'] = addressbook.canopusId;
      defaults['dataCapture'] = addressbook.dataCapture;
      defaults['excludeFromReporting'] = addressbook.excludeFromReporting;
      defaults['nonConsented'] = addressbook.nonConsented;
    }
  } catch (e) {
    logger.error(`[getDefaultsFromFirstAddressbook] error: ${e}`);
  }

  return defaults;
};

interface CategoryRuleResult {
  category_rule: string | null;
  category_rule_specific_account_type: string | null;
}

const getRuleFromCategory = (emailOptinCategories: any, category: string) => {
  const categoryRuleResult: CategoryRuleResult = {
    category_rule: null,
    category_rule_specific_account_type: null,
  };

  try {
    if (emailOptinCategories[category]) {
      const emailOptinCategory = emailOptinCategories[category];
      if (emailOptinCategory && emailOptinCategory.category_rule) {
        categoryRuleResult['category_rule'] = emailOptinCategory.category_rule;
        if (
          emailOptinCategory.category_rule ===
          VALIDATION_RULES.SPECIFIC_TO_ACCOUNT
        ) {
          categoryRuleResult['category_rule_specific_account_type'] =
            emailOptinCategory.category_rule_specific_account_type;
        }
      }
    }
  } catch (e) {
    logger.error(
      `[validateAddressbookCategory] getRuleFromCategory returned an error: ${e}`
    );
  }

  return categoryRuleResult;
};

export const AddressbooksBulkUpdate = ({
  addressbookKeys = [],
  ddAccount,
  enumerations,
  emailOptinCategories,
  setBulkUpdateLoading,
}: AddressbooksBulkUpdateProps) => {
  // logger.debug(`addressbookKeys: ${addressbookKeys}`);
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState(defaultAddressbookProperties);
  const [modalOpen, setModalOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [shouldSendToLytics, setShouldSendToLytics] = useState(false);

  // populate initial selection based on first record
  useEffect(() => {
    if (addressbookKeys && addressbookKeys.length > 0) {
      // logger.debug(`useEffect: addressbooksKeys[0]: ${addressbookKeys[0]}`);
      setLoading(true);
      const getDefaultsCall = getDefaultsFromFirstAddressbook(
        addressbookKeys[0]
      );

      getDefaultsCall
        .then((newDefaults) => {
          setProperties(newDefaults);
          setLoading(false);
        })
        .catch((e) => {
          logger.error(`useEffect - unable to get first record: ${e}`);
          setLoading(false);
        });
    }
  }, [addressbookKeys]);

  const getDataAcquisitionSelectionFromAccount = async (id) => {
    const damApp = firebaseService.getDAMApp();
    const accountRef = damApp.database().ref(`accounts/dotdigital/${id}`);
    const snap = await accountRef.once('value');
    if (snap.exists()) {
      const account = snap.val();
      return account.data_acquisition_selection || '';
    }

    return '';
  };

  useEffect(() => {
    if (ddAccount && ddAccount.id) {
      setLoading(true);
      const getDataAcquisitionSelectionFromAccountCall =
        getDataAcquisitionSelectionFromAccount(ddAccount.id);

      getDataAcquisitionSelectionFromAccountCall
        .then((selection) => {
          const isLytics = selection === 'lytics';
          setShouldSendToLytics(isLytics);
          setLoading(false);
        })
        .catch((e) => {
          logger.error(
            `useEffect - unable to get account data acquisition selection: ${e}`
          );
          setLoading(false);
        });
    }
  }, [ddAccount]);

  if (loading || !enumerations || !emailOptinCategories) {
    return <Loader loading={true} />;
  }

  if (!addressbookKeys || addressbookKeys.length === 0) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Alert color="info">
              Please select some addressbooks and confirm your selection by
              pressing the "Confirm Selection" button
            </Alert>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  const validateProperties = (properties) => {
    logger.debug(
      `[validateProperties]: properties: ${JSON.stringify(properties)}`
    );

    const nonNullProperties = R.reject(R.isNil)(properties);
    const nonEmptyProperties = R.reject(R.isEmpty)(nonNullProperties);

    logger.debug(
      `[validateProperties]: nonEmptyProperties: ${JSON.stringify(
        nonEmptyProperties
      )}`
    );
    if (R.isEmpty(nonEmptyProperties)) {
      logger.debug('NOTHING SET');
      setError('No properties set to update');
      return;
    }

    setError(null);
  };

  const updateDefaults = (event, fieldName) => {
    logger.debug(`[updateDefaults] event: ${event}, fieldName: ${fieldName}`);
    let val;
    if (fieldName === 'category') {
      val = event.target.value;
    } else {
      logger.debug(
        `[updateDefaults] event.target.value: ${event.target.value}`
      );
      val =
        event.target.value === 'true'
          ? 'true'
          : event.target.value === 'false'
          ? 'false'
          : '';
    }

    const newProperties = { ...properties, [fieldName]: val };
    setProperties(newProperties);
    validateProperties(newProperties);
  };

  const updatePartyName = (party_id, party_name, canopus_id) => {
    const newProperties = {
      ...properties,
      canopusId: canopus_id,
      party_id,
      party_name,
    };
    setProperties(newProperties);
    validateProperties(newProperties);
  };

  const openBulkUpdateModal = () => {
    setModalOpen(true);
  };

  const handleBulkUpdateConfirmation = async () => {
    setUpdating(true);
    setBulkUpdateLoading(true);
    try {
      await bulkUpdateAddressbooks(
        addressbookKeys,
        properties,
        shouldSendToLytics
      );
    } catch (e) {
      logger.error(`[handleBulkUpdateConfirmation] failed to update: ${e}`);
    }

    setUpdating(false);
    setBulkUpdateLoading(false);
    setModalOpen(false);
  };

  // this needs updating with new rules around what to make available based on account type
  const addressbookCategories =
    Object.values(enumerations.enumerations.addressbookCategories || {}) || [];

  const categoryOptions = R.map((category) => {
    const categoryRulesResult = getRuleFromCategory(
      emailOptinCategories,
      category.value
    );
    const categoryRule = categoryRulesResult.category_rule;
    logger.debug(`[AddressbooksBulkUpdate] categoryRule: ${categoryRule}`);
    const categorySpecificAccount =
      categoryRulesResult.category_rule_specific_account_type;
    if (
      (categoryRule !== VALIDATION_RULES.ONE_PER_ACCOUNT &&
        categoryRule !== VALIDATION_RULES.ONE_PER_COUNTRY_BRAND_CHANNEL &&
        categoryRule !== VALIDATION_RULES.SPECIFIC_TO_ACCOUNT) ||
      (categoryRule === VALIDATION_RULES.SPECIFIC_TO_ACCOUNT &&
        ddAccount.ddAccountType === categorySpecificAccount)
    ) {
      return (
        <option value={category.value} key={category.value}>
          {category.label}
        </option>
      );
    }
  }, addressbookCategories);

  // todo: plug in with validation
  const bulkUpdateIsDisabled = loading || error ? true : false;

  const nonNullPropertiesToUpdate = R.reject(R.isNil)(properties) || {};
  const validPropertiesToUpdate =
    R.reject(R.isEmpty)(nonNullPropertiesToUpdate) || {};
  const propertiesToUpdateRows = Object.keys(validPropertiesToUpdate).map(
    (val, idx, arr) => {
      return (
        <Row key={idx}>
          <Col sm={6}>{val}</Col>
          <Col sm={1}>=</Col>
          <Col sm={5}>{`${validPropertiesToUpdate[val]}`}</Col>
        </Row>
      );
    }
  );

  return (
    <React.Fragment>
      <Alert color="info">
        First record in selection used to populate defaults. Please note other
        selected records may have different values.
      </Alert>
      {error && <Alert color="danger">{error}</Alert>}
      <Form>
        <Row className={addressbookStyles.bulkInputRow}>
          <Col className={addressbookStyles.brandInputCol}>
            <Label>Optin Category</Label>
            <Input
              type="select"
              value={`${properties['category'] || ''}`}
              onChange={(e) => updateDefaults(e, 'category')}
            >
              <option key="" value="">
                Do Not Set
              </option>
              {categoryOptions}
            </Input>
          </Col>
          <Col className={addressbookStyles.brandInput} sm={3}>
            <BrandInput
              name="party_id"
              value={`${properties['party_id'] || ''}`}
              label="Party ID"
              brandFieldName="party_name"
              brandFieldNameLabel="Party Name"
              disabled={false}
              handleOnChange={(party_id, party_name, canopus_id) =>
                updatePartyName(party_id, party_name, canopus_id)
              }
              brandService={BrandService.PARTY_SERVICE}
              hideCanopus={true}
            />
          </Col>
          <Col className={addressbookStyles.brandInputCol}>
            <Label>Processed</Label>
            <Input
              type="select"
              value={
                properties['processed'] !== undefined
                  ? `${properties['processed']}`
                  : ''
              }
              onChange={(e) => updateDefaults(e, 'processed')}
            >
              <option key="" value="">
                Do Not Set
              </option>
              {booleanOptions}
            </Input>
          </Col>
          <Col className={addressbookStyles.brandInputCol}>
            <Label>Data Capture</Label>
            <Input
              type="select"
              value={
                properties['dataCapture'] !== undefined
                  ? `${properties['dataCapture']}`
                  : ''
              }
              onChange={(e) => updateDefaults(e, 'dataCapture')}
            >
              <option key="" value="">
                Do Not Set
              </option>
              {booleanOptions}
            </Input>
          </Col>
          <Col className={addressbookStyles.brandInputCol}>
            <Label>Exclude From Reporting</Label>
            <Input
              type="select"
              value={
                properties['excludeFromReporting'] !== undefined
                  ? `${properties['excludeFromReporting']}`
                  : ''
              }
              onChange={(e) => updateDefaults(e, 'excludeFromReporting')}
            >
              <option key="" value="">
                Do Not Set
              </option>
              {booleanOptions}
            </Input>
          </Col>
          <Col className={addressbookStyles.brandInputCol}>
            <Label>Non Consented</Label>
            <Input
              type="select"
              value={
                properties['nonConsented'] !== undefined
                  ? `${properties['nonConsented']}`
                  : ''
              }
              onChange={(e) => updateDefaults(e, 'nonConsented')}
            >
              <option key="" value="">
                Do Not Set
              </option>
              {booleanOptions}
            </Input>
          </Col>
          <Col className={addressbookStyles.brandInputCol}>
            <Button
              onClick={openBulkUpdateModal}
              disabled={bulkUpdateIsDisabled}
              className={addressbookStyles.bulkUpdateButton}
            >
              Bulk Update {addressbookKeys.length > 1 ? 'All ' : ''}
              {addressbookKeys.length || 0} Selected Record
              {addressbookKeys.length === 1 ? '' : 's'}
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal isOpen={modalOpen} className={addressbookStyles.modalPadding}>
        <ModalHeader>Confirm Bulk Update</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              Are you sure you want to update the following Addressbook keys:
            </Col>
          </Row>
          <Row>
            <Col>{addressbookKeys.join(', ')}</Col>
          </Row>
          <Row className={addressbookStyles.rowMarginTop}>
            <Col>With the following values:</Col>
          </Row>
          {propertiesToUpdateRows}
          {updating && <Loader loading={true} />}
        </ModalBody>
        <ModalFooter>
          <Col className={addressbookStyles.center}>
            <Button
              color="primary"
              onClick={handleBulkUpdateConfirmation}
              disabled={updating}
            >
              Yes
            </Button>
          </Col>
          <Col className={addressbookStyles.center}>
            <Button
              color="secondary"
              onClick={() => setModalOpen(false)}
              disabled={updating}
            >
              Cancel
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
