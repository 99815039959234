import * as React from 'react';
import { useParams } from 'react-router';
import { useObject } from 'react-firebase-hooks/database';
import { AccountForm } from './AccountForm';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import { Loader } from '../../components/Loader';
import { useState } from 'react';
import { logger, serverLogger } from '../../logging';
import * as firebaseService from '../../services/firebase';
import * as R from 'ramda';
import firebase from 'firebase/compat/app';

const pagesStyles = require('../pages.css');

export const Account = () => {
  const damApp = firebaseService.getDAMApp();
  const { id }: any = useParams();
  const { system }: any = useParams();
  const accountRef = damApp.database().ref(`accounts/${system}/${id}`);
  const [snap, loading, error] = useObject(accountRef as any);
  const [user, userLoading] = useAuthState(damApp.auth() as any);
  const [saving, setSaving] = useState(false);

  if (error) {
    logger.error(error);
    return <div className={pagesStyles.container}>{error}</div>;
  }

  if (loading) {
    return <Loader loading={true} />;
  }

  if (saving) {
    return <Loader loading={true} />;
  }

  let account;
  let accountOrig;
  if (snap) {
    // logger.debug(`[Account] ...snap.val(): ${JSON.stringify({ ...snap.val() })}`);
    account = { ...snap.val(), key: snap.key, id: snap.key };
    accountOrig = { ...snap.val(), key: snap.key, id: snap.key };
    // delete any properties that are shown and saved in other tabs, otherwise this will save over w/ previous value
    delete account.lyticsData;
    delete account.data_acquisition_selection;
    delete account.data_acquisition_processing_method;
    delete account.whatsapp_enabled;
    delete account.sms_enabled;
    delete account.send_subscription_insights;
    delete account.send_genre_insights;
    // logger.debug(`[Account] account: ${JSON.stringify(account)}`);
  }

  const save = async (values, errors) => {
    logger.debug(
      `[Account][save] called with values: ${JSON.stringify(values)}`
    );
    const isDotdigital = system === 'dotdigital';
    const isSlicktext = system === 'slicktext';
    const isAttentive = system === 'attentive';
    const isTreasureData = system === 'treasure_data';
    const isCommunity = system === 'community';
    const errorItems = Object.keys(errors);
    if (errorItems.length === 0 && !userLoading && user) {
      setSaving(true);

      // always trim these
      values.ddAccountId && (values.ddAccountId = values.ddAccountId.trim());
      values.ddAccountName &&
        (values.ddAccountName = values.ddAccountName.trim());
      values.id && (values.id = values.id.trim());
      values.name && (values.name = values.name.trim());

      values.party_id && (values.party_id = parseInt(values.party_id, 10));

      // handle account deactivation/reactivation
      let callable;
      // we have just activated the account
      if (values.active === true && account.active === false) {
        callable = damApp
          .functions()
          .httpsCallable('accountManager-reactivateAccount');
      } else if (values.active === false && account.active === true) {
        callable = damApp
          .functions()
          .httpsCallable('accountManager-deactivateAccount');
      }

      const accountId = account.id;

      if (callable) {
        await callable({ system, accountId });
      }

      const accountToSave = {
        ...values,
        lastModifiedBy: { uid: user.uid, email: user.email },
      };
      if (isDotdigital) {
        accountToSave.lastModifiedAt = `${moment().format()}`;
        if (account.ddAccountType !== values.ddAccountType) {
          // accountType changed, so reset custom fields flags etc
          accountToSave['customFieldsCreationError'] = null;
          accountToSave['customFieldsFetchError'] = null;
          accountToSave['customFieldsLastSynced'] = null;
        }

        // set Insights flags on isB2COrB2B change
        if (account.isB2COrB2B !== values.isB2COrB2B) {
          if (
            values.isB2COrB2B === 'b2c' &&
            accountOrig.data_acquisition_processing_method === 'asynchronous'
          ) {
            accountToSave['send_subscription_insights'] = true;
            accountToSave['send_genre_insights'] = true;
          } else {
            accountToSave['send_subscription_insights'] = false;
            accountToSave['send_genre_insights'] = false;
          }
        }

        // if the dotdigital account has been set to 'marketing-siloed' then we need to clear any textwords mappings to addressbooks for this account
        if (accountToSave.ddAccountType === 'marketing_siloed') {
          logger.info(
            'Account is marketing - siloed - clear addressbook mappings for textwords'
          );
          const mappedTextwordsRef = damApp
            .database()
            .ref('textwords')
            .orderByChild('mappingDDAccountId')
            .equalTo(parseInt(accountToSave.ddAccountId, 10));
          const mappedTextwordsSnap = await mappedTextwordsRef.once('value');
          const textwordRefs: firebase.database.Reference[] = [];

          mappedTextwordsSnap.forEach((s) => {
            textwordRefs.push(s.ref);
          });

          for (const ref of textwordRefs) {
            await ref.update({
              addressbookMappingValid: false,
              mappingDDAccountId: null,
              mappingDDAddressbookId: null,
              modified: moment().unix(),
            });
            logger.info('Cleared textword mapping');
          }
        }

        // update the account name field on all addressbooks if it has changed or country if changed or allowMerchRecommendationsForAddressbooks
        if (
          account.ddAccountName !== values.ddAccountName ||
          account.territoryOperatingCountry !==
            values.territoryOperatingCountry ||
          account.allowMerchRecommendationsForAddressbooks !==
            values.allowMerchRecommendationsForAddressbooks
        ) {
          const ifbApp = firebaseService.getIFBApp();
          const addressbooksRef = ifbApp
            .database()
            .ref('addressbooks')
            .orderByChild('accountId')
            .equalTo(parseInt(account.ddAccountId, 10));
          const addressbooksSnap = await addressbooksRef.once('value');
          const addressbooks = {};

          addressbooksSnap.forEach((s) => {
            const addressbook = s.val();
            const key = s.key;
            let isModified = false;

            if (account.ddAccountName !== values.ddAccountName) {
              addressbooks[`${key}/accountName`] = values.ddAccountName;
              isModified = true;
            }

            // Country should no longer be changed for accounts that have a country
            // if (
            //   account.territoryOperatingCountry !==
            //   values.territoryOperatingCountry
            // ) {
            //   addressbooks[`${key}/country`] = R.toUpper(
            //     values.territoryOperatingCountry
            //   ); // addressbooks is upper case country
            //   isModified = true;
            // }

            // if new value is false ensure allowMerchRecommendations on addressbook is set to false
            if (
              account.allowMerchRecommendationsForAddressbooks !==
              values.allowMerchRecommendationsForAddressbooks
            ) {
              if (values.allowMerchRecommendationsForAddressbooks === false) {
                // logger.debug(`values.allowMerchRecommendationsForAddressbooks changed and is false. setting all addressbook to false`);
                addressbooks[
                  `${key}/allowMerchRecommendationsEnabledForAccount`
                ] = false;
                if (addressbook.allowMerchRecommendations === true) {
                  // logger.debug(`setting allowMerchRecommendations on addressbook ${key} to false`);
                  addressbooks[`${key}/allowMerchRecommendations`] = false;
                }

                isModified = true;
              }

              if (values.allowMerchRecommendationsForAddressbooks === true) {
                addressbooks[
                  `${key}/allowMerchRecommendationsEnabledForAccount`
                ] = true;
                isModified = true;
              }
            }

            if (isModified) {
              const nowstr = moment().format();
              addressbooks[
                `${key}/haspartyid_senttobq_accountid_addressbookid`
              ] = `${addressbook.partyIdValid}_false_${key}`;
              addressbooks[`${key}/modified`] = nowstr;
            }
          });

          await ifbApp.database().ref('addressbooks').update(addressbooks);
          logger.info('[Account] updated addressbooks');
        }
      }

      if (isSlicktext) {
        accountToSave.modified = `${moment().unix()}`;

        // check ref to see if stAccountId has been stored in interim // consider changing this, as could just delete account.stAccountId
        try {
          logger.debug(
            `[Account] [save] [slicktext] getting slicktext account to check for stAccountId. id: ${id}`
          );
          const stAccountSnap = await accountRef.once('value');
          logger.debug(
            `[Account] [save] [slicktext] got attentive account to check for stAccountId. stAccountSnap.val(): ${stAccountSnap.val()}`
          );
          let retrieved_stAccountId = '';
          logger.debug(
            `[Account] [save] [slicktext] stAccountSnap.val().stAccountId: ${
              stAccountSnap.val().stAccountId
            }`
          );

          if (
            stAccountSnap.val().stAccountId &&
            stAccountSnap.val().stAccountId !== ''
          ) {
            logger.debug(
              `[Account] [save] [slicktext] setting retrieved_stAccountId: ${
                stAccountSnap.val()['stAccountId']
              }`
            );
            retrieved_stAccountId = stAccountSnap.val()['stAccountId'];
          }

          accountToSave.stAccountId = retrieved_stAccountId;
        } catch (e) {
          logger.error(e);
          serverLogger(
            `[Account] User ${user.email} had an error attempting to get account prior to saving: ${accountId} (${system})`,
            'error'
          );
          setSaving(false);
        }

        // update the account name field on all addressbooks if it has changed or country if changed
        if (account.country !== values.country) {
          const textwordsRef = damApp
            .database()
            .ref('textwords')
            .orderByChild('accountId')
            .equalTo(account.id);
          const textwordsSnap = await textwordsRef.once('value');
          const textwords = {};

          textwordsSnap.forEach((s) => {
            const key = s.key;

            if (account.country !== values.country) {
              textwords[`${key}/country`] = values.country; // textwords is lowercase country
            }
          });

          await damApp.database().ref('textwords').update(textwords);
          logger.info('updated textwords');
        }
      }

      if (isAttentive) {
        accountToSave.modified_at = `${moment().unix()}`;
        accountToSave.modified_by = `${user.email}`;

        // check ref to see if account_id has been stored in interim // consider changing this, as could just delete account.account_id
        try {
          logger.debug(
            `[Account] [save] [attentive] getting attentive account to check for account_id. id: ${id}`
          );
          const attentiveAccountSnap = await accountRef.once('value');
          logger.debug(
            `[Account] [save] [attentive] got attentive account to check for account_id. attentiveAccountSnap.val(): ${attentiveAccountSnap.val()}`
          );
          let retrieved_account_id = '';
          logger.debug(
            `[Account] [save] [attentive] attentiveAccountSnap.val().account_id: ${
              attentiveAccountSnap.val().account_id
            }`
          );

          if (
            attentiveAccountSnap.val().account_id &&
            attentiveAccountSnap.val().account_id !== ''
          ) {
            logger.debug(
              `[Account] [save] [attentive] setting retrieved_account_id: ${
                attentiveAccountSnap.val()['account_id']
              }`
            );
            retrieved_account_id = attentiveAccountSnap.val()['account_id'];
          }

          accountToSave.account_id = retrieved_account_id;
        } catch (e) {
          logger.error(e);
          serverLogger(
            `[Account] User ${user.email} had an error attempting to get account prior to saving: ${accountId} (${system})`,
            'error'
          );
          setSaving(false);
        }
      }

      if (isTreasureData || isCommunity) {
        accountToSave.modified_at = `${moment().unix()}`;
        accountToSave.modified_by = `${user.email}`;
      }

      try {
        serverLogger(
          `[Account] User ${user.email} updated account: ${accountId} (${system})`,
          'info'
        );
        await accountRef.update(accountToSave);
        setSaving(false);
      } catch (e) {
        logger.error(e);
        serverLogger(
          `[Account] User ${user.email} had an error attempting to update account: ${accountId} (${system})`,
          'error'
        );
        setSaving(false);
      }
    } else {
      logger.error('errors', errorItems);
      setSaving(false);
    }
  };

  return (
    <AccountForm
      model={account}
      save={save}
      account={account}
      system={system}
    />
  );
};
