import * as React from 'react';
import { useContext, useState } from 'react';
import { Col, Row, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Loader } from '../../components/Loader';
import { Addressbooks } from './Addressbooks';
import { EnumerationsContext } from '../enumerations/EnumerationsContext';
import { TextwordsTab } from '../account/slicktext/TextwordsTab';

const pagesStyles = require('../pages.css');

export const AddressbooksContainer = () => {
  const { enumerations, loadingEnumerations } = useContext(EnumerationsContext);
  const [activeTab, setActiveTab] = useState(0);

  if (!enumerations.enumerations || loadingEnumerations) {
    return <Loader loading={true} />;
  }

  return (
    <div className={pagesStyles.fullHeight}>
      <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
        <Row className={pagesStyles.headerRow}>
          <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
            <h3>Marketing Lists</h3>
          </Col>
        </Row>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 0 })}
              onClick={() => setActiveTab(0)}
            >
              Addressbooks
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 1 })}
              onClick={() => setActiveTab(1)}
            >
              Textwords
            </NavLink>
          </NavItem>
        </Nav>
        <div hidden={activeTab !== 0} className={pagesStyles.fullHeight}>
          <Addressbooks
            account={null}
            allAccounts={true}
            enumerations={enumerations}
          />
        </div>
        <div hidden={activeTab !== 1} className={pagesStyles.fullHeight}>
          <TextwordsTab account={undefined} />
        </div>
      </div>
    </div>
  );
};
