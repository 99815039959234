.formRow {
  margin-top: 20px;
}

.button {
  margin-right: 10px;
}

.columnsButton {
  margin: 15px 0;
}

.fullHeight {
  height: 100%;
}

.fullHeightTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fitContentTableContainer {
  width: fit-content;
  flex: 1;
  overflow-y: auto;
}

.addressbooksTableContainer {
  width: fit-content;
}

.addressbooksTable {
  border-collapse: separate;
  border-spacing: 0;
}

.addressbooksTable th {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  z-index: 1;
}

.addressbooksTable td {
  border-right: 1px solid #dee2e6;
}

.addressbooksTable th:first-child,
.addressbooksTable td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid #dee2e6;
}

.addressbooksTable tr:last-child td {
  /* Apply a left border on the first <td> or <th> in a row */
  border-bottom: 1px solid #dee2e6;
}

.addressbooksTable select,
.addressbooksTable input {
  width: -webkit-fill-available;
  margin-top: 5px;
}

.addressbooksTableHeader {
  vertical-align: top !important;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.addressbooksVerify {
  margin-left: 10px;
}

.canopusLoader {
  height: 40px;
  width: 40px;
  padding: 0px;
  margin-left: -23px;
}

.mappingModalBody,
.mappingModalFooter,
.mappingModalHeader {
  padding: 50px;
}

.mappingInput {
  cursor: pointer;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mappingModalBodyRow {
  padding: 20px;
}

.mappingModalFooterRow {
  padding: 20px;
  text-align: left;
}

.mappingModal {
  max-width: 80%;
}

.pagination button {
  margin: 5px;
}

.bulkSelectButton {
  margin-bottom: 10px;
}
