.brandValidCol svg {
  margin-top: 40px;
  margin-left: -10px;
}

.brandValidCol img {
  margin-top: 30px;
}

.brandValidCol {
  display: contents;
  padding-left: 5px;
}

.brandLoader {
  height: 40px;
  width: 40px;
  padding: 0px;
  margin-left: -23px;
}

.canopusRow {
  margin-top: 10px;
}
