import * as React from 'react';
import { Table, Row, Input, Form, Col, Alert } from 'reactstrap';
import { ref } from '../../utils/firebase';
import { useState } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { arrayToOptions } from '../../utils/react';
import { Loader } from '../../components/Loader';
import { getDAMApp } from '../../services/firebase';
import { CredentialsRow } from './CredentialsRow';
import { CreateCredentialsRow } from './CreateCredentialsRow';

const R = require('ramda');
const iconStyles = require('../../components/icons.css');
const accountStyles = require('./account.css');

interface Credential {
  name: string;
  active: boolean;
  secretPath: string;
}

export const Credentials = ({
  system,
  account,
  reload,
  setReload,
  credentials,
}) => {
  const damApp = getDAMApp();
  // I'm not loving the reload mechanic here but it does work
  const [error, setError] = useState('');
  const [credentialType, setCredentialType] = useState('');
  const credentialsRef = damApp.database().ref('enumerations/credentialTypes');
  // if we use list or listvals this causes a rerender for each item which causes a 'setstate on unmounted component' error...
  const [credentialTypes] = useObjectVal(credentialsRef as any);

  const [currentCredentials, loading] = useObjectVal(
    ref(`credentials/${account.key}`) as any
  );
  if (loading) {
    return <Loader loading={true}></Loader>;
  }

  const creds = currentCredentials || {};

  const currentCredentialsValues = Object.values(creds);
  const currentCredsKeys = [];
  for (const cred of currentCredentialsValues as Credential[]) {
    currentCredsKeys.push(cred.name);
  }

  const availableCredentialTypes = R.omit(currentCredsKeys, credentialTypes);

  let filteredCredentials = [];
  if (credentials.length > 0) {
    // we only care about active credentials
    filteredCredentials = credentials.filter((cred) => cred.active === true);

    if (credentialType !== '') {
      filteredCredentials = filteredCredentials.filter((cred) => {
        return cred.name === credentialType;
      });
    }
  }

  return (
    <div>
      {account && (
        <Row className={accountStyles.credsExportRow}>
          <Col sm="2">
            <Input
              type="select"
              placeholder="Credential type"
              value={credentialType}
              onChange={(e) => setCredentialType(e.target.value)}
            >
              {credentialTypes &&
                arrayToOptions(Object.values(credentialTypes))}
            </Input>
          </Col>
        </Row>
      )}
      <Form>
        {error && (
          <Alert color="danger">
            <strong>Error:</strong> {error}
          </Alert>
        )}
        <Table striped responsive>
          <thead>
            <CreateCredentialsRow
              setError={setError}
              system={system}
              account={account}
              accountId={account.key}
              setReload={setReload}
              reload={reload}
              credentialTypes={availableCredentialTypes}
            />
          </thead>
          <tbody>
            {filteredCredentials.map((c) => (
              <CredentialsRow
                setError={setError}
                key={c.key}
                credential={c}
                system={system}
                account={account}
                accountId={account.key}
                setReload={setReload}
                reload={reload}
              />
            ))}
          </tbody>
        </Table>
      </Form>
      {reload && <div></div>}
    </div>
  );
};
