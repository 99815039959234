.permissionsTable {
  margin-top: 50px;
}

.permissionsSelectRow {
  margin-top: 10px;
}

.usersTable select,
.usersTable input {
  width: 100px;
  margin-top: 5px;
}

.usersTable {
  overflow-x: inherit;
}

.usersTableContainer {
  overflow-x: inherit;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}
