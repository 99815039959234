import * as React from 'react';
import { Table, Button } from 'reactstrap';
import { DownArrow, UpArrow } from '../../components/Icons';
import { useEffect } from 'react';
import { logger } from '../../logging';
import { useMountedLayoutEffect } from 'react-table';

const dotdigitalStyles = require('../account/dotdigital/dotdigital.css');
const iconStyles = require('../../components/icons.css');

export const AddressbooksTable = ({
  preGlobalFilteredRows,
  state,
  setGlobalFilter,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  system,
  pageOptions,
  page,
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  canPreviousPage,
  canNextPage,
  handleSelectedRows,
  canBulkUpdate,
  selectedFlatRows,
}) => {
  const filters = state.filters;
  const pageIndex = state.pageIndex;
  const pageSize = state.pageSize;
  const selectedRowIds = state.selectedRowIds;

  useEffect(() => {
    // filters changed
    gotoPage(0);
  }, [filters]);

  // logger.debug(
  //   `selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${
  //     selectedFlatRows &&
  //     selectedFlatRows[0] &&
  //     selectedFlatRows[0].original.key
  //   }, canBulkUpdate: ${canBulkUpdate}`
  // );

  // useMountedLayoutEffect(() => {
  //   logger.debug(
  //     `[useMountedLayoutEffect] selectedFlatRows: ${Object.keys(
  //       selectedFlatRows
  //     )}`
  //   );

  //   selectedRowIds &&
  //     selectedFlatRows &&
  //     handleSelectedRows(selectedRowIds, selectedFlatRows);
  // }, [handleSelectedRows, selectedRowIds]);

  const handlBulkUpdateClick = () => {
    handleSelectedRows(selectedFlatRows);
  };

  return (
    <div className={dotdigitalStyles.fullHeight}>
      <div className={dotdigitalStyles.fullHeightTableContainer}>
        <div className={dotdigitalStyles.fitContentTableContainer}>
          <Table
            {...getTableProps()}
            striped
            className={dotdigitalStyles.addressbooksTable}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={dotdigitalStyles.addressbooksTableHeader}
                    >
                      {canBulkUpdate && column.isSelectCol === true ? (
                        <Button
                          onClick={handlBulkUpdateClick}
                          className={dotdigitalStyles.bulkSelectButton}
                        >
                          Confirm Selection
                        </Button>
                      ) : null}
                      {column.render('Header')}
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                      {column.isSelectCol !== true ? (
                        <Button
                          className={dotdigitalStyles.sortButton}
                          size="sm"
                          color="link"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.isSorted ? 'sorted ' : 'sort'}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <DownArrow
                                  fill="#007bff"
                                  class={iconStyles.sortButton}
                                />
                              ) : (
                                <UpArrow
                                  fill="#007bff"
                                  class={iconStyles.sortButton}
                                />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </Button>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className={dotdigitalStyles.pagination}>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous Page
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next Page
          </Button>
          <div>
            Page{' '}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>
        </div>
        Total Rows: {rows.length}
        <br />
        Page Size: {pageSize}
        {canBulkUpdate ? (
          <React.Fragment>
            <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};
