import moment from 'moment';

export const getDate = () => `${moment().unix()}`;

export const engagementRowGenerator = (
  name,
  territory,
  id,
  type,
  rollup_id
) => {
  const today = getDate();
  const artists = {
    party_id: id,
    party_name: name,
    party_type: type,
    rollup_id: rollup_id || 0,
    territory: territory,
    status_UMG: 'null',
    status_label: 'null',
    status_artist: 'null',
    created: today,
    modified: today,
    modified_by: '',
  };

  return artists;
};
