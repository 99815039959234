.addButton,
.minusButton {
  margin-top: -4px;
}

.smallButton {
  padding: 0 6px;
}

.tick,
.cross {
  margin-top: 13px;
  margin-left: -15px;
  width: 20px;
  height: 20px;
}

.sortButton {
  margin-top: -4px;
}

.unarchive,
.archive {
  margin-left: -5px !important;
}
