.formRow {
  margin-top: 20px;
}

.button {
  margin-right: 10px;
}

.columnsButton {
  margin: 15px 0;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.mappingModalBody,
.mappingModalFooter,
.mappingModalHeader {
  padding: 50px;
}

.mappingInput {
  cursor: pointer;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mappingModalBodyRow {
  padding: 20px;
}

.mappingModalFooterRow {
  padding: 20px;
  text-align: left;
}

.mappingModal {
  max-width: 80%;
}

.validationError {
  min-width: 170px;
}

.optinEnabledRowHeader {
  margin-top: 25px;
}

.optinEnabledRow {
  margin-top: 15px;
}

.optinTableRowHeader {
  margin-top: 25px;
}

.optinTableRow {
  margin-top: 15x;
}
