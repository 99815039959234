.label {
  display: block;
  padding-bottom: 10px;
}

.checkbox {
  height: 20px;
  width: 20px;
}

.labelContainer {
  padding-left: 10px;
}

.container {
  padding-bottom: 20px;
}