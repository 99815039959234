import pino, { Logger } from 'pino';

// Map Pino levels to Google Cloud Logging severity levels
// https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
const levelToSeverity: any = {
  trace: 'DEBUG',
  debug: 'DEBUG',
  info: 'INFO',
  warn: 'WARNING',
  error: 'ERROR',
  fatal: 'CRITICAL',
};

// base this on NODE_ENV (set in our webpack configs)
const level = process.env.ENV === 'prod' ? 'info' : 'trace';

export const logger: Logger = pino({
  level,
  name: 'digital-accounts-management',
  base: { serviceContext: { service: 'digital-accounts-management' } },
  formatters: {
    level(label: string) {
      const pinoLevel = label;
      const severity = levelToSeverity[pinoLevel];
      // `@type` property tells Error Reporting to track even if there is no `stack_trace`
      // you might want to make this an option the plugin, in our case we do want error reporting for all errors, with or without a stack
      const typeProp =
        pinoLevel === 'error' || pinoLevel === 'fatal'
          ? {
              '@type':
                'type.googleapis.com/google.devtools.clouderrorreporting.v1beta1.ReportedErrorEvent',
            }
          : {};
      return { severity, ...typeProp };
    },
    log(object: any) {
      const logObject = object as { err?: Error };
      const stackProp = logObject?.err?.stack
        ? { stack_trace: logObject.err.stack }
        : {};

      return {
        ...object,
        ...stackProp,
      };
    },
  },
  messageKey: 'message',
});
