import * as React from 'react';
import { Table, Row, Col, Button } from 'reactstrap';
import { GlobalFilter } from '../../../components/table/GlobalFilter';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { DownArrow, UpArrow } from '../../../components/Icons';

const slicktextStyles = require('./slicktext.css');
const dotdigitalStyles = require('../dotdigital/dotdigital.css');
const iconStyles = require('../../../components/icons.css');

export const TextwordsTable = ({
  preGlobalFilteredRows,
  state,
  setGlobalFilter,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  system,
  pageOptions,
  page,
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  canPreviousPage,
  canNextPage,
  account,
}) => {
  const onCellClicked = (cell, row) => {
    // logger.info(`cell clicked - cell: ${JSON.stringify({ ...cell.getCellProps() })}, row: ${JSON.stringify({ ...row.getRowProps() })}`);
  };
  const filters = state.filters;
  const pageIndex = state.pageIndex;
  const pageSize = state.pageSize;

  useEffect(() => {
    // filters changed
    gotoPage(0);
  }, [filters]);

  return (
    <div className={dotdigitalStyles.fullHeight}>
      <div className={dotdigitalStyles.fullHeight}>
        {account && (
          <Row className={slicktextStyles.searchRow}>
            <Col>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Col>
          </Row>
        )}
        <div className={slicktextStyles.textwordsTableContainer}>
          <Table
            {...getTableProps()}
            striped
            className={slicktextStyles.textwordsTable}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={slicktextStyles.textwordsTableHeader}
                    >
                      {column.render('Header')}
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                      <Button
                        className={slicktextStyles.sortButton}
                        size="sm"
                        color="link"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.isSorted ? 'sorted ' : 'sort'}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <DownArrow
                                fill="#007bff"
                                class={iconStyles.sortButton}
                              />
                            ) : (
                              <UpArrow
                                fill="#007bff"
                                class={iconStyles.sortButton}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </Button>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {(account ? rows : page).map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={(e) => onCellClicked(cell, row)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        {account ? (
          <>Total rows: {rows?.length}</>
        ) : (
          <>
            <div className={dotdigitalStyles.pagination}>
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous Page
              </Button>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                Next Page
              </Button>
              <div>
                Page{' '}
                <em>
                  {pageIndex + 1} of {pageOptions?.length}
                </em>
              </div>
            </div>
            Total Rows: {rows.length}
            <br />
            Page Size: {pageSize}
          </>
        )}
      </div>
    </div>
  );
};
