import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { logger } from '../../logging';
import { getDAMApp } from '../../services/firebase';
const R = require('ramda');

const columnSelectorStyles = require('./columnsSelectorStyles.css');

export const ColumnsSelector = ({ columns, userCols, tableName }) => {
  const damApp = getDAMApp();
  const uid =
    (damApp.auth().currentUser && damApp.auth().currentUser.uid) || '';
  logger.debug(`uid: ${uid}`);
  const userColsRef = damApp
    .database()
    .ref(`user_preferences/${uid}/columns/${tableName}`);

  logger.debug(
    `userCols: ${JSON.stringify(userCols)}, columns: ${JSON.stringify(columns)}`
  );

  const columnChanged = async (checked, column) => {
    logger.debug(
      `columnChanged: checked: ${checked}, column: ${JSON.stringify(column)}`
    );
    const currentCols = (userCols as any) || {};
    const columnsToUpdate = { ...currentCols };
    columnsToUpdate[column.id] = checked;
    try {
      await userColsRef.update(columnsToUpdate);
    } catch (e) {
      logger.error(
        `[ColumnSelector] error updating column selection in firebase: ${e}`
      );
    }
  };

  const columnControls = R.map((column: any) => {
    logger.debug(`column: ${JSON.stringify(column)}`);
    if (column.id === 'selection') {
      return;
    }

    let checked = true;
    if (userCols && userCols[column.id] === false) {
      checked = false;
    }

    return (
      <Label
        check
        className={columnSelectorStyles.label}
        key={`label-${column.id}`}
      >
        <Input
          type="checkbox"
          checked={checked}
          className={columnSelectorStyles.checkbox}
          onChange={(e) => columnChanged(e.target.checked, column)}
          key={column.id}
        />
        <div
          className={columnSelectorStyles.labelContainer}
          key={`div-${column.id}`}
        >
          {`  ${column.Header}`}
        </div>
      </Label>
    );
  }, columns);

  return (
    <div className={columnSelectorStyles.container}>
      <FormGroup check>{columnControls}</FormGroup>
    </div>
  );
};
