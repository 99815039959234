import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import * as matchSorter from 'match-sorter';
import classnames from 'classnames';
import { PartyActivationMapping } from './PartyActivationMapping';
import * as firebaseService from '../../services/firebase';
import { useObject } from 'react-firebase-hooks/database';
import { Loader } from '../../components/Loader';
import firebase from 'firebase/compat/app';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import { PartyEngagementMapping } from './PartyEngagementMapping';
import { useAuthState } from 'react-firebase-hooks/auth';
import { engagementRowGenerator } from './functions';

const pagesStyles = require('../pages.css');
const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
export const fuzzyTextFilterFnExp = fuzzyTextFilterFn;

export const PartyEngagementContainer = () => {
  const [engagementStatus, activationStatus] = [0, 1];
  const [artistData, setArtistData] = useState({
    fetched: {},
    active: 0,
    loading: false,
    artistFound: false,
  });
  const [activeTab, setActiveTab] = useState(activationStatus);
  const [query, setQuery] = useState({ field: 'party_name_lower', value: 'A' });
  const [activationStatusIDs, setActivationStatusIDs] = useState({});
  const partyServiceApp = firebaseService.getPartyServiceApp();
  const ifbApp = firebaseService.getIFBApp();

  const [activationStatusRef, engagementStatusRef, countriesRef] = [
    partyServiceApp.database().ref('party_id'),
    partyServiceApp.database().ref('party_engagement_status'),
    ifbApp.database().ref('countries'),
  ];
  const activationStatusQuery = useMemo(
    () =>
      query.field === 'party_name_lower'
        ? activationStatusRef
            .orderByChild(query.field)
            .startAt(query.value.toLowerCase())
            .endAt(`${query.value.toLowerCase()}\uf8ff`)
            .limitToFirst(1000)
        : activationStatusRef
            .orderByChild(query.field)
            .equalTo(Number(query.value) || 0),
    [query]
  );

  const [countriesList, countriesLoading]: [
    firebase.database.DataSnapshot,
    any,
    any
  ] = useObject(countriesRef.orderByChild('has_dm_account').equalTo(true));

  const [activationStatusList, activationLoading]: [
    firebase.database.DataSnapshot,
    any,
    any
  ] = useObject(activationStatusQuery);

  const availableCountries = useMemo(
    () =>
      countriesLoading
        ? []
        : (Object.entries(countriesList.val()).map((row) => row[1]) as any[]),
    [countriesList, countriesLoading]
  );

  const parsedActivationList: any[] = useMemo(
    () =>
      activationStatusList
        ? Object.entries(activationStatusList.val() || {}).map((row) => row[1])
        : [],
    [activationStatusList]
  );

  const parsedEngagementList: any[] = useMemo(
    () =>
      artistData.fetched[artistData.active]
        ? Object.entries(artistData.fetched[artistData.active]).map(
            (row) => row[1]
          )
        : [],
    [artistData.fetched[artistData.active]]
  );

  const [user] = useAuthState(partyServiceApp.auth());

  const toggle = (tab) => {
    if (tab != activeTab) setActiveTab(tab);
  };

  const switchToPartyEngagement = (newArtist: number) => {
    setArtistData((artistData) => {
      if (newArtist !== artistData.active) {
        if (!artistData.fetched[newArtist])
          return { ...artistData, loading: true, active: newArtist };
        else return { ...artistData, loading: false, active: newArtist };
      }
      return artistData;
    });

    setActiveTab(engagementStatus);
  };
  const switchArtist = (newArtist: number) => {
    if (/^[0-9]*$/.test(`${newArtist}`)) {
      if (!artistData.fetched[newArtist])
        setArtistData((artistData) => {
          return { ...artistData, loading: true, active: newArtist };
        });
      else
        setArtistData((artistData) => {
          return { ...artistData, loading: false, active: newArtist };
        });
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      const fetched = await (
        await engagementStatusRef
          .orderByChild('party_id')
          .equalTo(artistData.active)
          .get()
      ).val();
      setArtistData({
        fetched: { ...artistData.fetched, [artistData.active]: fetched },
        loading: false,
        active: artistData.active,
        artistFound: fetched && true,
      });
    })();
  }, [artistData.active]);

  useEffect(() => {
    if (!activationLoading && !countriesLoading && !artistData.loading) {
      const newRows = {};
      const party_id = artistData.active;
      const values = artistData.fetched[party_id] || {};
      activationStatusRef
        .child(`${party_id}`)
        .get()
        .then((partySnap) => {
          const party = partySnap.val();
          if (!party) {
            setArtistData({ ...artistData, artistFound: false });
            return;
          }
          const { party_name, party_type, rollup_id } = party;

          let [territory, engagementID] = ['', ''];
          let changes = false;
          availableCountries.forEach((country) => {
            territory = country.id;
            engagementID = `${party_id}-${territory}`;
            if (!values[engagementID]) {
              newRows[engagementID] = engagementRowGenerator(
                party_name,
                territory,
                party_id,
                party_type,
                rollup_id
              );
              changes = true;
            }
          });
          if (changes) {
            engagementStatusRef.update(newRows);
            setArtistData({
              fetched: {
                ...artistData.fetched,
                [party_id]: { ...artistData.fetched[party_id], ...newRows },
              },
              loading: false,
              active: party_id,
              artistFound: true,
            });
          }
        });
    }
  }, [activationStatusList, artistData.active, artistData.loading]);

  return (
    <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
      <Row>
        <Col sm={{ size: 12 }} className={pagesStyles.headerRow}>
          <h3>Party Engagement Status</h3>
        </Col>
      </Row>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === activationStatus })}
            onClick={() => toggle(activationStatus)}
            id="activationTab"
          >
            Party Activation Status
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === engagementStatus })}
            onClick={() => toggle(engagementStatus)}
            id="engagementTab"
          >
            Party Engagement Status
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className={pagesStyles.fullHeight}>
        <TabPane tabId={activationStatus} className={pagesStyles.fullHeight}>
          {activationLoading ? (
            <Loader loading={true} />
          ) : (
            <PartyActivationMapping
              setActivationIDs={setActivationStatusIDs}
              switchToPartyEngagementStatus={switchToPartyEngagement}
              currentUser={user}
              activationStatusList={parsedActivationList}
              activationStatusRef={activationStatusRef}
              query={query}
              setQuery={setQuery}
            />
          )}
        </TabPane>
        <TabPane tabId={engagementStatus} className={pagesStyles.fullHeight}>
          {artistData.loading ? (
            <Loader loading={true} />
          ) : (
            <PartyEngagementMapping
              engagementStatusRef={engagementStatusRef}
              currentUser={user}
              engagementStatusList={parsedEngagementList}
              switchArtist={switchArtist}
              defaultArtist={artistData.active}
              artistFound={artistData.artistFound}
              activationStatusIDs={activationStatusIDs}
              availableCountries={availableCountries}
            />
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};
