import * as React from 'react';
import { useMemo, useState, useEffect, useRef } from 'react';
import { getDate } from './functions';
import { logger } from '../../../functions/src/logging';
import {
  Alert,
  Input,
  Collapse,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import moment from 'moment';
import { ColumnsSelector } from '../../components/table/ColumnsSelector';
import { PartyEngagementTable } from './PartyEngagementTable';
import { fuzzyTextFilterFnExp } from './PartyEngagementContainer';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import { PartyEngagement } from './interfaces';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { Can } from '../../auth/Can';
import { getDAMApp } from '../../services/firebase';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Loader } from '../../components/Loader';

const R = require('ramda');
const pagesStyles = require('../pages.css');
const tableStyles = require('./partyMapping.css');

interface props {
  engagementStatusList: PartyEngagement[];
  availableCountries: any[];
  currentUser: any;
  engagementStatusRef: any;
  activationStatusIDs: any;
  defaultArtist: number;
  artistFound: boolean;
  switchArtist: (newArtist: number) => void;
}

export const PartyEngagementMapping = ({
  engagementStatusRef,
  activationStatusIDs,
  currentUser,
  engagementStatusList,
  availableCountries,
  switchArtist,
  defaultArtist,
  artistFound,
}: props) => {
  const damApp = getDAMApp();
  const TABLE_NAME = 'partyEngagement';
  const [values, setValues] = useState<PartyEngagement[]>([]);
  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [countriesVisible, setCountriesVisible] = useState(false);
  const [countriesStatus, setCountriesStatus] = useState({});
  const [rowFalseDisabled, setRowFalseDisabled] = useState({});
  const artistSubmit = useRef<HTMLInputElement>(null);

  const [activeValues, setActiveValues] = useState({
    active: [],
    unactive: [],
  });

  const uid = damApp.auth().currentUser.uid;
  const userColsRef = damApp
    .database()
    .ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  const records = useMemo(() => activeValues.active, [activeValues.active]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const defaultCountries = {
    US: true,
    CN: true,
    GB: true,
    GE: true,
    JP: true,
    FR: true,
  };

  useEffect(() => {
    availableCountries.forEach((country) => {
      countriesStatus[country.id] = true;
    });
  }, [availableCountries]);

  const filterNullValue = (entries) => {
    const filteredRows = { active: [], unactive: [] };

    entries.forEach((value) => {
      if (!countriesStatus[value.territory]) return;
      filteredRows.active.push(value);
    });

    return filteredRows;
  };

  const updateData = (rowIndex, columnId, value, original) => {
    //engagementStatusRef.update({[rowIndex]:{...original,[columnId]:value}})
    const currentFilter = 'active';
    const entityID = `${original.party_id}-${original.territory}`;
    const oldRow = activeValues[currentFilter][rowIndex];
    const newRow = {
      ...oldRow,
      [columnId]: value,
      modified_by: currentUser.email,
      modified: getDate(),
      sent_to_bq: false, // new flag - if rows are modified this should be set to false, and the backend should export rows where this is false only
    };

    setChangedValues((changedValues) => {
      return {
        ...changedValues,
        [entityID]: newRow,
      };
    });

    setActiveValues((old) => {
      old[currentFilter] = old[currentFilter].map((row, index) => {
        const rowID = `${row.party_id}-${row.territory}`;
        if (rowID === entityID) {
          return {
            ...old[currentFilter][rowIndex],
            [columnId]: value,
          };
        }
        return row;
      });
      return old;
    });
  };

  const removeFromChangedData = (id, changedValues) => {
    const newChangedValuesList = Object.entries(changedValues);
    changedValues = {};

    newChangedValuesList.forEach((entry) => {
      if (entry[0] != id) {
        changedValues[entry[0]] = entry[1];
      }
    });

    setChangedValues(changedValues);
  };

  const saveDataBtn = ({
    row: { original, index },
    activationStatusIDs,
    changedValues,
    rowFalseDisabled,
  }) => {
    const [saveModal, setSaveModal] = useState(false);
    const name = `${original.party_id}-${original.territory}`;

    const toggleSaveModal = () => {
      setSaveModal((saveModal) => !saveModal);
    };

    const updateRow = () => {
      try {
        engagementStatusRef.update({ [name]: changedValues[name] });
        logger.info(
          `(${currentUser.displayName}|${
            currentUser.uid
          })has pushed the following changes: ${JSON.stringify(
            changedValues[name]
          )}`
        );
      } catch (e) {
        logger.error(
          `(${currentUser.displayName}|${
            currentUser.uid
          }) has failed to push the changes:\n${JSON.stringify(
            changedValues[name]
          )}\nWith the error:\n${JSON.stringify(e)}`
        );
      }

      setActiveValues((old) => {
        old['active'] = old['active'].map((row, index) => {
          const rowID = `${row.party_id}-${row.territory}`;
          if (rowID === name) {
            return {
              ...old['active'][index],
              ...changedValues[name],
            };
          }
          return row;
        });
        return old;
      });

      removeFromChangedData(name, changedValues);
    };

    return (
      <>
        {rowFalseDisabled[name]?.status ? (
          <Alert color="danger" style={{ textAlign: 'center', margin: 0 }}>
            Invalid input, can't allow three false flags
          </Alert>
        ) : rowFalseDisabled[name]?.nullCount > 0 && changedValues[name] ? (
          <Alert color="danger" style={{ textAlign: 'center', margin: 0 }}>
            Invalid input, no flag can be set to none
          </Alert>
        ) : (
          <Button
            className={tableStyles.updateRowBtn}
            disabled={
              !changedValues[name] || !activationStatusIDs[original.party_name]
            }
            onClick={toggleSaveModal}
          >
            Update data
          </Button>
        )}
        <Modal
          isOpen={saveModal}
          toggle={(e) => setSaveModal((visible) => !visible)}
        >
          <ModalHeader toggle={toggleSaveModal}>Please Confirm</ModalHeader>
          <ModalBody>
            <p>
              You are now about to set {`${changedValues[name]?.party_name}`}'s
              engagement levels to the following:
              <br />
              Artist Level: {`${changedValues[name]?.status_artist}`}
              <br />
              Label Level: {`${changedValues[name]?.status_label}`}
              <br />
              UMG Level: {`${changedValues[name]?.status_UMG}`}
            </p>
            Are you absolutely sure you want to do this?
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={(e) => {
                updateRow();
                toggleSaveModal();
              }}
            >
              Confirm
            </Button>{' '}
            <Button color="secondary" onClick={toggleSaveModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  const boolToggle = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    activationStatusIDs,
    updateData,
    changedValues,
  }) => {
    const [value, setValue] = useState(initialValue);
    const name = `${original.party_id}-${original.territory}`;

    useEffect(() => {
      const state = changedValues[name];
      const falseArray = state
        ? [state.status_UMG, state.status_label, state.status_artist]
        : [original.status_UMG, original.status_label, original.status_artist];

      const falseCount = falseArray.filter((status) => status == false).length;
      const nullCount = falseArray.filter((status) => status === 'null').length;

      if (falseCount >= 3) {
        setRowFalseDisabled((rows) => {
          return { ...rows, [name]: { status: true, nullCount } };
        });
      } else {
        setRowFalseDisabled((rows) => {
          return { ...rows, [name]: { status: false, nullCount } };
        });
      }
    }, [changedValues]);

    useEffect(() => {
      const changedValue = changedValues[name];
      setValue(changedValue ? changedValue[id] : original[id]);
    }, [original]);

    const onChange = (e) => {
      const newValue =
        e.target.value === 'undefined' ? 'null' : e.target.value === 'true';
      setValue(newValue);
      updateData(index, id, newValue, original);
    };

    return (
      <>
        <Can I="manage" a="party_engagement_status">
          <Input
            type="select"
            onChange={onChange}
            value={String(value)}
            disabled={
              activationStatusIDs[original.party_name] === 'null' ||
              !activationStatusIDs[original.party_name]
            }
          >
            <option value={'undefined'} key={'undefined'}>
              None
            </option>
            <option value="true" key="true">
              True
            </option>
            ,
            <option value="false" key="false">
              False
            </option>
          </Input>
        </Can>
        <Can I="manage" not a="party_engagement_status">
          <Can I="read" a="party_engagement_status">
            <Input type="select" value={String(value)} disabled={true}>
              <option value={'undefined'} key={'undefined'}>
                None
              </option>
              <option value="true" key="true">
                True
              </option>
              <option value="false" key="false">
                False
              </option>
            </Input>
          </Can>
        </Can>
      </>
    );
  };

  const columns = useMemo(() => {
    const cols: any = [
      {
        Header: 'Party Name',
        accessor: 'party_name',
        id: 'party_name',
        disableFilters: true,
      },
      {
        Header: 'Party Service ID',
        accessor: 'party_id',
        id: 'party_id',
        disableFilters: true,
      },
      {
        Header: 'Party Type',
        accessor: 'party_type',
        id: 'party_type',
        disableFilters: true,
      },
      {
        Header: 'Rollup ID',
        accessor: 'rollup_id',
        id: 'rollup_id',
        disableFilters: true,
      },
      {
        Header: 'Territory',
        accessor: 'territory',
        id: 'territory',
      },
      {
        Header: 'Artist Status',
        accessor: 'status_artist',
        id: 'status_artist',
        Cell: boolToggle,
      },
      {
        Header: 'Label Status',
        accessor: 'status_label',
        id: 'status_label',
        Cell: boolToggle,
      },
      {
        Header: 'UMG Status',
        accessor: 'status_UMG',
        id: 'status_UMG',
        Cell: boolToggle,
      },
      {
        Header: 'Update row',
        accessor: 'saveDataBtn',
        id: 'saveDataBtn',
        Cell: saveDataBtn,
        disableFilters: true,
      },
      {
        Header: 'Created',
        accessor: (row) => {
          if (row.created) {
            return moment
              .unix(parseInt(row.created))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'created',
        disableFilters: true,
      },
      {
        Header: 'Modified',
        accessor: (row) => {
          if (row.modified) {
            return moment
              .unix(parseInt(row.modified))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'modified',
        disableFilters: true,
      },
      {
        Header: 'Modified by',
        accessor: 'modified_by',
        id: 'modified_by',
        disableFilters: true,
      },
    ];
    return cols;
  }, []);

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'artist_name',
            desc: false,
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      activationStatusIDs,
      updateData,
      active: activeValues.active,
      unactive: activeValues.unactive,
      changedValues,
      rowFalseDisabled,
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  useEffect(() => {
    setActiveValues(filterNullValue(values));
  }, [values, countriesStatus]);

  useEffect(() => {
    if (!engagementStatusList) {
      setValues([]);
      return;
    }
    setValues(engagementStatusList);
  }, [engagementStatusList]);

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);

  const toggleCountries = () => setCountriesVisible(!countriesVisible);
  const toggleAllCountries = (status) => {
    setCountriesStatus((countries) => {
      const newCountries = {};
      Object.entries(countries).forEach(
        (country) => (newCountries[country[0]] = status)
      );
      return newCountries;
    });
  };

  const toggleCountry = (id) =>
    setCountriesStatus((countries) => {
      return { ...countriesStatus, [id]: !countries[id] };
    });

  const toggleSwitchArtist = () =>
    switchArtist(Number(artistSubmit.current.value));
  const validateId = (e) =>
    !/^[0-9]*$/.test(e.target.value) && e.preventDefault();

  if (userColsLoading) {
    return <Loader loading={true} />;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  return (
    <div className={pagesStyles.fullHeight}>
      <div className={pagesStyles.fullHeight}>
        <div className={`${pagesStyles.noPadding} ${pagesStyles.fullHeight}`}>
          <Row>
            <Col>
              <h3>
                {artistFound
                  ? engagementStatusList[0]?.party_name
                  : 'Artist not found'}
              </h3>
            </Col>
          </Row>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <Button
                className={tableStyles.button}
                onClick={toggleColumnsSelect}
              >
                Columns
              </Button>
              <Button className={tableStyles.button} onClick={toggleCountries}>
                Toggle countries
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Find Party ID</h5>
            </Col>
          </Row>
          <Row className={tableStyles.queryRow}>
            <Col sm={2}>
              <Input
                onChange={validateId}
                innerRef={artistSubmit}
                placeholder={`Currently looking at ${defaultArtist}`}
              />
            </Col>
            <Col sm={2}>
              <Button
                className={tableStyles.button}
                onClick={toggleSwitchArtist}
              >
                Load Data
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className={pagesStyles.divider}></Col>
          </Row>
          {engagementStatusList.length === 0 && (
            <Row>
              <Col sm={{ size: 12 }}>
                <Alert color="warning">
                  No artist found, initiate a search to load data.
                </Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12}>
              <Collapse isOpen={isColumnsSelectOpen}>
                <ColumnsSelector
                  columns={columns}
                  userCols={userCols}
                  tableName={TABLE_NAME}
                />
              </Collapse>
            </Col>
          </Row>
          <Col hidden={!countriesVisible}>
            <Button
              className={tableStyles.button}
              onClick={() => toggleAllCountries(true)}
            >
              Select all countries
            </Button>
            <Button
              className={tableStyles.button}
              onClick={() => toggleAllCountries(false)}
            >
              Unselect all countries
            </Button>
            <Row>
              <Col className={tableStyles.countryList}>
                {availableCountries
                  .sort((x, y) => {
                    if (x.id > y.id) return 1;
                    else return -1;
                  })
                  .map((country, index) => (
                    <div key={index} onClick={() => toggleCountry(country.id)}>
                      <input
                        type="checkbox"
                        readOnly
                        checked={countriesStatus[country.id] ? true : false}
                        name={country.id}
                      />
                      <label htmlFor={country.id}>{country.id}</label>
                    </div>
                  ))}
              </Col>
            </Row>
          </Col>
          <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
            <PartyEngagementTable tableData={tableData} />
          </Col>
        </div>
      </div>
    </div>
  );
};
