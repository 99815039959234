import * as React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button } from 'reactstrap';
import { logger, serverLogger } from '../logging';
import { getDAMApp, getCanopusApp, getIFBApp, getPartyServiceApp } from '../services/firebase';

export const LogoutButton = () => {
  const damApp = getDAMApp();
  const ifbApp = getIFBApp();
  const canopusApp = getCanopusApp();
  const partyServiceApp = getPartyServiceApp();

  const [user] = useAuthState(damApp.auth() as any);

  const logout = () => {
    if (user) {
      const userEmail = user['email'];
      // logger.info('reidrecting to', from.pathname);
      try {
        serverLogger(`User logged out: ${userEmail}`, 'info');
      } catch (e) {
        logger.error('error calling serverlogger');
      }
    }

    damApp.auth().signOut();
    canopusApp.auth().signOut();
    ifbApp.auth().signOut();
    partyServiceApp.auth().signOut();
  };

  if (user) {
    return <Button onClick={logout}>Log Out ({user.email})</Button>;
  }

  return <div></div>;
};
