import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import MultiSelectDropdown from './MultiSelectDropdown';

interface props {
  options: {
    id: string;
    label: string;
    placeholder?: string;
    number?: boolean;
    toggle?: boolean;
    values?: any[];
  }[];
  defaultValues: {
    select: string;
    input: string;
  };
  // I cant think of a better name
  submitable: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSubmit?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDeselect?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange?: Function;
}

const DDTableFilterStyles = require('./DDTable.css');

export const TableSearch = ({
  options,
  onSubmit,
  onChange,
  submitable,
  defaultValues,
  onSelect,
  onDeselect,
}: props) => {
  const [labelMap, setLabelMap] = useState({});

  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLInputElement>(null);
  const [currentOption, setCurrentOption] = useState<any>(undefined);

  useEffect(() => {
    const newLabelMap = {};

    options.forEach((option) => {
      newLabelMap[option.id] = option.label;
    });

    setLabelMap(newLabelMap);
  }, []);

  useEffect(() => {
    inputRef.current.value = defaultValues.input;
  }, [defaultValues.input]);

  const parseInput = () => {
    return options.filter((option) => option.id === selectRef.current?.value)[0]
      ?.number
      ? Number(inputRef.current?.value) || ''
      : inputRef.current?.value.trim();
  };

  return (
    <div>
      <label>{labelMap[selectRef.current?.value] || ''}</label>
      <Row>
        <Col sm={4} className={DDTableFilterStyles.fitContents}>
          <Input
            onChange={() => {
              const currentOption = options.filter(
                (option) => option.id === selectRef.current?.value
              )[0];
              if (inputRef.current) {
                inputRef.current.value = '';
              }
              setCurrentOption(currentOption);
            }}
            innerRef={selectRef}
            type="select"
            className={DDTableFilterStyles.fitContents}
          >
            {options.map((option, index) => (
              <option key={index} value={option.id}>
                {option.label}
              </option>
            ))}
          </Input>
        </Col>

        {currentOption?.toggle && (
          <Col>
            <MultiSelectDropdown
              values={currentOption.value}
              single={true}
              handleDeselect={(id) => onDeselect(id, currentOption.id)}
              handleSelect={(id) => onSelect(id, currentOption.id)}
            />
          </Col>
        )}
        {submitable ? (
          <>
            <Col hidden={currentOption?.toggle}>
              <Input
                innerRef={inputRef}
                placeholder={''}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit(
                      parseInput(),
                      selectRef.current?.value,
                      submitable
                    );
                  }
                }}
              />
            </Col>
            <Col sm={3}>
              <Button
                onClick={() => {
                  onSubmit(parseInput(), selectRef.current?.value, submitable);
                }}
                block
              >
                Load Data
              </Button>
            </Col>
          </>
        ) : (
          <Col hidden={currentOption?.toggle}>
            <Input
              innerRef={inputRef}
              onChange={() =>
                onChange(parseInput(), selectRef.current?.value, submitable)
              }
              placeholder={''}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
