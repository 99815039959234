import * as React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router';
import { AccountsContainer } from './pages/accounts/AccountsContainer';
import { Users } from './pages/users/Users';
import { Login } from './pages/Login';
import { Location } from 'history';
import { CreateAccount } from './pages/createAccount/CreateAccount';
import { Account } from './pages/account/Account';
import { Enumerations } from './pages/enumerations/Enumerations';
import { Labels } from './pages/labels/Labels';
import { Loader } from './components/Loader';
import { useUserWithRole } from './hooks/firebase';
import { Features } from './pages/feature-flags/Features';
import { CountryManager } from './pages/country-manager/CountryManager';
import { Roles } from './pages/roles/Roles';
import { CustomFieldsContainer } from './pages/customFields/CustomFieldsContainer';
import { AddressbooksContainer } from './pages/addressbooks/AddressbooksContainer';
import { CommunityContainer } from './pages/community/CommunityContainer';
import { PartyEngagementContainer } from './pages/partyEngagementStatus/PartyEngagementContainer';
import { WorkflowsContainer } from './pages/workflows/WorkflowsContainer';
import {
  getDAMApp,
  getIFBApp,
  getCanopusApp,
  getPartyServiceApp,
} from './services/firebase';
import { OptinsContainer } from './pages/optins/OptinsContainer';
import { ArtistMappingContainer } from './pages/artistMapping/ArtistMappingContainer';

const pagesStyles = require('./pages/pages.css');

export const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/community">
      <CommunityContainer />
    </PrivateRoute>
    <PrivateRoute exact path="/country-manager">
      <CountryManager />
    </PrivateRoute>
    <PrivateRoute exact path="/custom_fields">
      <CustomFieldsContainer />
    </PrivateRoute>
    <PrivateRoute exact path="/artist_mapping">
      <ArtistMappingContainer />
    </PrivateRoute>
    <PrivateRoute exact path="/accounts/new/:system">
      <CreateAccount />
    </PrivateRoute>
    <PrivateRoute path="/accounts/:system/:id">
      <Account />
    </PrivateRoute>
    <PrivateRoute exact path="/accounts">
      <AccountsContainer />
    </PrivateRoute>
    <PrivateRoute exact path="/addressbooks">
      <AddressbooksContainer />
    </PrivateRoute>
    <PrivateRoute exact path="/party_engagement_status">
      <PartyEngagementContainer />
    </PrivateRoute>
    <PrivateRoute exact path="/optins">
      <OptinsContainer />
    </PrivateRoute>
    <PrivateRoute path="/users">
      <Users />
    </PrivateRoute>
    <PrivateRoute path="/enumerations">
      <Enumerations />
    </PrivateRoute>
    <PrivateRoute path="/labels">
      <Labels />
    </PrivateRoute>
    <Route path="/login">
      <Login />
    </Route>
    <PrivateRoute path="/features">
      <Features />
    </PrivateRoute>
    <PrivateRoute path="/roles">
      <Roles />
    </PrivateRoute>
    <PrivateRoute path="/workflows">
      <WorkflowsContainer />
    </PrivateRoute>
    <Route path="/">
      <Redirect exact from="/" to="accounts" />
    </Route>
  </Switch>
);

type LocationState = {
  from: Location;
};

export type LocationProps = Location<LocationState>;

export const PrivateRoute = ({ children, ...rest }) => {
  const location: LocationProps = useLocation();
  const damApp = getDAMApp();
  const ifbApp = getIFBApp();
  const canopusApp = getCanopusApp();
  const partyServiceApp = getPartyServiceApp();
  const [user, loading, error] = useUserWithRole(damApp.auth());
  const [userIFB, loadingIFB, errorIFB] = useUserWithRole(ifbApp.auth());
  const [userCanopus, loadingCanopus, errorCanopus] = useUserWithRole(
    canopusApp.auth()
  );
  const [userPartyService, loadingPartyService, errorPartyService] =
    useUserWithRole(partyServiceApp.auth());

  if (loading || loadingCanopus || loadingIFB || loadingPartyService) {
    return <Loader loading={true} />;
  }

  if (user && userCanopus && userIFB && userPartyService) {
    return <Route {...rest}>{children}</Route>;
  }

  return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
};
