import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import { CustomFields } from './CustomFields';
import { CustomFieldGroups } from './CustomFieldGroups';

const pagesStyles = require('../pages.css');

const NEW_FIELDS_TAB = '1';
const FIELD_GROUPS_TAB = '2';

export const CustomFieldsContainer = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Custom Fields</h3>
        </Col>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === NEW_FIELDS_TAB })}
            onClick={() => {
              toggle(NEW_FIELDS_TAB);
            }}
          >
            Fields
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === FIELD_GROUPS_TAB })}
            onClick={() => {
              toggle(FIELD_GROUPS_TAB);
            }}
          >
            Field Groups
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className={pagesStyles.fullHeight}>
        <TabPane tabId={NEW_FIELDS_TAB} className={pagesStyles.fullHeight}>
          <CustomFields />
        </TabPane>
        <TabPane tabId={FIELD_GROUPS_TAB} className={pagesStyles.fullHeight}>
          <CustomFieldGroups />
        </TabPane>
      </TabContent>
    </div>
  );
};
