import * as React from 'react';
import { useMemo, useState, useEffect, useRef } from 'react';
import {
  Alert,
  Input,
  Collapse,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import moment from 'moment';
import { ColumnsSelector } from '../../components/table/ColumnsSelector';
import { PartyActivationTable } from './PartyActivationTable';
import { fuzzyTextFilterFnExp } from './PartyEngagementContainer';
import { getDate } from './functions';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import { PartyActivation } from './interfaces';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { logger } from '../../logging';
import { Can } from '../../auth/Can';
import { getDAMApp } from '../../services/firebase';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Loader } from '../../components/Loader';
const R = require('ramda');
const pagesStyles = require('../pages.css');
const tableStyles = require('./partyMapping.css');

interface props {
  activationStatusList: PartyActivation[];
  activationStatusRef: any;
  setActivationIDs: any;
  currentUser: any;
  switchToPartyEngagementStatus: any;
  setQuery: any;
  query: any;
}

export const PartyActivationMapping = ({
  activationStatusRef,
  setActivationIDs,
  currentUser,
  activationStatusList,
  setQuery,
  switchToPartyEngagementStatus,
  query,
}: props) => {
  const damApp = getDAMApp();
  const TABLE_NAME = 'partyActivation';
  const queryRef = { value: useRef(null), type: useRef(null) };
  const [values, setValues] = useState<PartyActivation[]>([]);
  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [nullFilter, setNullFilter] = useState(true);
  const [activeValues, setActiveValues] = useState({
    active: [],
    unactive: [],
  });

  const uid = damApp.auth().currentUser.uid;
  const userColsRef = damApp
    .database()
    .ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  const records = useMemo(
    () => (nullFilter ? activeValues.active : activeValues.unactive),
    [activeValues, nullFilter]
  );

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  useEffect(() => {
    if (!activationStatusList) {
      setValues([]);
      return;
    }

    setValues(activationStatusList);
  }, [activationStatusList]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const updateData = (rowIndex, columnId, value, original) => {
    const currentFilter = nullFilter ? 'active' : 'unactive';
    const oldRow = activeValues[currentFilter][rowIndex];
    if (!oldRow.created) oldRow.created = getDate();

    const newRow = {
      ...oldRow,
      [columnId]: value,
      modified_by: currentUser.email,
      modified: getDate(),
      sent_to_bq: false, // new flag - if rows are modified this should be set to false, and the backend should export rows where this is false only
    };

    if (typeof value === 'undefined') {
      delete newRow[columnId];
    }

    setChangedValues((changedValues) => {
      return {
        ...changedValues,
        [original.party_id]: newRow,
      };
    });

    setActiveValues((old) => {
      old[currentFilter] = old[currentFilter].map((row, index) => {
        if (row.party_id === original.party_id) {
          return {
            ...old[currentFilter][rowIndex],
            [columnId]: value,
          };
        }
        return row;
      });
      return old;
    });
  };

  const filterNullValue = (entries) => {
    const filteredRows = { active: [], unactive: [] };
    entries.forEach((value) => {
      filteredRows.active.push(value);
    });

    return filteredRows;
  };

  const updateActivationData = () => {
    const newData = {};
    values.forEach((value) => {
      newData[value.party_name] = value.cdp_activation_status;
    });
    setActivationIDs(newData);
  };

  const removeFromChangedData = (id, changedValues) => {
    const newChangedValuesList = Object.entries(changedValues);
    changedValues = {};

    newChangedValuesList.forEach((entry) => {
      if (entry[0] != id) {
        changedValues[entry[0]] = entry[1];
      }
    });

    setChangedValues(changedValues);
  };

  const saveDataBtn = ({ row: { original }, changedValues }) => {
    const [saveModal, setSaveModal] = useState(false);
    const toggleSaveModal = () => setSaveModal((saveModal) => !saveModal);

    const updateRow = () => {
      const id = original.party_id;
      try {
        activationStatusRef.update({ [id]: changedValues[id] });
        updateActivationData();
        logger.info(
          `(${currentUser.displayName}|${
            currentUser.uid
          })has pushed the following changes:\n${JSON.stringify(
            changedValues[id]
          )}`
        );
      } catch (e) {
        logger.error(
          `(${currentUser.displayName}|${
            currentUser.uid
          }) has failed to push the following changes:\n${JSON.stringify(
            changedValues[id]
          )}\nWith the error:\n${JSON.stringify(e)}`
        );
      }

      removeFromChangedData(id, changedValues);
    };

    return (
      <>
        <Button
          className={tableStyles.updateRowBtn}
          disabled={!changedValues[original.party_id]}
          onClick={toggleSaveModal}
        >
          Update data
        </Button>
        <Modal
          isOpen={saveModal}
          toggle={(e) => setModalVisible((visible) => !visible)}
        >
          <ModalHeader toggle={toggleSaveModal}>Please Confirm</ModalHeader>
          <ModalBody>
            <p>
              You are now about to set{' '}
              {`${changedValues[original.party_id]?.party_name}`}'s activation
              status to be:{' '}
              {`${changedValues[original.party_id]?.cdp_activation_status}`}
            </p>
            Are you absolutely sure you want to do this?
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={(e) => {
                updateRow();
                toggleSaveModal();
              }}
            >
              Confirm
            </Button>{' '}
            <Button color="secondary" onClick={toggleSaveModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  const artistSwitchBtn = ({ row: { original } }) => {
    const toggleArtistSwitch = () =>
      switchToPartyEngagementStatus(original.party_id);
    return (
      <Button className={tableStyles.updateRowBtn} onClick={toggleArtistSwitch}>
        Switch to Party Engagement Status
      </Button>
    );
  };

  const boolToggle = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateData,
    changedValues,
  }) => {
    const [value, setValue] = useState(
      changedValues[original.party_id]
        ? changedValues[original.party_id][id]
        : initialValue
    );

    const onChange = (e) => {
      const newValue =
        e.target.value === 'undefined' ? undefined : e.target.value === 'true';
      setValue(newValue);

      if (newValue !== original[id]) {
        updateData(index, id, newValue, original);
        return;
      }

      removeFromChangedData(original.party_id, changedValues);
    };

    useEffect(() => {
      const changedValue = changedValues[original.party_id];
      setValue(changedValue ? changedValue[id] : original[id]);
    }, [original]);

    return (
      <>
        <Can I="manage" a="party_engagement_status">
          <Input type="select" onChange={onChange} value={String(value)}>
            <option value={'undefined'} key={'undefined'}>
              None
            </option>
            <option value="true" key="true">
              True
            </option>
            <option value="false" key="false">
              False
            </option>
          </Input>
        </Can>
        <Can I="manage" not a="party_engagement_status">
          <Can I="read" a="party_engagement_status">
            <Input type="select" disabled={true} value={String(value)}>
              <option value={'undefined'} key={'undefined'}>
                None
              </option>
              <option value="true" key="true">
                True
              </option>
              <option value="false" key="false">
                False
              </option>
            </Input>
          </Can>
        </Can>
      </>
    );
  };

  const columns = useMemo(() => {
    const cols: any = [
      {
        Header: 'Party Name',
        accessor: 'party_name',
        id: 'party_name',
      },
      {
        Header: 'Party Service ID',
        accessor: 'party_id',
        id: 'party_id',
      },
      {
        Header: 'Party Type',
        accessor: 'party_type',
        id: 'party_type',
      },
      {
        Header: 'CDP Activation Status',
        accessor: 'cdp_activation_status',
        id: 'cdp_activation_status',
        Cell: boolToggle,
      },
      {
        Header: 'Update row',
        accessor: 'saveDataBtn',
        id: 'saveDataBtn',
        Cell: saveDataBtn,
        disableFilters: true,
      },
      {
        Header: 'Engagement Status',
        accessor: 'artistSwitchBtn',
        id: 'artistSwitchBtn',
        Cell: artistSwitchBtn,
        disableFilters: true,
      },
      {
        Header: 'Created',
        accessor: (row) => {
          if (row.created) {
            return moment
              .unix(parseInt(row.created))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'created',
        disableFilters: true,
      },
      {
        Header: 'Last updated',
        accessor: (row) => {
          if (row.modified) {
            return moment
              .unix(parseInt(row.modified))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'modified',
        disableFilters: true,
      },
      {
        Header: 'Modified by',
        accessor: 'modified_by',
        id: 'modified_by',
        disableFilters: true,
      },
    ];
    return cols;
  }, []);

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'artist_name',
            desc: false,
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      updateData,
      changedValues,
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  useEffect(() => {
    setActiveValues(filterNullValue(values));
    updateActivationData();
  }, [values]);

  useEffect(() => {
    if (!activationStatusList) {
      setValues([]);
      return;
    }
    setValues(activationStatusList);
  }, [activationStatusList]);

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);
  const toggleSwitchQuery = () =>
    setQuery({
      field: queryRef.type.current.value,
      value: queryRef.value.current.value.trim().toLowerCase(),
    });

  if (userColsLoading) {
    return <Loader loading={true} />;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  return (
    <div className={pagesStyles.fullHeight}>
      <div className={pagesStyles.fullHeight}>
        <div className={`${pagesStyles.noPadding} ${pagesStyles.fullHeight}`}>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <Button
                className={tableStyles.button}
                onClick={toggleColumnsSelect}
              >
                Columns
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Find Artist</h5>
            </Col>
          </Row>
          <Row className={tableStyles.queryRow}>
            <Col sm={1}>
              <Input
                type="select"
                innerRef={queryRef.type}
                defaultValue={query.field}
              >
                <option value={'party_id'}>Party ID</option>
                <option value={'party_name_lower'}>Party Name</option>
              </Input>
            </Col>
            <Col sm={2}>
              <Input
                id="artistSearchInput"
                innerRef={queryRef.value}
                placeholder={`Search for artist entry`}
              />
            </Col>
            <Col sm={2}>
              <Button
                id="artistSearchSubmit"
                className={tableStyles.button}
                onClick={toggleSwitchQuery}
              >
                Load Data
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className={pagesStyles.divider}></Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Collapse isOpen={isColumnsSelectOpen}>
                <ColumnsSelector
                  columns={columns}
                  userCols={userCols}
                  tableName={TABLE_NAME}
                />
              </Collapse>
            </Col>
          </Row>
          {activationStatusList.length === 0 && (
            <Row>
              <Col sm={{ size: 12 }}>
                <Alert color="warning">
                  No artist found, initiate a search to load data.
                </Alert>
              </Col>
            </Row>
          )}
          <Row className={pagesStyles.fullHeight}>
            <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
              <PartyActivationTable tableData={tableData} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
