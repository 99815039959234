import * as React from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Table, TabPane } from 'reactstrap';
import { Loader } from '../../../components/Loader';
import { ref } from '../../../utils/firebase';
import { LYTICS_TAB } from '../AccountForm';

export const LyticsTab = ({ accountId }) => {

  const [lyticsData, lyticsDataLoading, lyticsDataError] = useObjectVal(ref(`accounts/dotdigital/${accountId}/lyticsData`));

  if (lyticsDataLoading === true) {
    return <Loader loading={true} />;
  }

  if (lyticsDataError) {
    return <TabPane tabId={LYTICS_TAB}><React.Fragment>Error</React.Fragment></TabPane>;
  }

  if (!lyticsData) {
    return <TabPane tabId={LYTICS_TAB}><div>No Lytics entities exist for this account</div></TabPane>;
  }

  const rowKeys = Object.keys(lyticsData);
  const rows = rowKeys.map((k) => {
    const data = lyticsData[k];
    return <tr key={k}><td>{data.name}</td><td>{data.id}</td><td>{data.type}</td><td><a href={data.link} target="_blank">{data.name}</a></td><td>{data.error ? data.error : ''}</td></tr>;
  });

  return <TabPane tabId={LYTICS_TAB}>
    <Table striped={true} size="sm">
    <thead>
      <tr>
        <th>Name</th>
        <th>Id</th>
        <th>Type</th>
        <th>Link</th>
        <th>Error</th>
      </tr>
    </thead>
    <tbody>
      {rows}
    </tbody>
  </Table>
  </TabPane>
};