import * as React from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Alert,
} from 'reactstrap';
import { Field } from '@availity/form';
import { SelectField } from '@availity/select';
import '@availity/yup';
import { ref } from '../../../utils/firebase';
import { Loader } from '../../../components/Loader';
import { Can } from '../../../auth/Can';
import { logger } from '../../../logging';
import { getDAMApp } from '../../../services/firebase';
import { useFormikContext } from 'formik';
import { BrandInputField } from '../../../components/brand/BrandInputField';
import { BrandService } from '../../../components/brand/BrandLookup';

const accountStyles = require('../account.css');

// account name should be unique - maybe this should be onsubmit tho (as this is reused for view/edit)
// we should debounce this/throttle it
export const validateSTAccountName = async (
  rawValue,
  normalised_name,
  id,
  setFieldError,
  setHasFormErrors
) => {
  logger.debug(
    `[SlicktextFields] validateSTAccountName - rawValue: ${rawValue}, normalised_name: ${normalised_name}, id: ${id}, typeof id: ${typeof id}`
  );
  const damApp = getDAMApp();
  const errorMessage = 'An account name must be provided and unique';

  const value = rawValue ? rawValue.trim() : '';

  if (!value || value === '') {
    logger.debug(
      '[SlicktextFields] validateSTAccountName - there is no value, so we dont lookup. Returning as this is the cause of a setstate error'
    );
    setFieldError('name', errorMessage);
    return false; // there's no value so it is invalid
  }

  logger.debug(
    `[SlicktextFields] validateSTAccountName - typeof damApp: ${typeof damApp}`
  );
  const accountsRef = damApp
    .database()
    .ref('accounts/slicktext')
    .orderByChild('name')
    .equalTo(value);
  const accountsSnap = await accountsRef.once('value');

  const accounts = [];
  accountsSnap.forEach((v) => {
    accounts.push({ ...v.val(), id: v.key });
  });

  // so obviously there's an account with this name (if we're editing)
  if (accounts.length > 0) {
    const account = accounts[0];
    // if there is an account with this name but a different uid, then we have a validation error
    if (account.id !== id) {
      logger.debug(
        `[SlicktextFields] validateSTAccountName - account.id (${account.id}) and id (${id}) are not the same`
      );
      setFieldError('name', errorMessage);
      return false;
    }
  }

  // check account id:
  const accountsNNRef = damApp
    .database()
    .ref('accounts/slicktext')
    .orderByChild('normalised_name')
    .equalTo(normalised_name);
  const accountsNNSnap = await accountsNNRef.once('value');

  const accountsNNs = [];
  accountsNNSnap.forEach((v) => {
    accountsNNs.push({ ...v.val(), id: v.key });
  });

  // so obviously there's an account with this NN (if we're editing)
  if (accountsNNs.length > 0) {
    const account = accountsNNs[0];
    // if there is an account with this NN but a different uid, then we have a validation error
    if (account.id !== id) {
      logger.debug(
        `[SlicktextFields] validateSTAccountName -  account.id: ${account.id} id: ${id} not same`
      );
      setFieldError(
        'normalised_name',
        `${errorMessage}: Account normalised name must also be unique`
      );
      return false;
    }
  }

  return true;
};

export const SlicktextFields = ({
  model,
  setCanSave,
  system,
  accountId,
  errors,
}) => {
  const formik = useFormikContext();
  const [errorMessage, setErrorMessage] = useState('');

  const handleNameChange = (e) => {
    logger.debug(
      `[SlicktextFields] handleNameChanged: e.target.value: ${e.target.value}`
    );
    const nameClean = e.target.value
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/[ ]/g, '-')
      .toLowerCase();
    formik.setFieldValue('normalised_name', nameClean);
  };

  // active/inactive toggle confirmation modal
  const [modal, setModal] = useState(false);
  const toggle = (ok?) => {
    if (ok === false) {
      formik.setFieldValue('active', !formik.values['active']); // Maybe look at default here instead
    }
    setModal(!modal);
  };

  //active/inactive toggle confirmation countryModal
  const [countryModal, setCountryModal] = useState(false);
  const toggleCountryModal = (ok?) => {
    if (ok === false) {
      logger.debug(
        `[toggleCountryModal] ok = ${ok} - reverting to previous selection: ${model['country']}`
      );
      formik.setFieldValue('country', model['country']);
    }

    setCountryModal(!countryModal);
  };

  // this is called when trying to toggle active. It opens the confirmation modal and stores the desired active value
  const confirmSetActive = (value: boolean) => {
    toggle();
  };

  // this is called when the confirm modal button is clicked. It sets the active value and closes the modal.
  const setActiveConfirmed = () => {
    toggle();
  };

  const setActiveCancelled = () => {
    toggle(false);
  };

  const confirmSetCountry = (value: any) => {
    toggleCountryModal();
  };

  const setCountryConfirmed = () => {
    toggleCountryModal();
  };

  const setCountryCancelled = () => {
    toggleCountryModal(false);
  };

  const checkIfCanSave = () => {
    if (errorMessage === '') {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  };

  useEffect(() => {
    if (errors) {
      const invalidFields = Object.keys(errors);
      // logger.debug(`invalidFields: ${JSON.stringify(invalidFields)}`);
      if (invalidFields.length > 0) {
        setErrorMessage(
          `The following fields are set incorrectly: ${JSON.stringify(
            invalidFields
          )}`
        );
        checkIfCanSave();
      } else {
        setErrorMessage('');
        checkIfCanSave();
      }
    }
  });

  const handlePartyIdChange = (partyId, partyName) => {
    logger.debug(
      `[AttentiveFields] handlePartyIdChange: partyId: ${partyId}, partyName: ${partyName}`
    );
    formik.setFieldValue('party_id', partyId);
    formik.setFieldValue('party_name', partyName);
  };

  const [enumerations, enumerationsLoading] = useObjectVal(
    ref('enumerations') as any
  ) as any;
  const [countries, countriesLoading] = useObjectVal(
    ref('countries') as any
  ) as any;

  if (!enumerations || enumerationsLoading || countriesLoading || !countries) {
    return <Loader loading={true} />;
  }

  const countriesValues = (Object.values(countries) as any) || [];
  const languages = (Object.values(enumerations.languages) as any) || [];
  const timezones = (Object.values(enumerations.timezones) as any) || [];

  const isNameDisabled = model.id !== undefined;
  const disableIfInactive = formik.values['active'] === false ? true : false;
  const hasStAccountId =
    formik.values['stAccountId'] !== undefined &&
    formik.values['stAccountId'] !== '';

  return (
    <React.Fragment>
      {errorMessage !== '' ? (
        <Row>
          <Col sm={12}>
            <Alert color="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      ) : null}
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <Field
            name="name"
            label="Name"
            required
            onChange={handleNameChange}
            disabled={isNameDisabled || disableIfInactive}
          />
          <Field name="normalised_name" label="Normalised Name" disabled />
          {!hasStAccountId && (
            <Alert color="info">
              Account ID will be populated when a SlickText credential is added
              to DAM
            </Alert>
          )}
          <Field
            name="stAccountId"
            label="SlickText Account ID"
            disabled={true}
          />
          <BrandInputField
            name="party_id"
            label="Party ID"
            brandFieldName="party_name"
            brandFieldNameLabel="Party Name"
            required={false}
            disabled={disableIfInactive}
            handleOnChange={handlePartyIdChange}
            brandService={BrandService.PARTY_SERVICE}
          />
          <SelectField
            name="country"
            label="Country"
            options={countriesValues}
            isMulti={false}
            required
            isDisabled={disableIfInactive}
            onChange={(option) => confirmSetCountry(option)}
          />
          <SelectField
            name="language"
            label="Language"
            options={languages}
            isMulti={false}
            required
            isDisabled={disableIfInactive}
          />

          <SelectField
            name="timezone"
            label="Timezone"
            options={timezones}
            isMulti={false}
            required
            isDisabled={disableIfInactive}
          />

          <Field name="id" type="hidden" />
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Can I="delete" an="account">
            <FormGroup check>
              <Label check className={accountStyles.activeCheckboxLabel}>
                <Field
                  className={accountStyles.activeSTCheckbox}
                  name="active"
                  type="checkbox"
                  onChange={(e) => {
                    confirmSetActive(e.target.checked);
                  }}
                />{' '}
                Active
              </Label>
            </FormGroup>
          </Can>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={(e) => toggle(false)}>
        <ModalHeader toggle={(e) => toggle(false)}>Please Confirm</ModalHeader>
        <ModalBody>
          Please confirm that you would like to change the 'active' status of
          this account. Once saved this change will also update all of the Vault
          credentials' active flag for this account.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => setActiveConfirmed()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => setActiveCancelled()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={countryModal} toggle={(e) => toggleCountryModal(false)}>
        <ModalHeader toggle={(e) => toggleCountryModal(false)}>
          Please Confirm Country Change
        </ModalHeader>
        <ModalBody>
          Please confirm that you would like to change the country from [
          {countries[model['country']] && countries[model['country']].label}] to
          [
          {countries[formik.values['country']] &&
            countries[formik.values['country']].label}
          ] for this account. Please note changing this will have repercussions
          as to the data acquisition method and this account's textwords.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => setCountryConfirmed()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => setCountryCancelled()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
