import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { logger } from '../../../logging';
import { DDFields, validateAccountId, validateAccountName } from './DDFields';
import { Form } from '@availity/form';
import { object, string, number, ref } from 'yup';
import '@availity/yup';

const DDFormValidation = object().shape({
  ddAccountName: string().required(),
  canopusId: number().isRequired(false),
  party_id: number().isRequired(false),
  party_name: string().isRequired(false),
  ddAccountId: number().required(),
  ddAccountLevel: string().required(),
  ddAccountType: string().required(),
  isUmgOrPartner: string().required(),
  label: string().required(),
  regionalGroupings: string().required(),
  territoryOperatingCountry: string().required(),
  localeCode: string().required(),
  language: string().required(),
  ddLeadAccountSetup: string().required(),
  ipMapping: string().required(),
  sendingAccountCFAEmail: string().required(),
  isB2COrB2B: string().required(),
});

const DDFormErrorCheck = ({
  errors,
  touched,
  isValidating,
  setHasFormErrors,
  setFormErrors,
  setFormTouched,
}) => {
  // logger.debug(
  //   `[DDForm] DDFormErrorCheck errors: ${JSON.stringify(errors)}, touched: ${JSON.stringify(
  //     touched
  //   )}, isValidating: ${JSON.stringify(isValidating)}`
  // );

  const hasErrors = Object.keys(errors).length > 0;
  // logger.debug(`[DDForm] DDFormErrorCheck hasErrors: ${hasErrors}`);

  React.useEffect(() => {
    setFormErrors(errors);
    setHasFormErrors(hasErrors);
    setFormTouched(touched);
  });

  return null;
};

export const DDForm = ({
  canSave,
  model,
  save,
  account,
  system,
  setCanSave,
}) => {
  const [hasFormErrors, setHasFormErrors] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [formTouched, setFormTouched] = React.useState({});
  const defaultDDFormValues = {
    ddAccountName: '',
    ddFormerAccountName: '',
    canopusId: '',
    party_id: '',
    party_name: '',
    ddAccountId: '',
    ddAccountLevel: '',
    ddAccountType: '',
    storePlatform: '',
    isB2COrB2B: '',
    isUmgOrPartner: '',
    label: '',
    seedListName: '',
    shopifyAccountId: '',
    regionalGroupings: '',
    accountLevelParent: '',
    territoryOperatingCountry: '',
    localeCode: '',
    language: '',
    ddLeadAccountSetup: '',
    ipMapping: '',
    sendingAccountCFAEmail: '',
    isUsingCustomDomains: '',
    seedListUpdates: '',
    createStandardLyticsEntities: false,
    createGenreLyticsEntities: false,
    allowMerchRecommendationsForAddressbooks: false,
    active: true,
    ...model,
  };

  const handleSubmit = async (values, { setFieldError }) => {
    logger.debug(
      `[DDForm] handleSubmit called: [ACTIVE]: ${JSON.stringify(
        values.active
      )} values: ${JSON.stringify(values)}`
    );
    // setFieldError: (field: string, message: string | undefined) => void;
    // handle async ddAccountName validation
    const isAccountNameValid = await validateAccountName(
      values['ddAccountName'],
      values['key'],
      values['territoryOperatingCountry'],
      setFieldError
    );

    logger.debug(
      `[DDForm] handleSubmit - formErrors: ${JSON.stringify(formErrors)}`
    );

    // handle async ddAccountId validation
    const isAccountIdValid = await validateAccountId(
      values['ddAccountId'],
      values['key'],
      setFieldError
    );

    logger.debug(
      `[DDForm] handleSubmit - [save about to be called] formErrors: ${JSON.stringify(
        formErrors
      )}`
    );

    if (isAccountNameValid === true && isAccountIdValid === true) {
      await save(values, formErrors);
    }
  };

  const submitDisabled = !canSave || hasFormErrors;

  return (
    <Form
      initialValues={defaultDDFormValues}
      onSubmit={handleSubmit}
      validationSchema={DDFormValidation}
    >
      {({ errors, touched, isValidating }) => (
        <React.Fragment>
          <Row>
            <Col sm="12">
              <DDFields
                accountId={account && account.id}
                system={system}
                model={model}
                setCanSave={setCanSave}
                errors={errors}
              />
            </Col>
          </Row>
          <Button
            type="submit"
            disabled={submitDisabled}
            color={submitDisabled ? 'secondary' : 'primary'}
          >
            Save
          </Button>
          <DDFormErrorCheck
            errors={errors}
            touched={touched}
            isValidating={isValidating}
            setHasFormErrors={setHasFormErrors}
            setFormErrors={setFormErrors}
            setFormTouched={setFormTouched}
          />
        </React.Fragment>
      )}
    </Form>
  );
};
