import * as React from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LogoutButton } from '../components/LogoutButton';
import { Can } from '../auth/Can';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Feature } from 'flagged';
import { getDAMApp } from '../services/firebase';

export const NavBar = () => {
  const damApp = getDAMApp();
  const [user, loading] = useAuthState(damApp.auth() as any);
  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand tag={Link} to="/accounts">
        Digital Accounts Manager
      </NavbarBrand>
      <Nav className="mr-auto" navbar>
        <Can I="read" an="account">
          {user && (
            <NavItem>
              <NavLink tag={Link} to="/accounts">
                Accounts
              </NavLink>
            </NavItem>
          )}
        </Can>
        <Feature name="addressbooks-management">
          <Can I="read" an="account">
            {user && (
              <NavItem>
                <NavLink tag={Link} to="/addressbooks">
                  Marketing Lists
                </NavLink>
              </NavItem>
            )}
          </Can>
        </Feature>
        <Feature name="party-engagement-status">
          <Can I="read" an="party_engagement_status">
            {user && (
              <NavItem>
                <NavLink tag={Link} to="/party_engagement_status">
                  Party Engagement
                </NavLink>
              </NavItem>
            )}
          </Can>
        </Feature>
        <Feature name="artist-mapping">
          <Can I="manage" a="artist_mapping">
            {user && (
              <NavItem>
                <NavLink tag={Link} to="/artist_mapping">
                  Artist Mapping
                </NavLink>
              </NavItem>
            )}
          </Can>
        </Feature>
        <Feature name="community-mapping">
          <Can I="manage" a="community">
            {user && (
              <NavItem>
                <NavLink tag={Link} to="/community">
                  Community Mapping
                </NavLink>
              </NavItem>
            )}
          </Can>
        </Feature>
        <Feature name="custom-fields">
          <Can I="manage" a="custom_fields">
            {user && (
              <NavItem>
                <NavLink tag={Link} to="/custom_fields">
                  Custom Fields
                </NavLink>
              </NavItem>
            )}
          </Can>
        </Feature>

        <Feature name="optins-management">
          <Can I="manage" an="optins">
            {user && (
              <NavItem>
                <NavLink tag={Link} to="/optins">
                  Optins
                </NavLink>
              </NavItem>
            )}
          </Can>
        </Feature>
        <Feature name="workflow-management">
          <Can I="manage" a="workflows">
            {user && (
              <NavItem>
                <NavLink tag={Link} to="/workflows">
                  Workflows
                </NavLink>
              </NavItem>
            )}
          </Can>
        </Feature>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Admin
          </DropdownToggle>
          <DropdownMenu right>
            <Feature name="treasure-data-admin">
              <Can I="manage" a="country">
                <DropdownItem>
                  {user && (
                    <NavItem>
                      <NavLink tag={Link} to="/country-manager">
                        Country Manager
                      </NavLink>
                    </NavItem>
                  )}
                </DropdownItem>
              </Can>
            </Feature>
            <Can I="manage" an="enumerations">
              <DropdownItem>
                {user && (
                  <NavItem>
                    <NavLink tag={Link} to="/enumerations">
                      Enumerations
                    </NavLink>
                  </NavItem>
                )}
              </DropdownItem>
            </Can>
            <Can I="manage" a="features">
              <DropdownItem>
                {user && (
                  <NavItem>
                    <NavLink tag={Link} to="/features">
                      Features
                    </NavLink>
                  </NavItem>
                )}
              </DropdownItem>
            </Can>
            <Can I="manage" a="label">
              <DropdownItem>
                {user && (
                  <NavItem>
                    <NavLink tag={Link} to="/labels">
                      Labels
                    </NavLink>
                  </NavItem>
                )}
              </DropdownItem>
            </Can>
            <Can I="manage" a="roles">
              <DropdownItem>
                {user && (
                  <NavItem>
                    <NavLink tag={Link} to="/roles">
                      Roles
                    </NavLink>
                  </NavItem>
                )}
              </DropdownItem>
            </Can>
            <Can I="manage" a="user">
              <DropdownItem>
                {user && (
                  <NavItem>
                    <NavLink tag={Link} to="/users">
                      Users
                    </NavLink>
                  </NavItem>
                )}
              </DropdownItem>
            </Can>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      <LogoutButton />
    </Navbar>
  );
};
