import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Row, Col, Button, Collapse } from 'reactstrap';
import { AccountsTable } from '../AccountsTable';
import { SelectColumnFilter } from '../../../components/table/SelectColumnfilter';
import { Redirect } from 'react-router-dom';
import { Can } from '../../../auth/Can';
import { ExportToExcel } from '../ExportToExcel';
import { DefaultColumnFilter } from '../../../components/table/DefaultFilter';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { Loader } from '../../../components/Loader';
import { Enumerations } from '../../enumerations/enumerationsModel';
import { Credential, fuzzyTextFilterFnExp } from '../AccountsContainer';
import { logger } from '../../../logging';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import { ErrorIcon, TickIcon } from '../../../components/Icons';
import { ColumnsSelector } from '../../../components/table/ColumnsSelector';
import * as firebaseService from '../../../services/firebase';
import * as R from 'ramda';
import { Labels } from '../../labels/labelsModel';
import { Countries } from '../../country-manager/countriesModel';
import { DDTableFilters, countries } from '../../../components/DDTableFilters';
const pagesStyles = require('../../pages.css');
const accountsStyles = require('../accounts.css');

export interface DDAccount {
  id: string;
  authsCompletedBy?: string;
  ipMapping: string; //enum
  regionalGroupings: string; //enum
  accountLevelParent: string;
  territoryOperatingCountry: string; //enum
  ddAccountName: string;
  formerName?: string;
  ddAccountLevel: string; //enum
  ddAccountType: string; //enum
  storePlatform?: string;
  priority: string; //enum
  isB2B?: boolean;
  isB2C?: boolean;
  isUsingCustomDomain?: boolean;
  localCode: string;
  language: string; // enum
  timezone: string; //enum
  ddLeadAccountSetup: string;
  ddAccountId: string;
  sendingAccountCFAEmail: string;
  isUmgOrParter: string;
  credentials?: Credential[];
  lyticsSyncAPIExists?: boolean;
  lyticsAPIUserRequired?: boolean;
  formbuilderAPIUserRequired?: boolean;
  lemonadeAPIUserRequired?: boolean;
  comments?: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  data_acquisition_selection?: string;
  data_acquisition_processing_method?: string;
  send_subscription_insights?: boolean;
  send_genre_insights?: boolean;
}

interface Accounts {
  accounts: DDAccount[];
}

const getEnumLabels = (
  values,
  enumerations: Enumerations,
  labelsModel: Labels,
  countriesModel: Countries
) => {
  const enumMappings = {
    regionalGroupings: 'regionalGroupings',
    territoryOperatingCountry: 'operatingCountries',
    ddAccountLevel: 'ddAccountLevels',
    ddAccountType: 'ddAccountTypes',
    isB2COrB2B: 'b2bOrB2c',
    isUmgOrPartner: 'umgOrPartner',
  };

  const enumsToLookup = [
    'regionalGroupings',
    'territoryOperatingCountry',
    'ddAccountLevel',
    'ddAccountType',
    'isB2COrB2B',
    'isUmgOrPartner',
    'label',
  ];
  const labels = { ...values };
  // logger.debug(`labels['label']: ${labels['label']}`);
  try {
    enumsToLookup.forEach((type) => {
      // logger.info('type: ', type, ' values[type]: ', values[type], 'enumerations.enumerations[enumMappings[type]][values[type]].label:', enumerations.enumerations[enumMappings[type]][values[type]].label);
      if (type === 'label') {
        labels[type] = labelsModel.getName(values[type]);
      } else if (type === 'territoryOperatingCountry') {
        labels[type] = countriesModel.getName(values[type]);
      } else {
        labels[type] = enumerations.getLabel(enumMappings[type], values[type]);
      }
    });
  } catch (e) {
    logger.error(
      e,
      ' ddAccountName: ',
      values.ddAccountName,
      values,
      enumerations.enumerations
    );
  }

  return labels;
};

export const Accounts = ({
  enumerations,
  showActive,
  setShowActive,
  labelsModel,
  countriesModel,
}) => {
  const TABLE_NAME = 'ddAccounts';
  const [searchQuery, setSearchQuery] = useState('ddAccountName');
  const [searchType, setSearchType] = useState('broad');
  const [searchValue, setSearchValue] = useState('');

  const ifbApp = firebaseService.getIFBApp();
  const damApp = firebaseService.getDAMApp();
  // get accounts from firebase hooks here
  const baseAccountsRef = damApp.database().ref('accounts/dotdigital');
  const accountsRef = useMemo(() => {
    const accountsRef =
      searchType === 'exact'
        ? baseAccountsRef
            .orderByChild(searchQuery)
            .equalTo(searchValue)
            .limitToFirst(1000)
        : baseAccountsRef
            .orderByChild(searchQuery)
            .startAt(searchValue)
            .endAt(`${searchValue}\uf8ff`)
            .limitToFirst(1000);
    return accountsRef;
  }, [searchValue]);

  const [createAccount, setCreateAccount] = useState(false);
  //const [showInactiveAccounts, setShowInactiveAccounts] = useState(false);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);

  const [filteredValues, setFilteredValues] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(true);

  const [countryList, setCountryList] = useState([]);

  const [user] = useAuthState(damApp.auth() as any);

  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const uid =
    (damApp.auth().currentUser && damApp.auth().currentUser.uid) || '';
  const userColsRef = damApp
    .database()
    .ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  useEffect(() => {
    (async () => {
      const countryList = (await countries()).map((country) => {
        return { ...country, id: country.id.toLowerCase() };
      });
      setCountryList(countryList);
    })();
  }, []);

  // when filteredValues changes or enumerations changes
  // and there are no values yet
  // put values into the correct shape + lookup enumeration labels + update state with mapped values
  useEffect(() => {
    // logger.debug(
    //   `[useEffect] here values are set if values.length === 0 (${values.length}) filteredValues: ${filteredValues}, enumerations: ${enumerations}, labelsModel: ${labelsModel}`
    // );
    if (enumerations && labelsModel) {
      const filteredValuesVal = filteredValues || [];
      const accounts = Object.keys(filteredValuesVal).filter((k) => {
        return filteredValuesVal[k].active === showActive;
      });

      setValues(
        accounts.map((k) => {
          const value = filteredValues[k];
          const labels = getEnumLabels(
            value,
            enumerations,
            labelsModel,
            countriesModel
          );
          return { ...labels, key: k };
        })
      );
    }
  }, [filteredValues, enumerations, showActive, labelsModel, countriesModel]); // when filteredValues or enumerations changes

  const toggleShowActive = () => {
    logger.debug(`[toggleShowActive] showActive: ${showActive}`);
    const show = !showActive;
    setShowActive(show);
    setValues([]);
  };

  const newAccount = () => {
    setCreateAccount(true);
  };

  const updateTableData = (rowIndex, columnId, value) => {
    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const AddressbookCountCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateTableData,
  }) => {
    const [count, setCount] = React.useState(null);

    const addressbooksObtained = (snapshot) => {
      const addressbookCount = snapshot.numChildren();
      setCount(addressbookCount);
      updateTableData(index, 'addressbooksCount', addressbookCount);
    };

    const getAddressbookCount = (accountId) => {
      const addressbookRef = ifbApp
        .database()
        .ref('addressbooks')
        .orderByChild('account_addressbookname')
        .startAt(`${accountId}`)
        .endAt(`${accountId}\uf8ff`)
        .on('value', addressbooksObtained);
    };

    React.useEffect(() => {
      getAddressbookCount(original.ddAccountId);
    }, [original.ddAccountId]);

    return (
      <React.Fragment>
        {count === null ? (
          <div>
            <img
              src="/images/loading.gif"
              className={accountsStyles.countLoader}
            />
          </div>
        ) : (
          <div>{count && `${count}`}</div>
        )}
      </React.Fragment>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'DAM key',
        accessor: 'key',
        id: 'key',
      },
      {
        Header: 'DD - Account Name (Company)',
        accessor: 'ddAccountName',
        id: 'ddAccountName',
      },
      {
        Header: 'Addressbooks Sync Status',
        accessor: (row) => {
          if (row.addressbooksSyncDate && !row.addressbooksSyncError) {
            return 'OK';
          }

          if (row.addressbooksSyncError) {
            return row.addressbooksSyncError;
          }

          return '';
        },
        Cell: ({ cell: { value } }) => {
          if (value === 'OK') {
            return (
              <div>
                <TickIcon /> OK
              </div>
            );
          } else if (value && value !== '') {
            return (
              <div className={accountsStyles.addressbookSyncErrorCell}>
                <ErrorIcon errors={[value]} fill="red" /> {value}
              </div>
            );
          }

          return null;
        },
        id: 'addressbooksSyncStatus',
      },
      {
        Header: 'Addressbooks Sync Date',
        accessor: (row) => {
          if (row.addressbooksSyncDate) {
            return `${moment
              .unix(parseInt(row.addressbooksSyncDate))
              .format('YYYY-MM-DD HH:mm:ss')}`;
          }

          return '';
        },
        id: 'addressbooksSyncDate',
      },
      {
        Header: 'Custom Fields Status',
        accessor: (row) => {
          if (
            row.customFieldsLastSynced &&
            row.customFieldsCreationError !== true &&
            row.customFieldsFetchError !== true
          ) {
            return 'OK';
          }

          if (
            row.customFieldsFetchError === true &&
            row.customFieldsCreationError === true
          ) {
            return 'Fetch and Create Error';
          }

          if (row.customFieldsFetchError === true) {
            return 'Fetch Error';
          }

          if (row.customFieldsCreationError === true) {
            return 'Create Error';
          }

          if (row.customFieldsVaultError === true) {
            return 'Vault Error';
          }

          if (row.customFieldsAuthError === true) {
            return 'Auth Error';
          }

          if (row.customFieldsCredentialError === true) {
            return 'No form-builder credential exists for this account';
          }

          return '';
        },
        Cell: ({ cell: { value } }) => {
          if (value === 'OK') {
            return (
              <div>
                <TickIcon /> OK
              </div>
            );
          }

          if (value === 'Fetch and Create Error') {
            return (
              <div className={accountsStyles.addressbookSyncErrorCell}>
                <ErrorIcon
                  errors={['Fetch Error', 'Create Error']}
                  fill="red"
                />{' '}
                Fetch and Create Error
              </div>
            );
          }

          if (value === 'Fetch Error') {
            return (
              <div className={accountsStyles.addressbookSyncErrorCell}>
                <ErrorIcon errors={['Fetch Error']} fill="red" /> Fetch Error
              </div>
            );
          }

          if (value === 'Create Error') {
            return (
              <div className={accountsStyles.addressbookSyncErrorCell}>
                <ErrorIcon errors={['Create Error']} fill="red" /> Create Error
              </div>
            );
          }

          if (value === 'Vault Error') {
            return (
              <div className={accountsStyles.addressbookSyncErrorCell}>
                <ErrorIcon errors={['Vault Error']} fill="red" /> Vault Error
              </div>
            );
          }

          if (value === 'Auth Error') {
            return (
              <div className={accountsStyles.addressbookSyncErrorCell}>
                <ErrorIcon errors={['Auth Error']} fill="red" /> Auth Error
              </div>
            );
          }

          if (value === 'No form-builder credential exists for this account') {
            return (
              <div className={accountsStyles.addressbookSyncErrorCell}>
                <ErrorIcon
                  errors={[
                    'No form-builder credential exists for this account',
                  ]}
                  fill="red"
                />{' '}
                No form-builder credential exists for this account
              </div>
            );
          }

          return null;
        },
        id: 'customFieldsStatus',
      },
      {
        Header: 'Custom Fields Last Sync',
        accessor: (row) => {
          if (row.customFieldsLastSynced) {
            return `${moment
              .unix(parseInt(row.customFieldsLastSynced))
              .format('YYYY-MM-DD HH:mm:ss')}`;
          }

          return '';
        },
        id: 'customFieldsLastSynced',
      },
      {
        Header: 'DD Account Level',
        accessor: 'ddAccountLevel',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'ddAccountLevel',
      },
      {
        Header: 'Label',
        accessor: 'label',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'label',
      },
      {
        Header: 'Regional Groupings (Not Accounts - Reference only)',
        accessor: 'regionalGroupings',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'regionalGroupings',
      },
      {
        Header: 'Territory (Operating Country)',
        accessor: 'territoryOperatingCountry',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'territoryOperatingCountry',
      },
      {
        Header: 'Data Acquisition Processing Method',
        accessor: 'data_acquisition_processing_method',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'data_acquisition_processing_method',
      },
      {
        Header: 'Data Acquisition Selection',
        accessor: 'data_acquisition_selection',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'data_acquisition_selection',
      },
      {
        Header: 'DD Account Type',
        accessor: 'ddAccountType',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'ddAccountType',
      },
      {
        Header: 'B2C/B2B',
        accessor: 'isB2COrB2B',
        Filter: SelectColumnFilter,
        id: 'isB2COrB2B',
      },
      {
        Header: 'Dotdigital Account ID',
        accessor: 'ddAccountId',
        id: 'ddAccountId',
      },
      {
        Header: 'UMG / Partner',
        accessor: 'isUmgOrPartner',
        Filter: SelectColumnFilter,
        id: 'isUmgOrPartner',
      },
      {
        Header: 'Created',
        accessor: (row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        id: 'createdAt',
        hideHeader: true,
      },
      {
        Header: 'Addressbook count',
        accessor: 'addressbooksCount',
        Cell: AddressbookCountCell,
        id: 'addressbook count',
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const tableData = useTable(
    {
      columns,
      data: filteredValues,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      updateTableData,
    } as any,
    useFilters,
    useSortBy,
    usePagination
  ) as any;

  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (createAccount === true) {
    return (
      <React.Fragment>
        <Redirect to="/accounts/new/dotdigital" />
      </React.Fragment>
    );
  }

  // depends on dd account id being unique
  const accountsFiltered = () => {
    const rows = tableData.rows.map((r) => r.values);

    return filteredValues.filter((v: any) => {
      const found = rows.find((r) => {
        return r.ddAccountId === v.ddAccountId;
      });

      if (found) {
        return true;
      }

      return false;
    });
  };

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);
  const toggleFiltersSelect = () => setFiltersOpen(!filtersOpen);

  return (
    <div className={pagesStyles.fullHeight}>
      {values && (
        <div className={`${pagesStyles.noPadding} ${pagesStyles.fullHeight}`}>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <Button
                onClick={(e) => toggleShowActive()}
                className={accountsStyles.button}
              >
                Show {showActive ? 'Inactive' : 'Active'} Accounts
              </Button>
              <Can I="create" an="account">
                <Button
                  onClick={(e) => newAccount()}
                  className={accountsStyles.button}
                >
                  Create New Account
                </Button>
              </Can>
              <Can I="export" an="accounts">
                <ExportToExcel
                  csvData={accountsFiltered()}
                  fileName={'accounts-dotdigital'}
                  user={user}
                />
              </Can>
              <Button
                onClick={toggleColumnsSelect}
                className={accountsStyles.button}
              >
                Columns
              </Button>
              <Button
                onClick={toggleFiltersSelect}
                className={accountsStyles.button}
              >
                Filters
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Collapse isOpen={isColumnsSelectOpen}>
                <ColumnsSelector
                  columns={columns}
                  userCols={userCols}
                  tableName={TABLE_NAME}
                />
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 4 }}>
              <Collapse isOpen={filtersOpen}>
                <DDTableFilters
                  db="dam"
                  path="accounts/dotdigital"
                  title="Find DD Accounts"
                  rowsToExport={tableData.rows.map((row) => row.values) || []}
                  setLoading={setLoading}
                  customQuery={
                    showActive
                      ? {
                          query: 'active',
                          value: true,
                        }
                      : {
                          query: 'active',
                          value: false,
                        }
                  }
                  setFilteredValues={setFilteredValues}
                  filters={[
                    {
                      type: 'search',
                      query: 'dd_account_search',
                      customHandler: false,
                      submitable: true,
                      labels: {
                        placeholder: '',
                      },
                      options: [
                        {
                          id: 'ddAccountName',
                          label: 'DD Account Name',
                          placeholder: 'Search for DD Account Name',
                        },
                        {
                          id: 'key',
                          label: 'DAM key',
                          placeholder: 'Search for DAM key',
                        },
                        {
                          id: 'label',
                          label: 'Label',
                          placeholder: 'Search for an account label',
                        },

                        {
                          id: 'ddAccountId',
                          label: 'DD Account ID',
                          placeholder: 'Search for DD Account ID',
                        },
                        {
                          toggle: true,
                          id: 'isB2COrB2B',
                          label: 'B2B/B2C',
                          value: [
                            { id: 'B2B', label: 'B2B' },
                            { id: 'B2C', label: 'B2C' },
                          ],
                        },
                        {
                          toggle: true,
                          id: 'territoryOperatingCountry',
                          label: 'Country',
                          value: countryList,
                        },
                        {
                          toggle: true,
                          id: 'regionalGroupings',
                          label: 'Regions',
                          value: Object.values(
                            enumerations.enumerations.regionalGroupings
                          ).map((region: any) => {
                            return { id: region.value, label: region.label };
                          }),
                        },
                      ],
                    },
                  ]}
                />
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 12 }} className={pagesStyles.divider}></Col>
          </Row>
          {loading || userColsLoading ? (
            <Loader loading={true} />
          ) : userColsError ? (
            <div>Error loading cols</div>
          ) : (
            <div className={pagesStyles.fullHeight}>
              <Row className={pagesStyles.fullHeight}>
                <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
                  <AccountsTable {...tableData} system="dotdigital" />
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
