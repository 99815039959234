.formRow {
  margin-top: 20px;
}

.button {
  margin-right: 10px;
}

.tagsTable select,
.tagsTable input {
  width: -webkit-fill-available;
  margin-top: 5px;
}

.tagsTable {
  overflow-x: inherit;
}

.tagsTableContainer {
  overflow-x: inherit;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.tagVerify {
  margin-left: 10px;
}

.canopusLoader {
  height: 40px;
  width: 40px;
  padding: 0px;
  margin-left: -23px;
}

.queryRow {
  margin-bottom: 20px;
}

.pagination button {
  margin: 5px;
}
