import { Workflow, WorkflowLifecycleStage } from './types';
import moment from 'moment';
import 'moment-timezone';
import { isLifecycleAtEnd } from '../../../functions/src/triggers/pubsub/workflows/utils';

function nextRunDateTime(cronString: string) {
  const [minute, hour, dayOfMonth, month, dayOfWeek] = cronString.split(' ');
  const now = moment().tz('America/Los_Angeles');
  const nextRun = moment(now);

  // Helper function to check if a value matches the cron field
  function matches(value, cronField) {
    if (cronField === '*') return true;
    if (cronField.includes(','))
      return cronField.split(',').includes(value.toString());
    if (cronField.includes('-')) {
      const [start, end] = cronField.split('-');
      return value >= parseInt(start) && value <= parseInt(end);
    }
    if (cronField.includes('/')) {
      const [, step] = cronField.split('/');
      return value % parseInt(step) === 0;
    }
    return value.toString() === cronField;
  }

  // Find the next matching time
  while (true) {
    if (
      matches(nextRun.minutes(), minute) &&
      matches(nextRun.hours(), hour) &&
      matches(nextRun.date(), dayOfMonth) &&
      matches(nextRun.month() + 1, month) &&
      matches(nextRun.day(), dayOfWeek)
    ) {
      return nextRun.local();
    }
    nextRun.add(1, 'minute');
    nextRun.seconds(0);
    nextRun.milliseconds(0);
  }
}

export function getReadableWorkflowLifecycleStage(workflow: Workflow) {
  const nextRun = nextRunDateTime(workflow.schedule);
  if (!workflow.lifecycle?.stage)
    return `daily; next run: ${nextRun.format('ddd, DD')}`;
  if (isLifecycleAtEnd(workflow.lifecycle)) return `lifecycle ended`;

  let title = '';
  let subtitle = '';
  switch (workflow.lifecycle.stage) {
    case WorkflowLifecycleStage.DAYS:
      title = `daily`;
      subtitle = `next run: ${nextRun.format('ddd, DD')}`;
      break;
    case WorkflowLifecycleStage.WEEKS:
      title = `weekly`;
      subtitle = `next run: ${nextRun.format('ddd, DD MMM')}`;
      break;
    case WorkflowLifecycleStage.MONTHS:
      title = `monthly`;
      subtitle = `next run: ${nextRun.format('ddd, DD MMM yy')}`;
      break;
    default:
      return `invalid lifecycle; next run: ${nextRun.format('ddd, DD')}`;
  }

  if (workflow.status !== 'enabled') subtitle = `workflow ${workflow.status}`;
  return `${title}; ${subtitle}`;
}
