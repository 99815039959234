import * as React from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Table, TabPane } from 'reactstrap';
import { Loader } from '../../../components/Loader';
import { ref } from '../../../utils/firebase';
import { CUSTOM_FIELDS_TAB } from '../AccountForm';

export const CustomFieldsTab = ({ accountId }) => {

  const [customFieldsValues, customFieldsLoading, customFieldsError] = useObjectVal(ref(`accounts/dotdigital/${accountId}/customFields`));

  if (customFieldsLoading === true) {
    return <Loader loading={true} />;
  }

  if (customFieldsError) {
    return <TabPane tabId={CUSTOM_FIELDS_TAB}><React.Fragment>Error</React.Fragment></TabPane>;
  }

  if (!customFieldsValues) {
    return <TabPane tabId={CUSTOM_FIELDS_TAB}><div>No custom fields currently in account</div></TabPane>;
  }

  const rowKeys = Object.keys(customFieldsValues);
  const rows = rowKeys.map((k) => {
    const field = customFieldsValues[k];
    return <tr key={k}><td>{field.name}</td><td>{field.type}</td><td>{field.defaultValue}</td><td>{field.visibility}</td><td>{field.error ? field.error : ''}</td></tr>;
  });

  return <TabPane tabId={CUSTOM_FIELDS_TAB}>
    <Table striped={true} size="sm">
    <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Default Value</th>
        <th>Visibility</th>
        <th>Error</th>
      </tr>
    </thead>
    <tbody>
      {rows}
    </tbody>
  </Table>
  </TabPane>
};