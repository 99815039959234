import * as React from 'react';
import { bool } from 'prop-types';
import { Row, Col } from 'reactstrap';

const loaderStyles = require('./loader.css');

interface LoaderProps {
  loading: boolean;
}

export class Loader extends React.Component<LoaderProps, any, any> {

  static propTypes = {
    loading: bool.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  render() {
    if (this.props.loading) {
      return <React.Fragment><Row className={loaderStyles.loaderRow}>
        <Col sm={4}></Col>
        <Col sm={4} className={loaderStyles.loaderCol}>
          <img src="/images/loading.gif" className="loading" />
        </Col>
        <Col sm={4}></Col>
      </Row></React.Fragment>;
    }

    return null;
  }
}
