import * as React from 'react';
import { useState, useEffect } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import * as firebaseService from '../../services/firebase';
import { Row, Col, Button, Input, Collapse } from 'reactstrap';
import { TagsTable } from './TagsTable';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { Loader } from '../../components/Loader';
import { fuzzyTextFilterFnExp } from './TagsContainer';
import { CrossIcon, TickIcon } from '../../components/Icons';
import moment from 'moment';
import { Can } from '../../auth/Can';
import { SelectColumnFilterHidden } from '../../components/table/SelectColumnfilter';
import { logger } from '../../logging';
import { ColumnsSelector } from '../../components/table/ColumnsSelector';
const R = require('ramda');

const pagesStyles = require('../pages.css');
const tagsStyles = require('./tags.css');
const iconStyles = require('../../components/icons.css');

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

export interface Tag {
  tag_name: string;
  similarity_score: number;
  artist_tag_matched?: string;
  canopus_id?: number;
  crm_status: boolean;
  created: number;
  modified?: number;
}

interface Tags {
  tags: Tag[];
}

export const getCanopus = async (canopusId: number) => {
  const canopusApp = firebaseService.getCanopusApp();
  const canopusRef = canopusApp
    .database()
    .ref('canopus')
    .orderByChild('canopusId')
    .equalTo(`${canopusId}`);
  const canopus = {
    name: '',
    custom: false,
  };

  try {
    const canopusSnap = await canopusRef.once('value');
    canopusSnap.forEach((snap) => {
      // return the first:
      const canopusNameChild = snap.child('canopusName').val();
      const canopusCustomChild = snap.child('custom').val();
      logger.info(
        `Available Canopus Name: ${JSON.stringify(snap.val())}, /canopus key: ${
          snap.key
        }`
      );
      if (typeof canopusNameChild === 'string' && canopusNameChild !== '') {
        logger.info(
          `Setting Canopus Name: ${JSON.stringify(snap.val())}, /canopus key: ${
            snap.key
          }`
        );
        canopus['name'] = canopusNameChild;
        if (
          typeof canopusCustomChild !== 'undefined' &&
          canopusCustomChild === true
        ) {
          canopus['custom'] = true;
        }
      }
    });
  } catch (e) {
    logger.error('getCanopus: error calling firebase:', e);
  }

  return canopus;
};

export const getPartyServiceRecord = async (partyId: string) => {
  const partyServiceRecord = {
    party_name: '',
    canopus_id: null,
  };

  const brandIdAsNumber = parseInt(partyId as string, 10);
  if (isNaN(brandIdAsNumber)) {
    return partyServiceRecord;
  }

  logger.debug(
    `[getPartyServiceRecord] called with brandIdAsNumber: ${brandIdAsNumber} (${typeof brandIdAsNumber})`
  );
  const partyServiceApp = firebaseService.getPartyServiceApp();
  const partyServiceRef = partyServiceApp
    .database()
    .ref('party_id')
    .orderByChild('party_id')
    .equalTo(brandIdAsNumber);

  try {
    const partyServiceSnap = await partyServiceRef.once('value');
    partyServiceSnap.forEach((snap) => {
      // return the first:
      const snapVal = snap.val();
      const partyName = snapVal['party_name'];
      logger.info(
        `Available Party Name: ${JSON.stringify(snap.val())}, /party_id key: ${
          snap.key
        }`
      );
      if (typeof partyName === 'string' && partyName !== '') {
        logger.info(
          `Setting Party Name: ${JSON.stringify(snap.val())}, /party_id key: ${
            snap.key
          }`
        );
        partyServiceRecord['party_name'] = partyName;
        partyServiceRecord['canopus_id'] = snapVal['canopus_id'];
      }
    });
  } catch (e) {
    logger.error('getPartyServiceRecord: error calling firebase:', e);
  }

  return partyServiceRecord;
};

export const Tags = () => {
  const damApp = firebaseService.getDAMApp();
  const TABLE_NAME = 'tags';

  const tagsRef = damApp.database().ref('tags');
  const [tagsList, loading, error] = useObjectVal(tagsRef as any);
  const [values, setValues] = useState([]);
  const [valuesLoading, setValuesLoading] = useState(false);

  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const uid = damApp.auth().currentUser.uid;
  const userColsRef = damApp
    .database()
    .ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  // when tagsList changes
  // and there are no values yet
  // put values into the correct shape + lookup enumeration labels + update state with mapped values
  useEffect(() => {
    if (tagsList) {
      // logger.info(`[Tags] tagsList: ${JSON.stringify(tagsList).substring(0, 100)}`);
    }

    if (tagsList === null) {
      setValuesLoading(true);
      setValues([]);
    }

    if (values.length === 0 && tagsList) {
      const tags = Object.keys(tagsList).filter((k) => {
        return tagsList[k];
      });

      setValuesLoading(true);
      setValues(
        tags.map((k) => {
          const value = tagsList[k];
          const labels = { ...value };
          return { ...labels, key: k };
        })
      );
    }
  }, [tagsList]); // when tagsList

  useEffect(() => {
    setValuesLoading(false);
  }, [values]);

  const updateMyData = async (rowIndex, columnId, value, updateTableData) => {
    logger.debug(
      `rowIndex: ${rowIndex}, columnId: ${columnId}, value: ${value}`
    );
    const tagRef = damApp.database().ref(`tags/${values[rowIndex].key}`);
    const tag = {};
    const now = moment().unix();
    tag[columnId] = value;
    tag['modified'] = now;
    try {
      await tagRef.update(tag);
    } catch (e) {
      logger.error('[updateMyData] - error updating tag row: ', e);
    }

    if (updateTableData) {
      setValues((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
              ['modified']: now,
            };
          }
          return row;
        })
      );
    }
  };

  const EditableCanopusCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [loading, setLoading] = React.useState(false);
    const hasCanopusName =
      original.artist_tag_matched !== '' &&
      original.artist_tag_matched !== undefined;

    const onChange = async (e) => {
      const canopusId = e.target.value;
      const re = /^\d+$/g;
      if (!re.test(canopusId) && canopusId !== '') {
        return;
      }

      setLoading(true);
      setValue(canopusId);
      await lookupCanopus(canopusId);
      setLoading(false);
    };

    const verifiedcanopus = original.crm_status;

    const lookupCanopus = async (canopusId) => {
      await updateMyData(index, id, canopusId, true);
      await updateMyData(index, 'artist_tag_matched', '', true);
      await updateMyData(index, 'custom_canopus', false, true);
      const canopus = await getCanopus(canopusId);
      await updateMyData(index, 'artist_tag_matched', canopus.name, true);
      await updateMyData(index, 'custom_canopus', canopus.custom, true);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {!verifiedcanopus && (
          <React.Fragment>
            <Row>
              <Col sm={10}>
                <Input type="text" value={value} onChange={onChange} />
              </Col>
              <Col sm={2}>
                {loading && (
                  <img
                    src="/images/loading.gif"
                    className={tagsStyles.canopusLoader}
                  />
                )}
                {!loading && hasCanopusName && (
                  <TickIcon fill="black" class={iconStyles.tick} />
                )}
                {!loading && !hasCanopusName && (
                  <CrossIcon fill="black" class={iconStyles.cross} />
                )}
              </Col>
            </Row>
          </React.Fragment>
        )}
        {verifiedcanopus && `${value}`}
      </React.Fragment>
    );
  };

  const EditableCRMStatus = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    cellInfo,
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const hasCanopusName =
      original.artist_tag_matched !== '' &&
      original.artist_tag_matched !== undefined;

    const handleClick = async (verify: boolean) => {
      setValue(verify);
      await updateMyData(index, 'crm_status', verify, true);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const canopusId = original.canopus_id;

    if (value === true) {
      return (
        <React.Fragment>
          true
          {
            <Button
              className={tagsStyles.tagVerify}
              onClick={() => {
                handleClick(false);
              }}
            >
              Set to false
            </Button>
          }
        </React.Fragment>
      );
    }

    const canopusIsNumber = /^\d+$/.test(canopusId);
    const hascanopus = !isNilOrEmpty(canopusId) && canopusIsNumber === true;

    return (
      <React.Fragment>
        false
        {hascanopus && hasCanopusName && (
          <Button
            className={tagsStyles.tagVerify}
            onClick={() => {
              handleClick(true);
            }}
          >
            Set to true
          </Button>
        )}
      </React.Fragment>
    );
  };

  const EditableHiddenStatus = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    cellInfo,
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue);

    const handleClick = async (hide: boolean) => {
      setValue(hide);
      await updateMyData(index, 'hidden', hide, true);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (value === true) {
      return (
        <React.Fragment>
          {
            <Button
              className={tagsStyles.tagVerify}
              onClick={() => {
                handleClick(false);
              }}
            >
              Display
            </Button>
          }
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Button
          className={tagsStyles.tagVerify}
          onClick={() => {
            handleClick(true);
          }}
        >
          Hide
        </Button>
      </React.Fragment>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Key',
        accessor: 'key',
        id: 'key',
      },
      {
        Header: 'Tag Name',
        accessor: 'tag_name',
        id: 'tag_name',
      },
      {
        Header: 'Canopus ID',
        accessor: 'canopus_id',
        Cell: EditableCanopusCell,
        id: 'canopus_id',
      },
      {
        Header: 'Custom Canopus',
        accessor: (row) => {
          if (row.custom_canopus === true) {
            return 'true';
          }

          return 'false';
        },
        id: 'custom_canopus',
      },
      {
        Header: 'Artist Tag Matched',
        accessor: 'artist_tag_matched',
        id: 'artist_tag_matched',
      },
      {
        Header: 'CRM Status',
        accessor: 'crm_status',
        Cell: EditableCRMStatus,
        id: 'crm_status',
      },
      {
        Header: 'Similarity Score',
        accessor: 'similarity_score',
        id: 'similarity_score',
      },
      {
        Header: 'Created',
        accessor: (row) => {
          if (row.created) {
            return moment
              .unix(parseInt(row.created))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'created',
      },
      {
        Header: 'Modified',
        accessor: (row) => {
          if (row.modified) {
            return moment
              .unix(parseInt(row.modified))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'modified',
      },
      {
        Header: 'Hidden',
        accessor: 'hidden',
        Filter: SelectColumnFilterHidden,
        filter: 'equals',
        Cell: EditableHiddenStatus,
        id: 'hidden',
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'tag_name',
            desc: false,
          },
        ],
        filters: [
          {
            id: 'hidden',
            value: false,
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      updateMyData,
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (loading || valuesLoading || userColsLoading) {
    return <Loader loading={true} />;
  }

  if (error) {
    return <div>{`Error: ${error.message}`}</div>;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);

  return (
    <React.Fragment>
      {values && (
        <div className={pagesStyles.noPadding}>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <Button onClick={toggleColumnsSelect}>Columns</Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Collapse isOpen={isColumnsSelectOpen}>
                <ColumnsSelector
                  columns={columns}
                  userCols={userCols}
                  tableName={TABLE_NAME}
                />
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 12 }}>
              <Can I="manage" a="tag">
                <TagsTable {...tableData} />
              </Can>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};
