.formRow {
  margin-top: 20px;
}

.fullHeight {
  height: 100%;
}

.fullHeightTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fitContentTableContainer {
  width: fit-content;
  flex: 1;
  overflow-y: auto;
}

.fitContentTableContainer {
  width: fit-content;
}

.button {
  margin-right: 10px;
}

.tagsTable select,
.tagsTable input {
  width: 100px;
  margin-top: 5px;
}

.tagsTable {
  overflow-x: inherit;
}

.tagsTableContainer {
  overflow-x: inherit;
}

.communityTableHeader {
  vertical-align: top !important;
}

.communityTable {
  border-collapse: separate;
  border-spacing: 0;
}

.communityTable th {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  z-index: 1;
}

.communityTable td {
  border-right: 1px solid #dee2e6;
}

.communityTable th:first-child,
.communityTable td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid #dee2e6;
}

.communityTable tr:last-child td {
  /* Apply a left border on the first <td> or <th> in a row */
  border-bottom: 1px solid #dee2e6;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.queryRow {
  margin-bottom: 20px;
}

.pagination button {
  margin: 5px;
}

.columnsButton {
  margin: 15px 0;
}

.validationError {
  min-width: 170px;
}
