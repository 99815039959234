import * as React from 'react';
import { Table, Button } from 'reactstrap';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { DownArrow, UpArrow } from '../../components/Icons';

const accountsStyles = require('./accounts.css');
const iconStyles = require('../../components/icons.css');

export const AccountsTable = ({
  preGlobalFilteredRows,
  state,
  setGlobalFilter,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  system,
  pageOptions,
  page,
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  canPreviousPage,
  canNextPage,
}) => {
  const filters = state.filters;
  const pageIndex = state.pageIndex;
  const pageSize = state.pageSize;

  const [selectedAccount, setSelectedAccount] = useState('');

  const onAccountRowClicked = (row) => {
    setSelectedAccount(row.original.key);
  };

  return (
    <div className={accountsStyles.fullHeight}>
      {selectedAccount !== '' && (
        <Redirect to={`/accounts/${system}/${selectedAccount}`} />
      )}
      {selectedAccount === '' && (
        <div className={accountsStyles.fullHeightTableContainer}>
          <div className={accountsStyles.fitContentTableContainer}>
            <Table
              {...getTableProps()}
              striped
              className={accountsStyles.accountsTable}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                        <Button
                          className={accountsStyles.sortButton}
                          size="sm"
                          color="link"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.isSorted ? 'sorted ' : 'sort'}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <DownArrow
                                  fill="#007bff"
                                  class={iconStyles.sortButton}
                                />
                              ) : (
                                <UpArrow
                                  fill="#007bff"
                                  class={iconStyles.sortButton}
                                />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </Button>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={(e) => onAccountRowClicked(row)}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className={accountsStyles.pagination}>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous Page
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next Page
            </Button>
            <div>
              Page{' '}
              <em>
                {pageIndex + 1} of {pageOptions.length}
              </em>
            </div>
          </div>
          Total Rows: {rows.length}
        </div>
      )}
    </div>
  );
};
