import * as React from 'react'
import { useList } from 'react-firebase-hooks/database';
import { useState } from 'react';
import { ref } from '../../utils/firebase';
import { arrayToOptions } from '../../utils/react';
import { Row, Col, Button, Table, Input, FormGroup, Alert } from 'reactstrap';
import { PlusIcon } from '../../components/Icons';
import { logger } from '../../logging';
import { Loader } from '../../components/Loader';

const pagesStyles = require('../pages.css');
const rolesStyles = require('./roles.css');
const iconStyles = require('../../components/icons.css');

const RoleRow = ({ refKey, val }) => {
  const [name, setName] = useState(val.name);
  const [description, setDescription] = useState(val.description);
  const roleRef = ref(`roles/${refKey}`);

  const updateRole = async () => {
    try {
      await roleRef.update({ ...val, name, description });
    } catch (e) {
      logger.error(e);
    }
  }

  const deleteRole = async () => {
    try {
      await roleRef.remove();
    } catch (e) {
      logger.error(e);
    }
  }

  return <tr>
    <td>{val.roleKey}</td>
    <td><Input name="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} /></td>
    <td><Input name="description" type="textarea" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} /></td>
    <td>
      <Button onClick={(e) => updateRole()} className={rolesStyles.button}>Update</Button>
      <Button onClick={(e) => deleteRole()} className={rolesStyles.button}>Delete</Button>
    </td>
  </tr>
}

const CreateRoleRow = ({ setValidationError }) => {
  const [roleKey, setRoleKey] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [disabled, setDisabled] = useState(true);

  const reset = () => {
    setRoleKey("");
    setName("");
    setDescription("");
    setDisabled(true);
  }

  const createRole = async () => {
    try {
      const roleRef = ref(`roles/${roleKey}`);

      // check for existing key first
      const existingKey = await roleRef.once('value');
      if (existingKey.exists()) {
        logger.info(`key already exists: roles/${roleKey}`);
        setValidationError('Duplicate keys are not allowed, please use a different key');
        reset();
        return;
      }

      const val = { roleKey, name, description };
      await roleRef.update(val);
      reset();
    } catch (e) {
      logger.error(e);
      reset();
    }
  }

  const handleNameChanged = (e) => {
    const val = e.target.value;
    setName(val);
    setDisabled(true);
    setValidationError('');
    if (val !== '' && roleKey !== '' && description !== '') {
      setDisabled(false);
    }
  }

  const handleKeyChanged = (e) => {
    const val = e.target.value;
    setRoleKey(val);
    setDisabled(true);
    setValidationError('');
    if (val !== '' && name !== '' && description !== '') {
      setDisabled(false);
    }
  }

  const handleDescriptionChanged = (e) => {
    const val = e.target.value;
    setDescription(val);
    setDisabled(true);
    setValidationError('');
    if (val !== '' && roleKey !== '' && name !== '') {
      setDisabled(false);
    }
  }

  return <tr>
    <td><Input name="roleKey" placeholder="roleKey" value={roleKey} onChange={handleKeyChanged} /></td>
    <td><Input name="name" placeholder="Name" value={name} onChange={handleNameChanged} /></td>
    <td><Input name="description" type="textarea" placeholder="Description" value={description} onChange={handleDescriptionChanged} /></td>
    <td><Button onClick={(e) => createRole()} disabled={disabled}>
      <PlusIcon fill="white" class={iconStyles.addButton} />
    </Button></td>
  </tr>
}

export const Roles = () => {
  const [roleValues, roleLoading, roleError] = useList(ref('roles'));
  const [validationError, setValidationError] = useState('');

  if (roleLoading) {
    return <Loader loading={true} />;
  }

  if (roleError) {
    return <React.Fragment>Error</React.Fragment>;
  }

  return <div className={pagesStyles.container}>
    <Row className={pagesStyles.headerRow}>
      <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
        <h3>Roles</h3>
      </Col>
    </Row>
    {validationError && validationError !== '' && <Row>
      <Col sm={{ size: 12 }}>
        <Alert color="danger">
          {validationError}
        </Alert>
      </Col>
    </Row>}
    <Row>
      <Col sm={{ size: 12 }}>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Key</th>
              <th>Name</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <CreateRoleRow setValidationError={setValidationError} />
            {roleValues && (
              roleValues.map((c) => <RoleRow refKey={c.key} val={c.val()} key={c.key} />)
            )}
          </tbody>
        </Table>
      </Col>
    </Row>

  </div>
}