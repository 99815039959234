import * as React from 'react';
import {
  Table,
  Input,
  Button,
  Collapse,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { ref } from '../../utils/firebase';
import { useState, useContext } from 'react';
import { AbilityContext } from '../../auth/Can';
import { useListVals, useList } from 'react-firebase-hooks/database';
import { arrayToOptions } from '../../utils/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import { logger } from '../../logging';
import { getDAMApp } from '../../services/firebase';

const accountStyles = require('./account.css');

const CredentialsRow = ({
  system,
  credential,
  accountId,
  reload,
  setReload,
}) => {
  const damApp = getDAMApp();
  const [credentialTypes] = useListVals(
    ref('enumerations/credentialTypes') as any
  );
  const [credentialsAuditResult, credentialsAuditLoading] = useList(
    ref(`credentials_audit/${credential.key}`) as any
  );
  let credentialsAudit = [];
  if (!credentialsAuditLoading && credentialsAuditResult) {
    credentialsAudit = credentialsAuditResult.map((snap) => {
      return { ...snap.val(), key: snap.key };
    });
  }

  const [name, setName] = useState(credential.name);
  const [username, setUsername] = useState(credential.username);
  const [password, setPassword] = useState(credential.password);
  const [webhookSecret, setWebhookSecret] = useState(credential.webhook_secret);
  const [appName, setAppName] = useState(credential.app_name);
  const [apiKey, setApiKey] = useState(credential.api_key);
  const [apiToken, setApiToken] = useState(credential.api_token);
  const [apiTokenLabel, setApiTokenLabel] = useState(
    credential.api_token_label
  );
  const [apiTokenCreatedDate, setApiTokenCreatedDate] = useState(
    credential.api_token_created_date
  );
  const ability = useContext(AbilityContext);

  const [user, userLoading] = useAuthState(damApp.auth() as any);

  const [isOpen, setIsOpen] = useState(false);
  const [restoring, setRestoring] = useState(false);

  const restoreCredentials = async () => {
    setRestoring(true);
    try {
      if (user && !userLoading) {
        const callable = damApp
          .functions()
          .httpsCallable('accountManager-activateCredential');
        await callable({ system, id: credential.key, accountId, name });

        setRestoring(true);
        setReload(!reload);
      } else {
        setRestoring(true);
        logger.info('Restore failed - unable to obtain user');
      }
    } catch (e) {
      setRestoring(false);
      logger.error(e);
    }
  };

  const toggleAudit = () => setIsOpen(!isOpen);

  return (
    <React.Fragment>
      <tr>
        <td>
          <Input
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="select"
            readOnly={true}
            disabled={true}
          >
            {arrayToOptions(credentialTypes)}
          </Input>
        </td>
        {system === 'attentive' && (
          <td>
            <Input
              name="appName"
              placeholder="App Name"
              value={appName}
              readOnly={true}
              disabled={true}
            />
          </td>
        )}
        {system === 'attentive' ||
          (system === 'treasure_data' && (
            <td>
              <Input
                name="apiKey"
                placeholder="Api Key"
                value={apiKey}
                readOnly={true}
                disabled={true}
              />
            </td>
          ))}
        {system === 'community' && (
          <td>
            <Input
              name="apiTokenLabel"
              placeholder="Api Access Token Label"
              value={apiTokenLabel}
              readOnly={true}
              disabled={true}
            />
          </td>
        )}
        {system === 'community' && (
          <td>
            <Input
              name="apiToken"
              placeholder="Api Access Token"
              value={apiToken}
              readOnly={true}
              disabled={true}
            />
          </td>
        )}
        {system === 'community' && (
          <td>
            <Input
              type="date"
              name="apiTokenCreatedDate"
              value={moment.unix(apiTokenCreatedDate).format('YYYY-MM-DD')}
              readOnly={true}
              disabled={true}
            />
          </td>
        )}
        {system !== 'treasure_data' && system !== 'community' && (
          <td>
            <Input
              name="username"
              placeholder="API User Name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              readOnly={true}
            />
          </td>
        )}
        {system !== 'treasure_data' && system !== 'community' && (
          <td>
            <Input
              name="password"
              placeholder="API Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              readOnly={true}
            />
          </td>
        )}
        {system === 'slicktext' || system === 'attentive' ? (
          <td>
            <Input
              name="webhookSecret"
              placeholder="Webhook Key"
              value={webhookSecret}
              readOnly={true}
              disabled={true}
            />
          </td>
        ) : null}
        <td>
          <Button
            onClick={(e) => toggleAudit()}
            className={accountStyles.actionButton}
          >
            {isOpen === false ? 'Show' : 'Hide'} History
          </Button>
          <Button
            disabled={ability.cannot('delete', 'credential') || restoring}
            onClick={(e) => restoreCredentials()}
            className={accountStyles.actionButton}
          >
            Restore
          </Button>
          {restoring && (
            <img
              src="/images/loading.gif"
              className={accountStyles.createLoading}
            />
          )}
        </td>
      </tr>
      <tr>
        <td colSpan={4} className={accountStyles.credentialsAuditRow}>
          <Collapse isOpen={isOpen}>
            {credentialsAudit && !credentialsAuditLoading && (
              <ListGroup className={accountStyles.credentialsAuditGroup}>
                {credentialsAudit.map((c) => (
                  <CredentialsAuditRow key={c.key} credentialAudit={c} />
                ))}
              </ListGroup>
            )}
          </Collapse>
        </td>
      </tr>
    </React.Fragment>
  );
};

const CredentialsAuditRow = ({ credentialAudit }) => {
  return (
    <ListGroupItem>
      Action: {credentialAudit.action} | User: {credentialAudit.email} | Date:{' '}
      {credentialAudit.date}
    </ListGroupItem>
  );
};

export const ArchivedCredentials = ({
  system,
  account,
  credentials,
  reload,
  setReload,
}) => {
  let filteredCredentials = [];
  if (credentials.length > 0) {
    filteredCredentials = credentials.filter((c) => c.active === false);
  }

  return (
    <div>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Name</th>
            {system === 'attentive' && <th>App Name</th>}
            {(system === 'attentive' || system === 'treasure_data') && (
              <th>API Key</th>
            )}
            {system === 'community' && <th>Api Access Token Label</th>}
            {system === 'community' && <th>Api Access Token</th>}
            {system !== 'treasure_data' && system !== 'community' && (
              <th>Username</th>
            )}
            {system !== 'treasure_data' && system !== 'community' && (
              <th>Password</th>
            )}
            {system === 'slicktext' ||
              (system === 'attentive' && <th>Webhook key</th>)}
          </tr>
        </thead>
        <tbody>
          {filteredCredentials.map((c) => (
            <CredentialsRow
              system={system}
              key={c.key}
              credential={c}
              accountId={account.key}
              setReload={setReload}
              reload={reload}
            />
          ))}
        </tbody>
      </Table>
      {reload && <div></div>}
    </div>
  );
};
