import * as React from 'react';
import { useState } from 'react';
import { TabPane, Row, Col, Form, Button, Input, Table } from 'reactstrap';
import { SHORT_LONG_CODES_TAB } from '../AccountForm';
import { Loader } from '../../../components/Loader';
import { MinusIcon, PlusIcon } from '../../../components/Icons';
import * as R from 'ramda';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import { logger } from '../../../logging';
import { getDAMApp } from '../../../services/firebase';

const iconStyles = require('../../../components/icons.css');
const accountStyles = require('../account.css');

export const ShortLongCodesTab = ({ account, system }) => {
  const damApp = getDAMApp();
  const accountRef = damApp.database().ref(`accounts/${system}/${account.id}`);
  const codes = account.shortLongCodes || [];
  const defaultShortLongCode = account.defaultShortLongCode || '';
  const [accountCodes, setAccountCodes] = useState(codes);
  const [newCode, setNewCode] = useState('');
  const [defaultCode, setDefaultCode] = useState(defaultShortLongCode);
  const [saving, setSaving] = useState(false);
  const [user, userLoading] = useAuthState(damApp.auth() as any);
  const disableAddButton =
    accountCodes.indexOf(newCode) !== -1 || newCode === '';
  const canSave = true;

  if (saving) {
    return (
      <TabPane tabId={SHORT_LONG_CODES_TAB}>
        <Loader loading={true} />
      </TabPane>
    );
  }

  const addCode = async () => {
    const newCodes = R.append(newCode, accountCodes);
    setAccountCodes(newCodes);
    setNewCode('');
    if (newCodes.length === 1) {
      setDefaultCode(newCodes[0]);
    }
  };

  const removeCode = async (c) => {
    const newCodes = R.without([c], accountCodes);
    setAccountCodes(newCodes);
    if (newCodes.length === 1) {
      setDefaultCode(newCodes[0]);
    }

    if (newCodes.length === 0) {
      setDefaultCode('');
    }

    if (newCodes.length > 1 && c === defaultCode) {
      setDefaultCode(newCodes[0]);
    }
  };

  const setDefault = async (c) => {
    logger.info(`default: ${c}`);
    setDefaultCode(c);
  };

  const save = async () => {
    setSaving(true);
    const account = {
      defaultShortLongCode: defaultCode,
      shortLongCodes: accountCodes,
      modified: `${moment().unix()}`,
      lastModifiedBy: { uid: user.uid, email: user.email },
    };
    try {
      await accountRef.update(account);
      setSaving(false);
    } catch (e) {
      logger.error(e);
      setSaving(false);
    }
  };

  const codeRows = accountCodes.map((c) => {
    const isDefault = c === defaultCode;
    return (
      <tr key={c}>
        <td>{c}</td>
        <td>
          {isDefault && 'Default'}
          {!isDefault && (
            <Button onClick={(e) => setDefault(c)}>Set Default</Button>
          )}
        </td>
        <td>
          <Button onClick={(e) => removeCode(c)}>
            <MinusIcon fill="white" class={iconStyles.minusButton} />
          </Button>
        </td>
      </tr>
    );
  });

  const hasCodes = accountCodes.length > 0;

  return (
    <TabPane tabId={SHORT_LONG_CODES_TAB}>
      <Row>
        <Col sm="12">
          <Row>
            <Col>Short / Long Codes</Col>
          </Row>
          <Row>
            <Col>
              <Input
                type="text"
                value={newCode}
                onChange={(e) => setNewCode(e.target.value)}
              />
            </Col>
            <Col>
              <Button onClick={() => addCode()} disabled={disableAddButton}>
                <PlusIcon fill="white" class={iconStyles.addButton} />
              </Button>
            </Col>
          </Row>
          {hasCodes && (
            <Table striped responsive className={accountStyles.codesTable}>
              <thead>
                <tr>
                  <th>Short/Long Code</th>
                  <th>Default</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>{codeRows}</tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Button
        disabled={!canSave}
        color={canSave ? 'primary' : 'secondary'}
        className={accountStyles.codesSave}
        onClick={save}
      >
        Save
      </Button>
    </TabPane>
  );
};
