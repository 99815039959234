import * as React from 'react';
import { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { logger } from '../logging';
import * as firebaseService from '../services/firebase';

export const IFBWorkflowFormSelector = ({ formId, setFormId, disabled }) => {
  const ifbApp = firebaseService.getIFBApp();

  const [options, setOptions] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const FORMSREF = 'forms';

  React.useEffect(() => {
    let isMounted = true;
    if (formId !== null) {
      setIsLoading(true);
      const formsRef = ifbApp
        .database()
        .ref(FORMSREF)
        .orderByChild('id')
        .equalTo(formId);
      formsRef.once('value', (snap) => {
        const forms = [];
        snap.forEach((s) => {
          const val = s.val();
          forms.push(val);
        });

        if (isMounted) {
          setSelectedForm(forms);
          setIsLoading(false);
        }
      });
    }

    if (formId === null) {
      setSelectedForm([]);
    }

    return () => {
      isMounted = false;
    };
  }, [formId]);

  const handleSearch = async (query) => {
    setIsLoading(true);
    setSelectedForm([]);

    const queryLower = query.toLowerCase();
    const forms = [];
    const formsRef = ifbApp
      .database()
      .ref(FORMSREF)
      .orderByChild('formType')
      .equalTo('workflow');
    const formsSnap = await formsRef.once('value');
    formsSnap.forEach((snap) => {
      const val = snap.val();
      logger.debug(`form: val.title: ${val.title}`);
      const titleLower = val.title.toLowerCase();
      if (titleLower.includes(queryLower)) {
        forms.push({
          id: val.id,
          title: val.title,
          dotmailer: { accountName: val.dotmailer.accountName },
          country: val.country,
        });
      }
    });

    setOptions(forms);
    setIsLoading(false);
  };

  const handleChange = (e) => {
    if (e && e[0] && e[0].id) {
      setSelectedForm(e);
      setFormId(e[0].id);
    }
  };

  return (
    <React.Fragment>
      <AsyncTypeahead
        isLoading={isLoading}
        id="ifb-form-selector"
        useCache={false}
        labelKey={(form) =>
          `${form.title} - ${form.dotmailer.accountName} [${form.country}]`
        }
        minLength={1}
        onSearch={handleSearch}
        onChange={handleChange}
        placeholder={'Find IFB Workflow Form'}
        selected={selectedForm || []}
        options={options}
        disabled={disabled}
      />
    </React.Fragment>
  );
};
