.removeButton {
  float: right;
  padding: 0 6px;
}

.fieldItem {
  padding-left: 15px !important;
}

.center {
  text-align: center;
}

.fullHeight {
  height: 100%;
}

.fullHeightTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fitContentTableContainer {
  flex: 1;
  overflow-y: auto;
}

.customFieldsTable {
  border-collapse: separate;
  border-spacing: 0;
}

.customFieldsTable th {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  z-index: 1;
}

.customFieldsTable td {
  border-right: 1px solid #dee2e6;
}

.customFieldsTable th:first-child,
.customFieldsTable td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid #dee2e6;
}

.customFieldsTable tr:last-child td {
  /* Apply a left border on the first <td> or <th> in a row */
  border-bottom: 1px solid #dee2e6;
}

.customFieldsTable td input {
  position: relative;
}
