import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Table,
} from 'reactstrap';
import * as firebaseService from '../../../services/firebase';
import { logger } from '../../../logging';
import moment from 'moment';
const R = require('ramda');

const slicktextStyles = require('./slicktext.css');

export const TextwordMappingModal = ({
  textword,
  mappingModalIsOpen,
  setMappingModalIsOpen,
  setMappingTextword,
  tableDataUpdated,
  mappingTextwordIndex,
}) => {
  const damApp = firebaseService.getDAMApp();
  const ifbApp = firebaseService.getIFBApp();

  const [saveIsDisabled, setSaveIsDisabled] = useState(true);
  const [mappingDDAccountId, setMappingDDAccountId] = useState(
    textword.mappingDDAccountId || ''
  );
  const [mappingDDAddressbookId, setMappingDDAddressbookId] = useState(
    textword.mappingDDAddressbookId || ''
  );
  const [availableAccountsSorted, setAvailableAccountsSorted] = useState([]);
  const [availableAccounts, setAvailableAccounts] = useState({});
  const [availableAddressbooksSorted, setAvailableAddressbooksSorted] =
    useState([]);
  const [availableAddressbooks, setAvailableAddressbooks] = useState({});

  const accountOptions = R.map((account) => {
    return (
      <option value={account.ddAccountId} key={account.ddAccountId}>
        {account.ddAccountName}
      </option>
    );
  }, availableAccountsSorted);

  const addressbookOptions = R.map((addressbook) => {
    return (
      <option value={addressbook.id} key={addressbook.id}>
        {addressbook.name}
      </option>
    );
  }, availableAddressbooksSorted);

  // logger.debug(`[TextwordMappingModal] textword.mappingDDAccountId: ${textword.mappingDDAccountId}, ${mappingDDAccountId}, ${mappingTextwordIndex}`)

  useEffect(() => {
    setMappingDDAccountId(textword.mappingDDAccountId);
    setMappingDDAddressbookId(textword.mappingDDAddressbookId);
    getAvailableAccounts();
  }, [textword]);

  useEffect(() => {
    if (mappingDDAccountId !== '') {
      getAvailableAddressbooks();
    }
  }, [mappingDDAccountId]);

  const getAvailableAddressbooks = async () => {
    // logger.debug('getAvailableAddressbooks called')
    if (typeof mappingDDAccountId === 'undefined') {
      return;
    }

    try {
      const addressbooksRef = ifbApp
        .database()
        .ref('addressbooks')
        .orderByChild('accountId')
        .equalTo(parseInt(mappingDDAccountId, 10));
      const addressbooksSnap = await addressbooksRef.once('value');
      const addressbooks = {};
      addressbooksSnap.forEach((v) => {
        const addressbook = v.val();
        if (
          addressbook.party_id === textword.party_id &&
          addressbook.dataCapture === true &&
          addressbook.deletedFromDD !== true &&
          addressbook.nonConsented !== true
        ) {
          addressbooks[addressbook.id] = {
            accountId: addressbook.accountId,
            accountName: addressbook.accountName,
            party_id: addressbook.party_id,
            country: addressbook.country,
            id: addressbook.id,
            name: addressbook.name,
            key: v.key,
          };
        }
      });

      const sortedAddressbooks = R.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }, R.values(addressbooks));

      setAvailableAddressbooksSorted(sortedAddressbooks);
      setAvailableAddressbooks(addressbooks);
      // logger.debug(`setAvailableAddressbooks: ${JSON.stringify(addressbooks)}`);
    } catch (e) {
      logger.error(`getAvailableAddressbooks: ${e}`);
      setAvailableAddressbooksSorted([]);
      setAvailableAddressbooks({});
    }
  };

  const isNumeric = (value) => {
    return /^\d+$/.test(value);
  };

  const getAvailableAccounts = async () => {
    // logger.debug(`getAvailableAccounts() called, with textword: ${JSON.stringify(textword)}`);
    if (
      typeof textword.country === 'undefined' ||
      typeof textword.party_id === 'undefined'
    ) {
      return;
    }

    try {
      // 1) get list of account ids where addressbooks have party_id set to textword party_id
      const addressbooksRef = ifbApp
        .database()
        .ref('addressbooks')
        .orderByChild('party_id')
        .equalTo(textword.party_id);
      const addressbooksSnap = await addressbooksRef.once('value');
      const accountsWithPartId = {};
      addressbooksSnap.forEach((v) => {
        const addressbook = v.val();
        // logger.debug(`addressbook: ${JSON.stringify(addressbook)}`);
        if (
          addressbook.country.toLowerCase() ===
            textword.country.toLowerCase() &&
          addressbook.dataCapture === true &&
          addressbook.deletedFromDD !== true &&
          addressbook.nonConsented !== true
        ) {
          accountsWithPartId[addressbook.accountId] = {
            key: v.key,
          };
        }
      });

      // 2) get accounts for textword country
      const accountsRef = damApp
        .database()
        .ref(`accounts/dotdigital`)
        .orderByChild('territoryOperatingCountry')
        .equalTo(textword.country.toLowerCase());
      const accountsSnap = await accountsRef.once('value');
      const accounts = {};
      accountsSnap.forEach((v) => {
        const account = v.val();
        // logger.debug(`availableAccounts: ${JSON.stringify(account)}, ${isNumeric(account.party_id)}`);
        if (
          account.ddAccountType !== 'marketing_siloed' &&
          account.active === true &&
          R.contains(account.ddAccountId, Object.keys(accountsWithPartId))
        ) {
          accounts[account.ddAccountId] = {
            party_id: account.party_id,
            party_name: account.party_name,
            ddAccountId: account.ddAccountId,
            ddAccountName: account.ddAccountName,
            ddAccountLevel: account.ddAccountLevel,
            ddAccountType: account.ddAccountType,
            key: v.key,
          };
        }
      });

      const sortedAccounts = R.sort((a, b) => {
        if (a.ddAccountName > b.ddAccountName) {
          return 1;
        }
        if (a.ddAccountName < b.ddAccountName) {
          return -1;
        }
        return 0;
      }, R.values(accounts));

      setAvailableAccountsSorted(sortedAccounts);
      setAvailableAccounts(accounts);
      // logger.debug(`availableAccounts: ${JSON.stringify(sortedAccounts)}`);
    } catch (e) {
      logger.error(`getAvailableAccounts: ${e}`);
      setAvailableAccountsSorted([]);
      setAvailableAccounts({});
    }
  };

  const saveTextwordMapping = async () => {
    // logger.debug(`saveTextwordMapping called for textword: ${textword.key}`);
    const now = moment().unix();
    const textWordRef = damApp.database().ref(`textwords/${textword.key}`);
    const mappingDDAccountIdInt = parseInt(mappingDDAccountId, 10);
    const mappingDDAddressbookIdInt = parseInt(mappingDDAddressbookId, 10);
    const textwordToUpdate = {
      ...textword,
      mappingDDAccountId: mappingDDAccountIdInt,
      mappingDDAddressbookId: mappingDDAddressbookIdInt,
      addressbookMappingValid: true,
      mapped_senttobq: 'true_false',
      modified: now,
    };

    try {
      await textWordRef.update(textwordToUpdate);
      // logger.debug(`updated textword with: ${JSON.stringify(textwordToUpdate)}`);
      tableDataUpdated(
        mappingTextwordIndex,
        'mappingDDAccountId',
        mappingDDAccountId,
        now
      );
      tableDataUpdated(
        mappingTextwordIndex,
        'mappingDDAddressbookId',
        mappingDDAddressbookId,
        now
      );
      tableDataUpdated(
        mappingTextwordIndex,
        'addressbookMappingValid',
        textwordToUpdate.addressbookMappingValid,
        now
      );
      setMappingTextword({});
      setMappingModalIsOpen(false);
    } catch (e) {
      logger.error('[updateMyData] - error updating textword: ', e);
      setMappingTextword({});
      setMappingModalIsOpen(false);
    }
  };

  const accountIdChanged = (e) => {
    setSaveIsDisabled(true);
    setMappingDDAccountId(e.target.value);
    setMappingDDAddressbookId('');
    if (e.target.value !== '' && mappingDDAddressbookId !== '') {
      setSaveIsDisabled(false);
    }
  };

  const addressbookIdChanged = (e) => {
    setSaveIsDisabled(true);
    setMappingDDAddressbookId(e.target.value);
    if (mappingDDAccountId !== '' && e.target.value !== '') {
      setSaveIsDisabled(false);
    }
  };

  const handleClose = () => {
    setMappingTextword({});
    setMappingModalIsOpen(false);
    setSaveIsDisabled(true);
    setAvailableAccountsSorted([]);
    setAvailableAccounts({});
    setAvailableAddressbooksSorted([]);
    setAvailableAddressbooks({});
  };

  return (
    <Modal
      isOpen={mappingModalIsOpen}
      toggle={handleClose}
      className={slicktextStyles.mappingModal}
    >
      <ModalHeader
        toggle={handleClose}
        className={slicktextStyles.mappingModalHeader}
      >
        Textword DD Addressbook Mapping for Textword: '{textword.word}' (
        {textword.accountName})
      </ModalHeader>
      <ModalBody className={slicktextStyles.mappingModalBody}>
        {availableAccountsSorted.length === 0 ? (
          <Row className={slicktextStyles.mappingModalBodyRow}>
            <Col>
              <h5>
                There are no DD addressbooks which match the criteria to map to
                this textword
              </h5>
            </Col>
          </Row>
        ) : null}
        <Row className={slicktextStyles.mappingModalBodyRow}>
          <Col>
            <h6>
              DD Addressbooks / DD Accounts must match the following criteria:
            </h6>
            <ul>
              <li>
                Addressbook must match textword country ({textword.country})
              </li>
              <li>
                Addressbook must match textword party id ({textword.party_id})
              </li>
              <li>Addressbook must have data capture enabled</li>
              <li>
                Addressbook must <u>not</u> be deleted from DD
              </li>
              <li>
                Addressbook must <u>not</u> be set to non-consented
              </li>
              <li>DD Account must be active</li>
              <li>
                DD Account must <u>not</u> be of account type 'marketing_siloed'
              </li>
            </ul>
          </Col>
        </Row>
        {availableAccountsSorted.length > 0 ? (
          <React.Fragment>
            <Row className={slicktextStyles.mappingModalBodyRow}>
              <Col>1) Select the correct DD Account</Col>
            </Row>
            <Row className={slicktextStyles.mappingModalBodyRow}>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>DD Account Name</th>
                      <th>DD Account ID</th>
                      <th>DD Account Type</th>
                      <th>DD Account Level</th>
                      <th>Party ID</th>
                      <th>Party Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          type="select"
                          value={mappingDDAccountId || ''}
                          onChange={accountIdChanged}
                        >
                          <option value="" key="">
                            Please select
                          </option>
                          {accountOptions}
                        </Input>
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={mappingDDAccountId || ''}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableAccounts[mappingDDAccountId] &&
                              availableAccounts[mappingDDAccountId]
                                .ddAccountType) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableAccounts[mappingDDAccountId] &&
                              availableAccounts[mappingDDAccountId]
                                .ddAccountLevel) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableAccounts[mappingDDAccountId] &&
                              availableAccounts[mappingDDAccountId].party_id) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableAccounts[mappingDDAccountId] &&
                              availableAccounts[mappingDDAccountId]
                                .party_name) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className={slicktextStyles.mappingModalBodyRow}>
              <Col>2) Select the correct DD Addressbook ID</Col>
            </Row>
            <Row className={slicktextStyles.mappingModalBodyRow}>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>DD Addressbook Name</th>
                      <th>DD Addressbook ID</th>
                      <th>DD Account ID</th>
                      <th>DD Account Name</th>
                      <th>Party ID</th>
                      <th>Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          type="select"
                          value={mappingDDAddressbookId || ''}
                          onChange={addressbookIdChanged}
                        >
                          <option value="" key="">
                            Please select
                          </option>
                          {addressbookOptions}
                        </Input>
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={mappingDDAddressbookId || ''}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableAddressbooks[mappingDDAddressbookId] &&
                              availableAddressbooks[mappingDDAddressbookId]
                                .accountId) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableAddressbooks[mappingDDAddressbookId] &&
                              availableAddressbooks[mappingDDAddressbookId]
                                .accountName) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableAddressbooks[mappingDDAddressbookId] &&
                              availableAddressbooks[mappingDDAddressbookId]
                                .party_id) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            (availableAddressbooks[mappingDDAddressbookId] &&
                              availableAddressbooks[mappingDDAddressbookId]
                                .country) ||
                            ''
                          }
                          disabled={true}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className={slicktextStyles.mappingModalFooterRow}>
              <Col>
                3) Save to commit the mapping and schedule for push to Big Query
              </Col>
            </Row>
            <Row className={slicktextStyles.mappingModalFooterRow}>
              <Col>
                <Button
                  color="primary"
                  onClick={(e) => saveTextwordMapping()}
                  disabled={saveIsDisabled}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        ) : null}
      </ModalBody>
    </Modal>
  );
};
