.inputContainer {
  display: flex;
}

.countrySelectedLabel {
  margin-right: 8px;
  margin-top: 10px;
  align-items: center;
  display: flex;
  gap: 4px;
}

.countrySelectedContainer {
  white-space: nowrap;
  display: flex;
}

.countryDropdown {
  width: 100%;
  position: absolute;
  z-index: 2;
}

.parentContainer {
  position: relative;
}
