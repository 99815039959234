import * as React from 'react';
import { useList, useObjectVal } from 'react-firebase-hooks/database';
import { useState } from 'react';
import { ref } from '../../utils/firebase';
import { arrayToOptions } from '../../utils/react';
import { Row, Col, Button, Table, Input, FormGroup, Alert } from 'reactstrap';
import { PlusIcon } from '../../components/Icons';
import { Loader } from '../../components/Loader';
import { logger } from '../../logging';

const pagesStyles = require('../pages.css');
const iconStyles = require('../../components/icons.css');

const LabelRow = ({ refKey, val, enumerations }) => {
  const labelGroups = Object.values(enumerations.labelGroup) || [];
  const [name, setName] = useState(val.label);
  const [group, setGroup] = useState(val.labelGroup);
  const labelRef = ref(`labels/${refKey}`);

  const updateLabelLabel = async () => {
    try {
      await labelRef.update({ ...val, label: name, labelGroup: group });
    } catch (e) {
      logger.error(e);
    }
  };

  /* const deleteLabel = async () => {
    try {
      await labelRef.remove();
    } catch (e) {
      logger.error(e);
    }
  } */

  return (
    <tr>
      <td>{val.value}</td>
      <td>
        <Input name="name" placeholder="Name" value={name} readOnly={true} />
      </td>
      <td>
        <Input
          type="select"
          name="labelGroup"
          value={group}
          onChange={(e) => setGroup(e.target.value)}
        >
          {arrayToOptions(labelGroups)}
        </Input>
      </td>
      <td>
        <Button onClick={(e) => updateLabelLabel()}>Update</Button>
      </td>
    </tr>
  );
};

const CreateLabelRow = ({ enumerations, setValidationError }) => {
  const labelGroups = Object.values(enumerations.labelGroup) || [];
  const [value, setValue] = useState('');
  const [name, setName] = useState('');
  const [group, setGroup] = useState('');
  const [disabled, setDisabled] = useState(true);

  // used if a duplicate value/name is found
  const reset = () => {
    setValue('');
    setName('');
    setGroup('');
    setDisabled(true);
  };

  const createLabel = async () => {
    try {
      const enumRef = ref(`labels/${value}`);
      const enumSnap = await enumRef.once('value');
      if (enumSnap.exists()) {
        // already have a key with this value,  can't create
        reset();
        setValidationError(
          'Duplicate keys are not allowed, please use a different key'
        );
        return;
      }

      const nameRef = ref(`labels`).orderByChild('label').equalTo(name);
      const nameSnap = await nameRef.once('value');

      let existingName;
      nameSnap.forEach((s) => {
        const val = s.val();
        existingName = val.label;
        return true;
      });

      if (existingName) {
        // already exists, can't create
        reset();
        setValidationError(
          'Duplicate label names are not allowed, please use a different name'
        );
        return;
      }

      const val = { value, label: name, labelGroup: group };
      await enumRef.update(val);

      setValidationError('');
      reset();
    } catch (e) {
      logger.error(e);
      reset();
    }
  };

  const handleValueChanged = (e) => {
    const val = e.target.value;
    setValue(val);
    setDisabled(true);
    if (val !== '' && name !== '' && group !== '') {
      setDisabled(false);
    }
  };

  const handleNameChanged = (e) => {
    const val = e.target.value;
    setName(val);
    setDisabled(true);
    if (val !== '' && value !== '' && group !== '') {
      setDisabled(false);
    }
  };

  const handleGroupChanged = (e) => {
    const val = e.target.value;
    setGroup(val);
    setDisabled(true);
    if (val !== '' && value !== '' && name !== '') {
      setDisabled(false);
    }
  };

  return (
    <tr>
      <td>
        <Input
          name="value"
          placeholder="Value"
          value={value}
          onChange={handleValueChanged}
        />
      </td>
      <td>
        <Input
          name="name"
          placeholder="Name"
          value={name}
          onChange={handleNameChanged}
        />
      </td>
      <td>
        <Input
          type="select"
          name="labelGroup"
          value={group}
          onChange={handleGroupChanged}
        >
          {arrayToOptions(labelGroups)}
        </Input>
      </td>
      <td>
        <Button onClick={(e) => createLabel()} disabled={disabled}>
          <PlusIcon fill="white" class={iconStyles.addButton} />
        </Button>
      </td>
    </tr>
  );
};

export const Labels = () => {
  const [enumerations] = useObjectVal(ref('enumerations')) as any;
  const [labelValues, labelLoading, labelError] = useList(ref('labels'));
  const [validationError, setValidationError] = useState('');

  if (labelLoading || !enumerations) {
    return <Loader loading={true} />;
  }

  if (labelError) {
    return <React.Fragment>Error</React.Fragment>;
  }

  return (
    <div className={pagesStyles.container}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Labels</h3>
        </Col>
      </Row>
      {validationError && validationError !== '' && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="danger">{validationError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={{ size: 12 }}>
          <Table striped responsive>
            <thead>
              <CreateLabelRow
                enumerations={enumerations}
                setValidationError={setValidationError}
              />
            </thead>
            <tbody>
              {labelValues.map((c) => (
                <LabelRow
                  refKey={c.key}
                  val={c.val()}
                  key={c.key}
                  enumerations={enumerations}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};
