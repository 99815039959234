import * as React from 'react';
import { enumerations as enumerationsObj } from './enumerationsModel';
import { createContext, useState, useEffect } from 'react';
import * as firebaseService from '../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export const EnumerationsContext = createContext({
  enumerations: enumerationsObj,
  loadingEnumerations: false,
});

export const EnumerationsProvider = ({ children }) => {
  const damApp = firebaseService.getDAMApp();
  const [enumerations, setEnumerations] = useState(enumerationsObj);
  const [loadingEnumerations, setLoadingEnumerations] = useState(false);
  const [user, userLoading] = useAuthState(damApp.auth() as any);

  const loadEnumerations = async () => {
    setLoadingEnumerations(true);
    await enumerations.fetch();
    setEnumerations(enumerations);
    setLoadingEnumerations(false);
  };

  useEffect(() => {
    if (user) {
      loadEnumerations();
    }
  }, [user]);

  return (
    <EnumerationsContext.Provider value={{ enumerations, loadingEnumerations }}>
      {children}
    </EnumerationsContext.Provider>
  );
};
