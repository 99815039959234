import * as React from 'react';
import { useContext } from 'react';
import { EnumerationsContext } from '../enumerations/EnumerationsContext';
import { useState } from 'react';
import classnames from 'classnames';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Optins } from './Optins';
import { OptinsCategories } from './OptinCategories';

const pagesStyles = require('../pages.css');

const OPTINS_TAB = '1';
const CATEGORIES_TAB = '2';

export const OptinsContainer = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { enumerations, loadingEnumerations } = useContext(EnumerationsContext);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Optins</h3>
        </Col>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === OPTINS_TAB })}
            onClick={() => {
              toggle(OPTINS_TAB);
            }}
          >
            Optins
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === CATEGORIES_TAB })}
            onClick={() => {
              toggle(CATEGORIES_TAB);
            }}
          >
            Optin Categories Management
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className={pagesStyles.fullHeight}>
        <TabPane tabId={OPTINS_TAB} className={pagesStyles.fullHeight}>
          <Optins enumerations={enumerations} />
        </TabPane>
        <TabPane tabId={CATEGORIES_TAB} className={pagesStyles.fullHeight}>
          <OptinsCategories />
        </TabPane>
      </TabContent>
    </div>
  );
};
