import * as React from 'react';
import { logger } from '../../logging';
import { useState } from 'react';

// Define a UI for filtering on numeric columns
export const NumberColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

export const NumberFilter = ({ column, data }) => {
  const { filterValue = [], setFilter } = column;

  // logger.debug(`filterValue[0]: ${filterValue[0]}`);

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(undefined);

  const handleMinChange = (event) => {
    const val = event.target.value;

    if (val === '') {
      setMin(0);
      setFilter([0, max]);
      return;
    }

    const isNum = /^\d+$/.test(val);
    if (!isNum) return;
    logger.debug(
      `[handleMinChange] val: ${val} (${isNum}) setting filter: ${[val, max]}`
    );
    setMin(parseInt(val, 10));
    setFilter([parseInt(val, 10), max]);
  };

  const handleMaxChange = (event) => {
    const val = event.target.value;

    if (val === '') {
      setMax(undefined);
      setFilter([min, undefined]);
      return;
    }

    const isNum = /^\d+$/.test(val);
    if (!isNum) return;

    logger.debug(
      `[handleMaxChange] val: ${val} (${isNum}) setting filter: ${[min, val]}`
    );
    setMax(parseInt(val, 10));
    setFilter([min, parseInt(val, 10)]);
  };

  return (
    <React.Fragment>
      <input
        placeholder="Min"
        value={min}
        type="text"
        onChange={handleMinChange}
      />
      <input
        placeholder="Max"
        value={max !== undefined ? max : ''}
        type="text"
        onChange={handleMaxChange}
      />
    </React.Fragment>
  );
};

export const NumberRangeFilter = ({ column, data }) => {
  const { filterValue = [], setFilter } = column;
  logger.debug(`filterValue: ${filterValue}`);

  // Handle filter changes
  const handleFilterChange = (event) => {
    logger.debug(`event: ${event.target.value}`);
    const [min, max] = event.target.value.split('-').map(Number);
    setFilter([min, max]);
  };

  return (
    <input
      type="text"
      value={filterValue.join('-')}
      onChange={handleFilterChange}
      placeholder="Min-Max"
    />
  );
};
