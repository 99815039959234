import * as React from 'react';
import { countries as countriesObj } from './countriesModel';
import { createContext, useState, useEffect } from 'react';
import * as firebaseService from '../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Loader } from '../../components/Loader';

export const CountriesContext = createContext({
  countries: countriesObj,
  loadingCountries: false,
});

export const CountriesProvider = ({ children }) => {
  const damApp = firebaseService.getDAMApp();
  const [countries, setCountries] = useState(countriesObj || ({} as any));
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [user, userLoading] = useAuthState(damApp.auth() as any);

  const loadCountries = async () => {
    setLoadingCountries(true);
    await countries.fetch();
    setCountries(countries);
    setLoadingCountries(false);
  };

  useEffect(() => {
    if (user) {
      loadCountries();
    }
  }, [user]);

  return (
    <CountriesContext.Provider value={{ countries, loadingCountries }}>
      {children}
    </CountriesContext.Provider>
  );
};
