import moment from 'moment';
import {
  ExpBackoff,
  Workflow,
  WorkflowInvalidSubtype,
  WorkflowLifecycle,
  WorkflowLifecycleDefault,
  WorkflowLifecycleStage,
  WorkflowLifecycleType,
  WorkflowSubtype,
} from '../../../workflows/domain/workflow';

// Default retry plan is
// Retry	Seconds
// 1	      10.00
// 2	      30.00
// 3	      70.00
// 4	      150.00
// 5	      300.00 - capped to 5 minutes
function nextBackoffRetryInterval(
  previousInterval = 0,
  interval = 10 * 1000,
  rate = 2,
  intervalCap = 300 * 1000 // Cap interval at 5 minutes
) {
  const nextInterval = previousInterval * rate + interval;
  return Math.min(nextInterval, intervalCap);
}

export enum BackoffReason {
  VAULT_OTHER_ERROR = 'VAULT OTHER ERROR',
  VAULT_API_NOT_FOUND = 'VAULT API NOT FOUND',
  VAULT_SERVER_ERROR = 'VAULT SERVER ERROR',
  VAULT_CREDS_INACTIVE = 'VAULT CREDS INACTIVE',
  VAULT_API_TIMEOUT = 'VAULT API TIMEOUT',
  DD_OTHER_ERROR = 'DD OTHER ERROR',
  DD_RATE_LIMIT_ERROR = 'DD RATE LIMIT ERROR',
  DD_SERVER_ERROR = 'DD SERVER ERROR',
  DD_API_TIMEOUT = 'DD API TIMEOUT',
  IFB_OTHER_ERROR = 'IFB OTHER ERROR',
  IFB_API_TIMEOUT = 'IFB API TIMEOUT',
  IFB_SERVER_ERROR = 'IFB SERVER ERROR',
}

export function backoffScheduler(
  currentBackoff: ExpBackoff | undefined,
  backoffResetAfter: number
) {
  const backoff = currentBackoff;
  const isValid = backoff?.last_incident
    ? moment().diff(moment.unix(backoff.last_incident), 's') < backoffResetAfter
    : false;
  const pendingExecution = (isValid && !backoff?.executed) || false;
  const interval = backoff?.interval || 300 * 1000;

  return {
    isValid,
    pendingExecution,
    interval,
    createExecutedBackoff() {
      return {
        ...backoff,
        executed: true,
      };
    },
    createBackoff(reason: string, defaultInterval = 0) {
      const interval = nextBackoffRetryInterval(
        backoff?.reason === reason ? backoff.interval : defaultInterval
      );
      return {
        reason,
        interval,
        executed: false,
        last_incident: moment.now(),
      } as ExpBackoff;
    },
  };
}

function getThreeStage6MLifecycle() {
  // starting workflow lifecycle state
  return WorkflowLifecycleDefault[WorkflowLifecycleType.THREE_STAGE_6M];
}

function getOneStage7DLifecycle() {
  // starting workflow lifecycle state
  return WorkflowLifecycleDefault[WorkflowLifecycleType.ONE_STAGE_7D];
}

export function getWorkflowDefaultLifecycle(workflow: Workflow) {
  switch (workflow.subtype) {
    case WorkflowSubtype.DATA_MIGRATION:
      return getOneStage7DLifecycle();
    case WorkflowSubtype.EXILE:
      return getOneStage7DLifecycle();
    case WorkflowSubtype.DATA_TRANSFER:
      return getThreeStage6MLifecycle();
    default:
      throw new WorkflowInvalidSubtype(`Invalid subtype: ${workflow.subtype}`);
  }
}

export const getCronSchedule = (stage: WorkflowLifecycleStage) => {
  switch (stage) {
    case WorkflowLifecycleStage.DAYS:
      return '0 0 * * *';
    case WorkflowLifecycleStage.WEEKS:
      const dayOfWeek = moment().day();
      // Create a cron string that runs on a day of the week (0-6)
      return `0 0 * * ${dayOfWeek}`;
    case WorkflowLifecycleStage.MONTHS:
      const dayOfMonth = Math.min(moment().date(), 28); // set to a max date of 28 to avoid skipping months that might not have 30 or 31 days
      // Create a cron string that runs on a day of the month (0-31)
      return `0 0 ${dayOfMonth} * *`;
    default:
      // default to daily?
      return '0 0 * * *';
  }
};

export function isLifecycleAtEnd(lifecycle: WorkflowLifecycle) {
  switch (lifecycle.type) {
    case WorkflowLifecycleType.THREE_STAGE_6M:
      return (
        lifecycle.stage === WorkflowLifecycleStage.MONTHS &&
        lifecycle.counter >= 6
      );
    case WorkflowLifecycleType.ONE_STAGE_7D:
      return (
        lifecycle.stage === WorkflowLifecycleStage.DAYS &&
        lifecycle.counter >= 7
      );
    default:
      return false;
  }
}
