import { getDAMApp } from '../../services/firebase';

export class Countries {
  countries = {};

  constructor() {
    this.fetch = this.fetch.bind(this);
  }

  async fetch() {
    const damApp = getDAMApp();
    const ref = damApp.database().ref('countries');
    const res = await ref.once('value');
    this.countries = res.val();
  }

  getName(value: string): string {
    const countriesObj = this.countries;
    if (!countriesObj) {
      return value;
    }

    const valueObj = countriesObj[value];
    if (!valueObj) {
      return value;
    }

    return valueObj.label;
  }
}

export const countries = new Countries();
