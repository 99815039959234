import * as React from 'react';
import { useContext, useState } from 'react';
import { useObject, useObjectVal } from 'react-firebase-hooks/database';
import * as firebaseService from '../../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Alert, Button, Col, CustomInput, Row, TabPane } from 'reactstrap';
import { Loader } from '../../../components/Loader';
import { logger, serverLogger } from '../../../logging';
import { ref } from '../../../utils/firebase';
import { DD_INSIGHTS_TAB } from '../AccountForm';
import moment from 'moment';
import { AbilityContext } from '../../../auth/Can';
import * as R from 'ramda';

const accountStyles = require('../account.css');

export const DDInsightsTab = ({ system, account }) => {
  const damApp = firebaseService.getDAMApp();
  const [user, userLoading] = useAuthState(damApp.auth() as any);
  const [accountValues, accountLoading, accountError] = useObjectVal(
    ref(`accounts/${system}/${account.id}`)
  );
  const [country, countryLoading, countryError] = useObjectVal(
    ref(
      `countries/${
        system === 'dotdigital'
          ? account.territoryOperatingCountry
          : account.country
      }`
    )
  );

  const [saving, setSaving] = useState(false);
  const [sendSubscriptionInsights, setSendSubscriptionInsights] =
    useState(false);
  const [sendGenreInsights, setSendGenreInsights] = useState(false);
  const [subDisabled, setSubDisabled] = useState(true);
  const [genreDisabled, setGenreDisabled] = useState(true);

  const ability = useContext(AbilityContext);

  // get the custom fields for this account that exist with no errors
  const accountFieldNames = R.reduce(
    (acc: string[], name: string) => {
      const field = accountValues['customFields'][name];
      if (!field.error || field.error === '') {
        return R.append(name, acc);
      }

      return acc;
    },
    [],
    Object.keys((accountValues && accountValues['customFields']) || {})
  );

  // get the fields classified as 'genre'
  const genreFieldsRef = damApp
    .database()
    .ref('customFields')
    .orderByChild('category')
    .equalTo('genre');
  const [genreFieldsSnap, genreFieldsLoading, genreFieldsError] = useObject(
    genreFieldsRef as any
  );

  // does this account have any genre fields?
  let hasGenreFields = false;
  if (genreFieldsSnap) {
    // check accountFieldNames against genreFields
    const genreFieldNames = [];
    genreFieldsSnap.forEach((s) => {
      const field = s.val();
      genreFieldNames.push(field.name);
    });

    const isect = R.intersection(accountFieldNames, genreFieldNames);
    hasGenreFields = isect.length > 0;
  }

  React.useEffect(() => {
    logger.debug(`accountValues: ${JSON.stringify(accountValues)}`);
    if (accountValues && accountValues['send_subscription_insights']) {
      setSendSubscriptionInsights(accountValues['send_subscription_insights']);
    }

    if (accountValues && accountValues['send_genre_insights']) {
      setSendGenreInsights(accountValues['send_genre_insights']);
    }

    if (
      accountValues &&
      accountValues['data_acquisition_processing_method'] === 'asynchronous' &&
      accountValues['isB2COrB2B'] === 'b2c'
    ) {
      logger.debug(`accountValues: ${JSON.stringify(accountValues)}`);
      setSubDisabled(false);
      setGenreDisabled(false);
    }
  }, [accountValues]);

  if (accountLoading === true || countryLoading) {
    return <Loader loading={true} />;
  }

  if (accountError || countryError) {
    return (
      <TabPane tabId={DD_INSIGHTS_TAB}>
        <React.Fragment>Error</React.Fragment>
      </TabPane>
    );
  }

  if (!accountValues) {
    return (
      <TabPane tabId={DD_INSIGHTS_TAB}>
        <div>No data available for account</div>
      </TabPane>
    );
  }

  const handleSave = async () => {
    // logger.debug(`[DataAcquisitionTab] save clicked`);
    setSaving(true);
    if (accountValues && !userLoading && user) {
      try {
        const accountRef = damApp
          .database()
          .ref(`accounts/${system}/${account.id}`);
        const accountToUpdate = {
          ...accountValues,
          lastModifiedBy: { uid: user.uid, email: user.email },
        };

        accountToUpdate['send_subscription_insights'] =
          sendSubscriptionInsights;

        accountToUpdate['send_genre_insights'] = sendGenreInsights;

        accountToUpdate['lastModifiedAt'] = `${moment().format()}`;

        serverLogger(
          `[DDInsightsTab] User ${user.email} attempting to change setting for account ${system}/${account.id} send_subscription_insights: ${sendSubscriptionInsights} & send_genre_insights: ${sendGenreInsights}`,
          'info'
        );

        await accountRef.update(accountToUpdate);
      } catch (e) {
        logger.error(`[DDInsightsTab] error saving account update: ${e}`);
      }
    }
    setSaving(false);
  };

  const handleSendSubscriptionInsights = (e: any) => {
    const checked = e.target.checked;
    setSendSubscriptionInsights(checked);
  };

  const handleSendGenreInsights = (e: any) => {
    const checked = e.target.checked;
    setSendGenreInsights(checked);
  };

  return (
    <TabPane tabId={DD_INSIGHTS_TAB}>
      <Row>
        <Col>
          <Alert color="info">
            DotDigital Insight records will only be sent when account is set to
            Asynchronous Data Acquisition Method
          </Alert>
        </Col>
      </Row>
      <Row className={accountStyles.formRow}>
        <Col sm={6}>
          <CustomInput
            type="switch"
            id="sendSubscriptionInsights"
            name="sendSubscriptionInsights"
            label="Send Subscription Insight Record to DD"
            onChange={handleSendSubscriptionInsights}
            checked={sendSubscriptionInsights}
            disabled={subDisabled}
          />
        </Col>
      </Row>
      <Row className={accountStyles.formRow}>
        <Col sm={6}>
          <CustomInput
            type="switch"
            id="sendGenreInsights"
            name="sendGenreInsights"
            label="Send Genre Insight Record to DD"
            onChange={handleSendGenreInsights}
            checked={sendGenreInsights}
            disabled={genreDisabled}
          />
        </Col>
      </Row>
      <Row className={accountStyles.formRow}>
        <Col sm={6}>
          <Button
            color="primary"
            onClick={handleSave}
            disabled={ability.cannot('manage', 'dd_insights') || saving}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </Col>
      </Row>
    </TabPane>
  );
};
