import * as React from 'react';
import { useContext } from 'react';
import { Col, Row, TabPane } from 'reactstrap';
import { ADDRESSBOOKS_TAB } from '../AccountForm';
import { Addressbooks } from '../../addressbooks/Addressbooks';
import { Loader } from '../../../components/Loader';
import { EnumerationsContext } from '../../enumerations/EnumerationsContext';

const pagesStyles = require('../../pages.css');

export interface Addressbook {
  id: number;
  accountId: number;
  accountName: string;
  account_addressbookname: string;
  canopusId?: string;
  connectLocalId?: string;
  country: string;
  created: string;
  dataCapture: boolean;
  deletedFromDD?: boolean;
  excludeFromReporting?: boolean;
  hascanopus_processed_accountid_addressbookid: string;
  haspartyid_senttobq_accountid_addressbookid: string;
  inDD_hascanopus_haslyticssegment_account: string;
  lastSynced: string;
  modified: string;
  name: string;
  nonConsented?: boolean;
  r2Id?: string;
  sentToBq?: string;
  sentPartyIdToLytics?: number;
  lyticsSegmentId?: string;
  custom_canopus?: boolean;
  canopusValid?: boolean;
  canopusName?: string;
  doNotShare?: boolean;
  allowMerchRecommendations?: boolean;
  category?: string;
  key?: string;
  communityLeaderId?: string | null;
  communityLeaderName?: string | null;
  communityLeaderMappingValid?: boolean;
  tag?: string;
  party_id?: number;
  party_name?: string;
  partyIdValid?: boolean;
}

interface Addressbooks {
  addressbooks: Addressbook[];
}

export const AddressbooksTab = ({ account }) => {
  const { enumerations, loadingEnumerations } = useContext(EnumerationsContext);

  if (!enumerations.enumerations || loadingEnumerations) {
    return <Loader loading={true} />;
  }

  return (
    <TabPane tabId={ADDRESSBOOKS_TAB} className={pagesStyles.fullHeight}>
      <div className={pagesStyles.fullHeight}>
        <Row className={pagesStyles.fullHeight}>
          <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
            <Addressbooks
              account={account}
              allAccounts={false}
              enumerations={enumerations}
            />
          </Col>
        </Row>
      </div>
    </TabPane>
  );
};
