import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { CommunityLeaders } from './CommunityLeaders';

const pagesStyles = require('../pages.css');

export const CommunityContainer = () => {
  return (
    <div className={pagesStyles.fullHeight}>
      <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
        <Row className={pagesStyles.headerRow}>
          <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
            <h3>Community Leaders</h3>
          </Col>
        </Row>
        <CommunityLeaders />
      </div>
    </div>
  );
};
