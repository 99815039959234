import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { Tags } from './Tags';
import * as matchSorter from 'match-sorter';

const pagesStyles = require('../pages.css');

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
export const fuzzyTextFilterFnExp = fuzzyTextFilterFn;

export const TagsContainer = () => {
  return (
    <div className={pagesStyles.container}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Tags</h3>
        </Col>
      </Row>
      <div>
        <Tags />
      </div>
    </div>
  );
};
