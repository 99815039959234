import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import { logger } from '../../functions/src/logging';

// initializes the default firebase app
let damApp: firebase.app.App;
export const getDAMApp = () => {
  if (damApp) {
    return damApp;
  }

  try {
    damApp = firebase.initializeApp(
      {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.FIREBASE_DATABASE_URL,
        projectId: process.env.FIREBASE_PROJECT,
      },
      'damApp'
    );
  } catch (e) {
    logger.error(`damApp initialization error: ${e}`);
  } // duplicate initialization of default app

  return damApp;
};

let canopusApp: firebase.app.App;
export const getCanopusApp = () => {
  if (canopusApp) {
    return canopusApp;
  }

  try {
    canopusApp = firebase.initializeApp(
      {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.FIREBASE_CANOPUS_DATABASE_URL,
        projectId: process.env.FIREBASE_PROJECT,
      },
      'canopusApp'
    );
  } catch (e) {
    logger.error(`canopusApp initialization error: ${e}`);
  } // duplicate initialization

  return canopusApp;
};

let partyServiceApp: firebase.app.App;
export const getPartyServiceApp = () => {
  if (partyServiceApp) {
    return partyServiceApp;
  }

  try {
    partyServiceApp = firebase.initializeApp(
      {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.FIREBASE_PARTY_SERVICE_DATABASE_URL,
        projectId: process.env.FIREBASE_PROJECT,
      },
      'partyServiceApp'
    );
  } catch (e) {
    logger.error(`partyServiceApp initialization error: ${e}`);
  } // duplicate initialization

  return partyServiceApp;
};

let ifbApp: firebase.app.App;
export const getIFBApp = () => {
  if (ifbApp) {
    return ifbApp;
  }

  try {
    ifbApp = firebase.initializeApp(
      {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.FIREBASE_IFB_DATABASE_URL,
        projectId: process.env.FIREBASE_PROJECT,
      },
      'ifbApp'
    );
  } catch (e) {
    logger.error(`ifbApp initialization error: ${e}`);
  } // duplicate initialization

  return ifbApp;
};

