import * as React from 'react';
import { useState, useRef } from 'react';
import classnames from 'classnames';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import { Can } from '../../auth/Can';
import { AccountAudit } from './AccountAudit';
import { CredentialsTabs } from './CredentialsTabs';
import { ShortLongCodesTab } from './slicktext/ShortLongCodesTab';
import { TextwordsTab } from './slicktext/TextwordsTab';
import { CustomFieldsTab } from './dotdigital/CustomFieldsTab';
import { LyticsTab } from './dotdigital/LyticsTab';
import { AddressbooksTab } from './dotdigital/AddressbooksTab';
import { Feature } from 'flagged';
import { PrivyTab } from './dotdigital/PrivyTab';
import { logger } from '../../logging';
import { DDForm } from './dotdigital/DDForm';
import { SlicktextForm } from './slicktext/SlicktextForm';
import { AttentiveForm } from './attentive/AttentiveForm';
import { DataAcquisitionTab } from './DataAcquisitionTab';
import { TreasureDataForm } from './treasure-data/TreasureDataForm';
import { CommunityForm } from './community/CommunityForm';
import { CommunityOptinsTab } from './community/CommunityOptinsTab';
import { DDInsightsTab } from './dotdigital/DDInsightsTab';

const pagesStyles = require('../pages.css');

const FIELDS_TAB = '1';
export const CREDENTIALS_TAB = '2';
export const ARCHIVED_CREDENTIALS_TAB = '3';
export const SHORT_LONG_CODES_TAB = '4';
export const TEXTWORDS_TAB = '5';
export const CUSTOM_FIELDS_TAB = '6';
export const ADDRESSBOOKS_TAB = '7';
export const LYTICS_TAB = '8';
export const PRIVY_TAB = '9';
export const DATA_ACQUISITION_TAB = '10';
export const COMMUNITY_OPTINS_TAB = '11';
export const DD_INSIGHTS_TAB = '12';

const systemNames = {
  dotdigital: 'DotDigital',
  slicktext: 'SlickText',
  attentive: 'Attentive',
  treasure_data: 'Treasure Data',
  community: 'Community',
};

export const AccountForm = ({ save, model, account, system }) => {
  // logger.debug(`[AccountForm] model: ${JSON.stringify(model)}`);
  const [activeTab, setActiveTab] = useState('1');
  const [canSave, setCanSave] = useState(true);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let accountName = '';
  const isDotdigital = system === 'dotdigital';
  const isSlicktext = system === 'slicktext';
  const isAttentive = system === 'attentive';
  const isTreasureData = system === 'treasure_data';
  const isCommunity = system === 'community';
  if (isDotdigital && account && account.ddAccountName)
    accountName = account.ddAccountName;
  if (isSlicktext && account && account.name) accountName = account.name;
  if (isAttentive && account && account.account_name)
    accountName = account.account_name;
  if (isTreasureData && account && account.account_name)
    accountName = account.account_name;
  if (isCommunity && account && account.account_name)
    accountName = account.account_name;

  return (
    <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
      <h3>{account && `${systemNames[system]}: ${accountName}`}</h3>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === FIELDS_TAB })}
            onClick={() => {
              toggle(FIELDS_TAB);
            }}
          >
            Account Details
          </NavLink>
        </NavItem>
        {account && isCommunity && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === COMMUNITY_OPTINS_TAB,
              })}
              onClick={() => {
                toggle(COMMUNITY_OPTINS_TAB);
              }}
            >
              Optins
            </NavLink>
          </NavItem>
        )}
        <Can I="read" a="credential">
          {account && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === CREDENTIALS_TAB,
                })}
                onClick={() => {
                  toggle(CREDENTIALS_TAB);
                }}
              >
                Credentials
              </NavLink>
            </NavItem>
          )}
        </Can>
        <Feature name="treasure-data-admin">
          {account && (isDotdigital || isSlicktext) && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === DATA_ACQUISITION_TAB,
                })}
                onClick={() => {
                  toggle(DATA_ACQUISITION_TAB);
                }}
              >
                Data Acquisition Admin
              </NavLink>
            </NavItem>
          )}
        </Feature>
        <Feature name="dd-insights-admin">
          {account && isDotdigital && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === DD_INSIGHTS_TAB,
                })}
                onClick={() => {
                  toggle(DD_INSIGHTS_TAB);
                }}
              >
                DD Insights
              </NavLink>
            </NavItem>
          )}
        </Feature>
        <Feature name="addressbooks-management">
          {account && isDotdigital && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === ADDRESSBOOKS_TAB,
                })}
                onClick={() => {
                  toggle(ADDRESSBOOKS_TAB);
                }}
              >
                Addressbooks
              </NavLink>
            </NavItem>
          )}
        </Feature>
        {account && isSlicktext && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TEXTWORDS_TAB })}
              onClick={() => {
                toggle(TEXTWORDS_TAB);
              }}
            >
              Textwords
            </NavLink>
          </NavItem>
        )}
        {account && isSlicktext && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === SHORT_LONG_CODES_TAB,
              })}
              onClick={() => {
                toggle(SHORT_LONG_CODES_TAB);
              }}
            >
              Short/Long Codes
            </NavLink>
          </NavItem>
        )}
        {account && isDotdigital && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === CUSTOM_FIELDS_TAB,
              })}
              onClick={() => {
                toggle(CUSTOM_FIELDS_TAB);
              }}
            >
              Custom Fields
            </NavLink>
          </NavItem>
        )}
        <Feature name="lytics-sync">
          {account && isDotdigital && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === LYTICS_TAB })}
                onClick={() => {
                  toggle(LYTICS_TAB);
                }}
              >
                Lytics
              </NavLink>
            </NavItem>
          )}
        </Feature>
        <Feature name="privy-fields">
          {account && isDotdigital && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === PRIVY_TAB })}
                onClick={() => {
                  toggle(PRIVY_TAB);
                }}
              >
                Privy
              </NavLink>
            </NavItem>
          )}
        </Feature>
        <Can I="read" a="credential">
          {account && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === ARCHIVED_CREDENTIALS_TAB,
                })}
                onClick={() => {
                  toggle(ARCHIVED_CREDENTIALS_TAB);
                }}
              >
                Archived Credentials
              </NavLink>
            </NavItem>
          )}
        </Can>
      </Nav>
      <TabContent activeTab={activeTab} className={pagesStyles.fullHeight}>
        <TabPane tabId={FIELDS_TAB} className={pagesStyles.fullHeight}>
          <Row>
            <Col sm="12">
              <AccountAudit account={account} system={system} />
            </Col>
          </Row>
          {isDotdigital && (
            <DDForm
              canSave={canSave}
              model={model}
              save={save}
              account={account}
              system={system}
              setCanSave={setCanSave}
            />
          )}
          {isSlicktext && (
            <SlicktextForm
              canSave={canSave}
              model={model}
              save={save}
              account={account}
              system={system}
              setCanSave={setCanSave}
            />
          )}
          {isAttentive && (
            <AttentiveForm
              canSave={canSave}
              model={model}
              save={save}
              account={account}
              system={system}
              setCanSave={setCanSave}
            />
          )}
          {isTreasureData && (
            <TreasureDataForm
              canSave={canSave}
              model={model}
              save={save}
              account={account}
              system={system}
              setCanSave={setCanSave}
            />
          )}
          {isCommunity && (
            <CommunityForm
              canSave={canSave}
              model={model}
              save={save}
              account={account}
              system={system}
              setCanSave={setCanSave}
            />
          )}
        </TabPane>
        {account && account.key && <CommunityOptinsTab account={account} />}
        {account && account.key && (
          <CredentialsTabs account={account} system={system} />
        )}
        {account && account.key && isSlicktext && (
          <ShortLongCodesTab account={account} system={system} />
        )}
        {account && isSlicktext && <TextwordsTab account={account} />}
        {account && isDotdigital && <CustomFieldsTab accountId={account.id} />}
        {account && isDotdigital && <LyticsTab accountId={account.id} />}
        <Feature name="addressbooks-management">
          {account && isDotdigital && <AddressbooksTab account={account} />}
        </Feature>
        <Feature name="privy-fields">
          {account && isDotdigital && <PrivyTab accountId={account.id} />}
        </Feature>
        <Feature name="treasure-data-admin">
          {account && (isDotdigital || isSlicktext) && (
            <DataAcquisitionTab system={system} account={account} />
          )}
        </Feature>
        <Feature name="dd-insights-admin">
          {account && isDotdigital && (
            <DDInsightsTab system={system} account={account} />
          )}
        </Feature>
      </TabContent>
    </div>
  );
};
