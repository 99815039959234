import * as React from 'react';

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] && row.values[id] !== '') {
        options.add(row.values[id]);
      }
    });
    return [...options];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option: any, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const SelectColumnFilterFactory =
  (enumeration) =>
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        if (row.values[id] && row.values[id] !== '') {
          options.add(row.values[id]);
        }
      });
      return [...options];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option: any, i) => (
          <option key={i} value={option}>
            {enumeration && enumeration[option] ? enumeration[option].label : option}
          </option>
        ))}
      </select>
    );
  };

export const SelectColumnFilterHidden = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const options = ['true', 'false'];
  const optionsMap = {
    true: 'Hidden',
    false: 'Displayed',
  };

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        if (e.target.value === 'true') {
          setFilter(true);
        } else if (e.target.value === 'false') {
          setFilter(false);
        } else {
          setFilter(undefined);
        }
      }}
    >
      <option value="">All</option>
      {options.map((option: any, i) => (
        <option key={i} value={option}>
          {optionsMap[option]}
        </option>
      ))}
    </select>
  );
};
