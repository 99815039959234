import * as React from 'react';
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { useOnCall } from '../../hooks/firebase';
import { useState, useEffect } from 'react';
import * as R from 'ramda';
import { MinusIcon, PlusIcon } from '../../components/Icons';
import { Loader } from '../../components/Loader';
import { logger } from '../../logging';
import { useList } from 'react-firebase-hooks/database';
import { ref } from '../../utils/firebase';
import { UsersTable } from './UsersTable';
import * as matchSorter from 'match-sorter';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import { useTable, useSortBy, useGlobalFilter, useFilters } from 'react-table';
import { getDAMApp } from '../../services/firebase';

const pagesStyles = require('../pages.css');
const iconStyles = require('../../components/icons.css');
const usersStyles = require('./users.css');

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
export const fuzzyTextFilterFnExp = fuzzyTextFilterFn;

export const UserModal = ({ user, isOpen, toggle }) => {
  const damApp = getDAMApp();
  const [selectedPermission, setSelectedPermission] = useState('');
  const [roleValues, roleLoading, roleError] = useList(ref('roles') as any);
  const perms = user && user.customClaims ? Object.keys(user.customClaims) : []; // default/existing perms
  const [userPermissions, setUserPermissions] = useState([...perms]);
  const disableAddButton =
    userPermissions.indexOf(selectedPermission) !== -1 ||
    selectedPermission === '';

  useEffect(() => {
    // to sync this when props.user changes (which is will as there's only one instance of usermodal)
    const perms =
      user && user.customClaims ? Object.keys(user.customClaims) : []; // default/existing perms
    // logger.debug(`user: ${user}, ${JSON.stringify(userPermissions)}, ${JSON.stringify(perms)}`)
    setUserPermissions(perms);
  }, [user]);

  const addPermission = async () => {
    const newPermissions = R.append(selectedPermission, userPermissions);
    setUserPermissions(newPermissions);
  };

  const removePermission = async (id) => {
    const newPermissions = R.without([id], userPermissions);
    setUserPermissions(newPermissions);
  };

  const save = async () => {
    // call setPermissions on backend with userPermissions
    const callable = damApp
      .functions()
      .httpsCallable('accountManager-setPermissions');
    // logger.info(userPermissions);
    await callable({ uid: user.uid, permissions: userPermissions });
    toggle();
  };

  if (roleLoading) {
    return <Loader loading={true} />;
  }

  if (roleError) {
    return <React.Fragment>Error</React.Fragment>;
  }

  const permissions = R.map((obj: any) => {
    const val = obj.val();
    return { id: val.roleKey, label: val.name };
  }, roleValues);

  const permissionsAvailable = R.reduce(
    (acc: any, obj: any) => {
      const val = obj.val();
      if (!R.includes(val.roleKey, userPermissions)) {
        return R.append({ id: val.roleKey, label: val.name }, acc);
      }
      return acc;
    },
    [],
    roleValues
  );

  const permissionsRows = userPermissions.map((p) => {
    const perm = R.find((o) => o.id === p, permissions);
    if (perm) {
      return (
        <tr key={perm.id}>
          <td>{perm.label}</td>
          <td>
            <Button onClick={(e) => removePermission(perm.id)}>
              <MinusIcon fill="white" class={iconStyles.minusButton} />
            </Button>
          </td>
        </tr>
      );
    }
  });

  return (
    <div className={pagesStyles.container}>
      {user && (
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {user.email} ({user.uid})
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>Permissions</Col>
            </Row>
            <Row className={usersStyles.permissionsSelectRow}>
              <Col>
                <Input
                  type="select"
                  value={selectedPermission}
                  onChange={(e) => setSelectedPermission(e.target.value)}
                >
                  <option key="" value="">
                    Please Select
                  </option>
                  {permissionsAvailable.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.label}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col>
                <Button
                  onClick={() => addPermission()}
                  disabled={disableAddButton}
                >
                  <PlusIcon fill="white" class={iconStyles.addButton} />
                </Button>
              </Col>
            </Row>
            <Table striped responsive className={usersStyles.permissionsTable}>
              <thead>
                <tr>
                  <th>Permission</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>{permissionsRows}</tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={save}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export const Users = () => {
  const [reloadUsers, setReloadUsers] = useState(false);
  const [usersResponse, loading, error] = useOnCall(
    'accountManager-listUsers',
    null,
    reloadUsers
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (usersResponse === null) {
      setUsers([]);
    }

    if (usersResponse) {
      logger.debug(`setting users`);
      setUsers(usersResponse.data);
    }
  }, [usersResponse]);

  useEffect(() => {
    logger.debug(`selectedUser: ${JSON.stringify(selectedUser)}`);
  }, [selectedUser]);

  const toggle = () => {
    setSelectedUser(null);
    setReloadUsers(!reloadUsers); // I hate this
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row }) => (
          <a href="#" onClick={() => setSelectedUser(row.original)}>
            {row.original.email}
          </a>
        ),
      },
      {
        Header: 'Is DAM User',
        accessor: (row) => {
          if (
            row.customClaims &&
            (row.customClaims.isDAMUser === true ||
              row.customClaims.damPowerUser === true)
          ) {
            return 'true';
          }

          return 'false';
        },
      },
      {
        Header: 'UID',
        accessor: 'uid',
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize users - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => users, [users]);

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: 'email',
            desc: false,
          },
        ],
      },
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy
  ) as any;

  if (loading) {
    return <Loader loading={true} />;
  }

  if (error) {
    return <React.Fragment>Error</React.Fragment>;
  }

  return (
    <div className={pagesStyles.container}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Users</h3>
        </Col>
      </Row>
      <UsersTable {...tableData} />
      <UserModal
        isOpen={selectedUser !== null}
        user={selectedUser}
        toggle={(e) => toggle()}
      />
    </div>
  );
};
