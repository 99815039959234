export interface Workflow {
  id: string;
  key?: string;
  name: string;
  type: WorkflowTypes;
  subtype: WorkflowSubtype;
  schedule: string; // cron pattern
  lifecycle: WorkflowLifecycle;
  description?: string;
  continuous: boolean;
  backfill: boolean;
  args?: MigrateContactsArgs;
  created: number;
  modified?: number;
  last_execution?: number;
  next_execution?: number;
  last_execution_result?: ExecutionResult;
  last_successful_execution?: number; // this will only be set when all batches have completed with 0 transient errors (ie all VALID subs have been migrated)
  status: State;
  scheduler_id: string;
  leases?: Lease[];
  error?: string;
  retries?: number;
  endDate?: number;
  isArchived?: boolean;
}

export interface Lease {
  [keyof: string]: number;
}

export enum WorkflowTypes {
  MIGRATE_CONTACTS = 'migrate_contacts',
}

export enum WorkflowSubtype {
  EXILE = 'exile',
  DATA_TRANSFER = 'data_transfer',
  DATA_MIGRATION = 'data_migration',
}

export type WorkflowLifecycle = {
  type: WorkflowLifecycleType;
  stage: WorkflowLifecycleStage;
  counter: number;
};

export enum WorkflowLifecycleType {
  ONE_STAGE_7D = 'one_stage_7d',
  THREE_STAGE_6M = 'three_stage_6m',
}

export enum WorkflowLifecycleStage {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
}

export const WorkflowLifecycleDefault: Record<
  WorkflowLifecycleType,
  WorkflowLifecycle
> = {
  [WorkflowLifecycleType.ONE_STAGE_7D]: {
    type: WorkflowLifecycleType.ONE_STAGE_7D,
    stage: WorkflowLifecycleStage.DAYS,
    counter: 0,
  },
  [WorkflowLifecycleType.THREE_STAGE_6M]: {
    type: WorkflowLifecycleType.THREE_STAGE_6M,
    stage: WorkflowLifecycleStage.DAYS,
    counter: 0,
  },
};

export enum State {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  PAUSED = 'paused',
  STATE_UNSPECIFIED = 'state_unspecified',
  UPDATE_FAILED = 'update_failed',
}

export enum ExecutionResult {
  SUCCESS = 'success',
  ERROR = 'error',
  RETRYING = 'retrying',
}

export enum WorkflowState {
  RESUME = 'resume',
  PAUSE = 'pause',
  RUN_NOW = 'run_now',
}

export type ExpBackoff = {
  reason: string; // string code to identify the reason for backoff and retry
  interval: number; // [ms] time to sleep before retrying a batch
  executed: boolean; // has backoff delay been executed
  last_incident: number; // unix time of last error
};

export interface MigrateContactsArgs {
  workflow_id: string;
  source_account: number;
  source_addressbook: number;
  destination_account: number;
  destination_addressbook: number;
  form_id: string;
  batch_size?: number;
  batch_offset?: number; // record to start from
  batch_number?: number; // used for lease identifiers
  retry_count?: number; // track number of retries
  successful_execution?: boolean;
  start_time?: number; // when the first batch runs - subsequent batches check this vs. now, if >= 23 hours we return (stop processing) as the cloud scheduler will run it again in an hour (trying to prevent tripping over itself)
  is_privacy?: boolean;
  backoff?: ExpBackoff;
}

export class WorkflowError extends Error {}
export class WorkflowExceededEndDate extends WorkflowError {}
export class WorkflowInvalidSubtype extends WorkflowError {}

export class WorkflowLifecycleError extends WorkflowError {}
export class WorkflowLifecycleExceededEndCondition extends WorkflowLifecycleError {}
export class WorkflowLifecycleUnexpectedStage extends WorkflowLifecycleError {}
