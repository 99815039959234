import * as React from 'react';
import { useContext, useState } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import * as firebaseService from '../../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Button,
  Col,
  CustomInput,
  Label,
  Row,
  Table,
  TabPane,
} from 'reactstrap';
import { Loader } from '../../../components/Loader';
import { ref } from '../../../utils/firebase';
import { COMMUNITY_OPTINS_TAB } from '../AccountForm';
import { CommunityOptins } from './CommunityOptins';
import { logger } from '../../../logging';
import { AbilityContext } from '../../../auth/Can';
import { Channel, Optin } from '../../../../functions/src/optins/domain/optin';
import moment from 'moment';
import {
  generateIndexes,
  index,
} from '../../../../functions/src/utils/indexes';

const communityStyles = require('./community.css');
const DEFAULT_CATEGORY = 'main_artist';

export const CommunityOptinsTab = ({ account }) => {
  const damApp = firebaseService.getDAMApp();
  const ifbApp = firebaseService.getIFBApp();
  const [user, userLoading] = useAuthState(damApp.auth() as any);
  const [accountValues, accountLoading, accountError] = useObjectVal(
    ref(`accounts/community/${account.key}`)
  );
  const [saving, setSaving] = useState(false);
  const [whatsappEnabled, setWhatsappEnabled] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState(false);

  const ability = useContext(AbilityContext);

  React.useEffect(() => {
    if (accountValues && accountValues['whatsapp_enabled']) {
      logger.debug(
        `[CommunityOptinsTab] useEffect - accountValues['whatsapp_enabled']: ${accountValues['whatsapp_enabled']}`
      );
      setWhatsappEnabled(accountValues['whatsapp_enabled']);
    }

    if (accountValues && accountValues['sms_enabled']) {
      logger.debug(
        `[CommunityOptinsTab] useEffect - accountValues['sms_enabled']: ${accountValues['sms_enabled']}`
      );
      setSmsEnabled(accountValues['sms_enabled']);
    }
  }, [accountValues]);

  if (accountError) {
    return (
      <TabPane tabId={COMMUNITY_OPTINS_TAB}>
        <React.Fragment>Error</React.Fragment>
      </TabPane>
    );
  }

  if (accountLoading || userLoading) {
    return <Loader loading={true} />;
  }

  if (!accountValues) {
    return (
      <TabPane tabId={COMMUNITY_OPTINS_TAB}>
        <div>No data available for account</div>
      </TabPane>
    );
  }

  interface UpdateCommunityOptin {
    channel: Channel;
    active: boolean;
    account: any;
  }

  const updateCommunityOptin = async ({
    channel,
    active,
    account,
  }: UpdateCommunityOptin) => {
    try {
      const now = moment().unix();
      // 1 get existing optin for account + channel
      let optin = null;
      const optinsRef = ifbApp.database().ref('optins');
      const optinRef = ifbApp
        .database()
        .ref('optins')
        .orderByChild('target_id')
        .equalTo(account.account_id);

      const optinsSnap = await optinRef.once('value');
      optinsSnap.forEach((snap) => {
        const existingOptin = snap.val();
        if (existingOptin.channel === channel) {
          optin = snap.val();
          logger.debug(
            `[CommunityOptinsTab] Existing optin: ${JSON.stringify(optin)}`
          );
        }
      });

      // for new optins: if no optin build one & set to active = false (as will need brand assigning)
      if (!optin) {
        optin = {};
        optin['accountName'] = account.account_name;
        optin['target_id'] = `${account.account_id}`;
        optin['channel'] = channel;
        optin['country'] = account.country_code;
        optin['category'] = DEFAULT_CATEGORY;
        optin['created'] = now;

        logger.debug(
          `[CommunityOptinsTab] New optin: ${JSON.stringify(optin)}`
        );
      }

      // aways set latest party_id, active and vendor
      optin['brandId'] = account.party_id || '';
      optin['name'] = account.party_name || '';
      optin['active'] = active;
      optin['vendor'] = 'community';
      optin['tag'] = `${account.party_name}_${account.account_name}_${channel}`;

      // 2 update to correct active status
      // check party_id & channel enabled
      if (!account.party_name) {
        optin['active'] = false;
      }

      // 3 save
      optin['modified'] = now;

      if (!optin['key']) {
        const newRef = await optinsRef.push();
        if (!newRef.key) {
          throw new Error('[optin/save] - newRef.key not present');
        }

        optin['key'] = newRef.key;
      }

      logger.debug(
        `[CommunityOptinsTab] about to reindex optin: ${JSON.stringify(optin)}`
      );
      const indexed = index(optin); // always rebuild indexes on save
      const newOptinRef = ifbApp.database().ref(`optins/${indexed.key}`);
      logger.debug(
        `[CommunityOptinsTab] about to update indexed optin: ${JSON.stringify(
          indexed
        )}`
      );

      await newOptinRef.update(indexed);
    } catch (e) {
      logger.error(`[CommunityOptinsTab] error updating optin: ${e}`);
    }
  };

  const saveCommunityOptins = async () => {
    logger.debug(`[CommunityOptinsTab] save called`);
    setSaving(true);
    if (accountValues && !userLoading && user) {
      try {
        const accountRef = damApp
          .database()
          .ref(`accounts/community/${account.key}`);
        const accountToUpdate = {
          ...accountValues,
          lastModifiedBy: { uid: user.uid, email: user.email },
        };

        logger.debug(
          `[CommunityOptinsTab] save - whatsappEnabled: ${whatsappEnabled}, smsEnabled: ${smsEnabled}`
        );
        accountToUpdate['whatsapp_enabled'] = whatsappEnabled;
        accountToUpdate['sms_enabled'] = smsEnabled;

        await accountRef.update(accountToUpdate);

        // now update the community optins accordingly
        await updateCommunityOptin({
          channel: Channel.WHATSAPP,
          active: whatsappEnabled === true ? true : false,
          account,
        });

        await updateCommunityOptin({
          channel: Channel.SMS,
          active: smsEnabled === true ? true : false,
          account,
        });
      } catch (e) {
        logger.error(`[CommunityOptinsTav] error saving account update: ${e}`);
      }
    }
    setSaving(false);
  };

  const handleWhatsappEnabledChange = async (e) => {
    logger.debug(
      `[handleWhatsappEnabledChange] called with e.target.checked: ${e.target.checked}`
    );
    setWhatsappEnabled(e.target.checked);
  };

  const handleSmsEnabledChange = async (e) => {
    logger.debug(
      `[handleSmsEnabledChange] called with e.target.checked: ${e.target.checked}`
    );
    setSmsEnabled(e.target.checked);
  };

  return (
    <TabPane tabId={COMMUNITY_OPTINS_TAB}>
      <Row className={communityStyles.optinEnabledRowHeader}>
        <Col sm={6}>
          <h4>Optin Channel Availability For Account</h4>
        </Col>
      </Row>
      <Row className={communityStyles.optinEnabledRow}>
        <Col sm={6}>
          <CustomInput
            type="switch"
            id="whatsapp_enabled"
            name="whatsapp_enabled"
            label="WhatsApp Enabled"
            onChange={handleWhatsappEnabledChange}
            checked={whatsappEnabled}
          />
        </Col>
      </Row>
      <Row className={communityStyles.optinEnabledRow}>
        <Col sm={6}>
          <CustomInput
            type="switch"
            id="sms_enabled"
            name="sms_enabled"
            label="SMS Enabled"
            onChange={handleSmsEnabledChange}
            checked={smsEnabled}
          />
        </Col>
      </Row>
      <Row className={communityStyles.optinEnabledRow}>
        <Col sm={6}>
          <Button
            color="primary"
            onClick={(e) => saveCommunityOptins()}
            disabled={ability.cannot('manage', 'data_acquisition') || saving}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </Col>
      </Row>
      <Row className={communityStyles.optinTableRowHeader}>
        <Col sm={6}>
          <h4>Community Optins Table For Account</h4>
        </Col>
      </Row>
      <Row className={communityStyles.optinTableRow}>
        <Col>
          {!saving ? (
            <CommunityOptins account_id={account.account_id} />
          ) : (
            <Loader loading={true} />
          )}
        </Col>
      </Row>
    </TabPane>
  );
};
