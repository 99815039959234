import * as React from 'react';
import { useEffect, useState } from 'react';
import * as firebaseService from '../../services/firebase';
import {
  useList,
  useListKeys,
  useObjectVal,
} from 'react-firebase-hooks/database';
import { Alert, Button, Col, CustomInput, Input, Row } from 'reactstrap';
import { Loader } from '../../components/Loader';
import { logger } from '../../logging';
import { ref } from '../../utils/firebase';
import * as R from 'ramda';
import { VALIDATION_RULES } from '../addressbooks/AddressbooksBulkUpdate';

const optinsStyles = require('./optins.css');

// these values are used for defaults if category is new
export const defaultCategoryValues = {
  partyId: {
    required: {
      label: 'Required',
      default: true,
    },
  },
  dataCapture: {
    default_value: {
      label: 'Default value',
      default: true,
    },
    editable: {
      label: 'Editable',
      default: true,
    },
  },
  excludeFromReporting: {
    default_value: {
      label: 'Default value',
      default: true,
    },
    editable: {
      label: 'Editable',
      default: true,
    },
  },
  nonConsented: {
    default_value: {
      label: 'Default value',
      default: true,
    },
    editable: {
      label: 'Editable',
      default: true,
    },
  },
  doNotShare: {
    default_value: {
      label: 'Default value',
      default: true,
    },
    editable: {
      label: 'Editable',
      default: true,
    },
  },
};

export const EmailCategoryRow = ({
  refKey,
  val,
  toggleUpdateAllAddressbooksModal,
  setAddressbookCategoryToUpdateData,
  ddAccountTypes,
}) => {
  const damApp = firebaseService.getDAMApp();
  const ifbApp = firebaseService.getIFBApp();
  const [saving, setSaving] = useState(false);
  const [uninitiated, setUninitiated] = useState(false);
  const [categoryValues, categoryLoading, categoryErrors] = useObjectVal(
    ref(`optin_categories/email/${refKey}`)
  );
  const [categoryKeys, categoryKeysLoading, categoryKeysErrors] = useListKeys(
    ref(`optin_categories/email/${refKey}`)
  );

  logger.debug(`val: ${JSON.stringify(val)}`);

  useEffect(() => {
    if (categoryValues === null) {
      logger.debug(
        `[EmailCategoryRow] useEffect: (${refKey}) categoryValues: ${categoryValues}`
      );
      setUninitiated(true);
    }
  }, [categoryValues]);

  const propertyTypes = {
    partyId: 'Party ID',
    dataCapture: 'Data Capture Enabled',
    excludeFromReporting: 'Exclude From Reporting',
    nonConsented: 'Non Consented',
    doNotShare: 'Do Not Share',
  };

  const handleUpdateAll = async () => {
    // logger.debug(`[handleUpdateAll] handleUpdateAll called`);
    const addressbooksRef = ifbApp
      .database()
      .ref(`addressbooks`)
      .orderByChild('category')
      .equalTo(refKey);
    const addressbookSnap = await addressbooksRef.once('value');
    const count = addressbookSnap.numChildren();
    const data = { ...val, count };
    // logger.debug(`[handleUpdateAll]: data: ${JSON.stringify(data)}`);
    setAddressbookCategoryToUpdateData(data);
    toggleUpdateAllAddressbooksModal();
  };

  const handleSwitch = async (key, id, e) => {
    // use ref to set approprietly
    // logger.debug(
    //   `[handleSwitch] key: ${key}, id: ${id}, e.target.checked: ${e.target.checked}, ${JSON.stringify(
    //     defaultCategoryValues
    //   )}`
    // );
    // this handles new categories that don't have any values set in DB
    try {
      const defaultValues = R.mapObjIndexed((value, key, obj) => {
        // logger.debug(`val: ${value}, key: ${key}`);
        return R.mapObjIndexed((v, k, o) => {
          return v.default;
        }, value);
      }, defaultCategoryValues);

      // logger.debug(`defaultValues: ${JSON.stringify(defaultValues)}`);

      const dataToUpdate = { ...defaultValues, ...categoryValues };
      dataToUpdate[key][id] = e.target.checked;
      // logger.debug(`dataToUpdate: ${JSON.stringify(dataToUpdate)}`);

      await saveCategory(dataToUpdate);
      setUninitiated(false);
    } catch (e) {
      logger.error(`handleSwitch: e: ${e}`);
    }
  };

  const handleCategoryRuleChange = async (e) => {
    logger.debug(`e.target.value: ${e.target.value}`);
    setSaving(true);
    try {
      const categoryRef = damApp
        .database()
        .ref(`optin_categories/email/${refKey}`);
      await categoryRef.update({ category_rule: e.target.value });
    } catch (e) {
      logger.error(`[handleCategoryRuleChange] error updating category: ${e}`);
    }

    setSaving(false);
  };

  const handleCategoryRuleSpecificAccountChange = async (e) => {
    logger.debug(`e.target.value: ${e.target.value}`);
    setSaving(true);
    try {
      const categoryRef = damApp
        .database()
        .ref(`optin_categories/email/${refKey}`);
      await categoryRef.update({
        category_rule_specific_account_type: e.target.value,
      });
    } catch (e) {
      logger.error(
        `[handleCategoryRuleSpecificAccountChange] error updating category: ${e}`
      );
    }

    setSaving(false);
  };

  const saveCategory = async (data) => {
    setSaving(true);
    try {
      const categoryRef = damApp
        .database()
        .ref(`optin_categories/email/${refKey}`);
      await categoryRef.update(data);
    } catch (e) {
      logger.error(`[saveCategory] error updating category: ${e}`);
    }

    setSaving(false);
  };

  if (categoryKeysLoading || categoryLoading) {
    return (
      <tr>
        <td>
          <Row>
            <Col>
              <h4>{val.label}</h4>
            </Col>
          </Row>
          <Row>
            <Col>ID: {refKey}</Col>
          </Row>
        </td>
        <td colSpan={6}>
          <Loader loading={true} />
        </td>
      </tr>
    );
  }

  if (categoryKeysErrors || categoryErrors || !ddAccountTypes) {
    return (
      <tr>
        <td>
          <Row>
            <Col>
              <h4>{val.label}</h4>
            </Col>
          </Row>
          <Row>
            <Col>ID: {refKey}</Col>
          </Row>
        </td>
        <td colSpan={6}>Error loading Category Data</td>
      </tr>
    );
  }

  logger.debug(`categoryValues: ${JSON.stringify(categoryValues)}`);
  // logger.debug(`categoryProperties: ${JSON.stringify(categoryProperties)}`);

  const disabled = saving === true;

  const CategoryProperty = ({ id, index, name, label, disabled }) => {
    return (
      <CustomInput
        type="switch"
        id={`${id}_${index}_${refKey}`}
        name={name}
        label={label}
        onChange={(e) => handleSwitch(id, index, e)}
        disabled={disabled}
        checked={
          (categoryValues && categoryValues[id] && categoryValues[id][index]) ??
          defaultCategoryValues[id][index].default
        }
      />
    );
  };

  if (!ddAccountTypes) {
    return <Loader loading={true} />;
  }

  const accountTypeOptions = R.map((snap) => {
    const accountType = snap.val();
    return (
      <option key={accountType.value} value={accountType.value}>
        {accountType.label}
      </option>
    );
  }, ddAccountTypes);

  return (
    <tr>
      <td>
        <Row>
          <Col>
            <h4>{val.label}</h4>
          </Col>
        </Row>
        <Row>
          <Col>ID: {refKey}</Col>
        </Row>
        {uninitiated && (
          <Row>
            <Col>
              <Alert color="info">
                This category is unitiated, to initiate please change any value
                to force a DB save.
              </Alert>
            </Col>
          </Row>
        )}
      </td>
      <td>
        <Row>
          <Col>
            <Input
              name="category_rule"
              placeholder="Category Rule"
              value={(categoryValues && categoryValues['category_rule']) ?? ''}
              onChange={handleCategoryRuleChange}
              type="select"
            >
              <option value="">No Rule</option>
              <option value={VALIDATION_RULES.ONE_PER_ACCOUNT}>
                One Per Account
              </option>
              <option value={VALIDATION_RULES.ONE_PER_COUNTRY_BRAND_CHANNEL}>
                One Per Country/Brand/Channel
              </option>
              <option value={VALIDATION_RULES.SPECIFIC_TO_ACCOUNT}>
                Only allowed for specific account
              </option>
            </Input>
          </Col>
        </Row>
        {categoryValues &&
        categoryValues['category_rule'] ===
          VALIDATION_RULES.SPECIFIC_TO_ACCOUNT ? (
          <Row>
            <Col>
              <Input
                name="category_rule_specific_account_type"
                placeholder="Category Rule Specific Account Type"
                value={
                  (categoryValues &&
                    categoryValues['category_rule_specific_account_type']) ??
                  ''
                }
                onChange={handleCategoryRuleSpecificAccountChange}
                type="select"
                className={optinsStyles.ruleInputBottom}
              >
                <option key="" value="">
                  Please Select
                </option>
                {accountTypeOptions}
              </Input>
            </Col>
          </Row>
        ) : null}
      </td>
      {Object.keys(defaultCategoryValues).map((c) => {
        return (
          <td key={c}>
            <Row>
              <Col sm={12}>
                <h6>{propertyTypes[c]}</h6>
              </Col>
            </Row>
            {Object.keys(defaultCategoryValues[c]).map((p) => {
              return (
                <Row key={p}>
                  <Col sm={12}>
                    <CategoryProperty
                      id={c}
                      index={p}
                      name={`${p}${defaultCategoryValues[c][p].label}`}
                      label={`${defaultCategoryValues[c][p].label}`}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              );
            })}
          </td>
        );
      })}
      <td>
        <Button onClick={(e) => handleUpdateAll()}>
          Update All Addressbooks
        </Button>
      </td>
    </tr>
  );
};
